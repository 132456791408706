import moment from 'moment';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { sysAPI } from '../../../apis/SysAPI';
import { Container, Header, Table } from 'semantic-ui-react';
import { StackedGraphic } from './Tables';
import { responseError } from '../../../helpers';
import { useDispatch } from 'react-redux';

const GraphicTable = ({ rows, title }) => {
  const dateFormatting = (date) => {
    return date && moment(date).isValid() ? moment(date).format('MM/DD/YYYY HH:mm:ss') : 'N/A';
  };

  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="4" textAlign="center">
              {title}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Complete Processes</Table.HeaderCell>
            <Table.HeaderCell>Most Used Area</Table.HeaderCell>
            <Table.HeaderCell># of Logins</Table.HeaderCell>
            <Table.HeaderCell>Last Login</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows.map((row) => {
            return (
              <Table.Row>
                <Table.Cell>{row.advisor}</Table.Cell>
                <Table.Cell>{row.completeProcesses}</Table.Cell>
                <Table.Cell>{row.area?.join('/') || 'report'}</Table.Cell>
                <Table.Cell>{row.logins || 0}</Table.Cell>
                <Table.Cell>{dateFormatting(row.lastLogin)}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

const UserActivity = ({ table }) => {
  return (
    <>
      {table?.subscribed?.length > 0 ? <GraphicTable rows={table.subscribed || []} title={'Paid'} /> : null}
      {table?.trial?.length > 0 ? <GraphicTable rows={table.trial || []} title={'Trial'} /> : null}
    </>
  );
};

const Graphic = forwardRef(
  (
    {
      endpoint,
      title,
      options,
      plugins,
      datalabels,
      hasTable,
      isPeriod,
      activeIndex,
      startDate,
      endDate,
      excludeCompanies,
      includeCompanies,
      areas,
      loading,
      setLoading,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const [labels, setLables] = useState([]);
    const [datasets, setDataset] = useState([]);
    const [sublabels, setSublables] = useState([]);
    const [subdatasets, setSubdataset] = useState([]);
    const [table, setTable] = useState({});
    // eslint-disable-next-line
    const [typeOfAnalysis, setTypeOfAnalysis] = useState([]);

    const callAPI = async () => {
      console.log('Holiwis');
      setLoading(true);
      try {
        const { data: response_1 } = await sysAPI.get(`/advisors/graphics/${endpoint}`, {
          params: {
            startDate: startDate,
            endDate: endDate,
            isPeriod: isPeriod,
            companies: includeCompanies.join(','),
            excludeCompanies: excludeCompanies.join(','),
            areas: areas.join(','),
          },
        });
        setDataset(response_1.datasets);
        setLables(response_1.labels);

        const { data: response_2 } = await sysAPI.get(`/advisors/graphics/${endpoint}-table`, {
          params: {
            startDate: startDate,
            endDate: endDate,
            isPeriod: isPeriod,
            companies: includeCompanies.join(','),
            excludeCompanies: excludeCompanies.join(','),
          },
        });
        setTable(response_2);

        if (endpoint === 'weekly-processes') {
          const { data: response_3 } = await sysAPI.get(`/advisors/graphics/${endpoint}-by-area`, {
            params: {
              startDate: startDate,
              endDate: endDate,
              isPeriod: isPeriod,
              companies: includeCompanies.join(','),
              excludeCompanies: excludeCompanies.join(','),
            },
          });
          setSubdataset(response_3.datasets);
          setSublables(response_3.labels);

          const { data: response_4 } = await sysAPI.get(`/advisors/graphics/${endpoint}-analysis-type-table`, {
            params: {
              startDate: startDate,
              endDate: endDate,
              isPeriod: isPeriod,
              companies: includeCompanies.join(','),
              excludeCompanies: excludeCompanies.join(','),
            },
          });
          setTypeOfAnalysis(response_4.typeOfAnalysis);
        }
      } catch (error) {
        responseError(error, undefined, 'Graphic,callAPI', dispatch);
      }
      setLoading(false);
    };

    useEffect(() => {
      callAPI();

      // eslint-disable-next-line
    }, [activeIndex]);

    useImperativeHandle(ref, () => ({
      callApiGraphic: callAPI,
    }));

    return (
      <>
        <Container>
          <Header textAlign="center" as="h2">
            {title}
          </Header>
          <StackedGraphic labels={labels} datasets={datasets} options={options} plugins={plugins} datalabels={datalabels} />
          {hasTable ? <UserActivity table={table} /> : null}
          {endpoint === 'weekly-processes' ? (
            <>
              <Header textAlign="center" as="h3">
                {"Week's User Activity by area"}
              </Header>
              <StackedGraphic
                labels={sublabels}
                datasets={subdatasets}
                options={{ barPercentage: 0.85 }}
                datalabels={{ anchor: 'end', align: 'end', offset: 5 }}
                plugins={{ legend: { display: true, position: 'right', labels: { font: { size: 15 } } } }}
              />
            </>
          ) : null}
        </Container>
      </>
    );
  }
);
export default Graphic;
