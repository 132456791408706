// eslint-disable-next-line
export default [
    {
        section: 'General Information',
        fields: [
            {
                name: 'clientIDFillingStatus',
                text: 'Filing Status',
                type: 'string',
                options: [
                    "Single",
                    "Married Filing Jointly",
                    "Married Filing Separately",
                    "Head of Household",
                    "Surviving Spouse"
                ],
            },
        ]
    },
    {
        section: 'Form 1040',
        fields: [
            {
                name: 'wages',
                text: 'Total amount from Form(s) W-2, box 1',
                line: 'Line 1a',
                type: 'number',
            },
            {
                name: 'householdWages',
                text: 'Household employee wages',
                line: 'Line 1b',
                type: 'number',
            },
            {
                name: 'tipIncome',
                text: 'Tip income',
                line: 'Line 1c',
                type: 'number',
            },
            {
                name: 'medicalWaiverPayments',
                text: 'Medicaid waiver payments',
                line: 'Line 1d',
                type: 'number',
            },
            {
                name: 'taxableDependentBenefits',
                text: 'Taxable dependent care benefits',
                line: 'Line 1e',
                type: 'number',
            },
            {
                name: 'employerAdoptionBenefits',
                text: 'Employer-provided adoption benefits',
                line: 'Line 1f',
                type: 'number',
            },
            {
                name: 'wagesFrom8919',
                text: 'Wages from form 8919',
                line: 'Line 1g',
                type: 'number',
            },
            {
                name: 'otherEarnedIncome',
                text: 'Other earned income',
                line: 'Line 1h',
                type: 'number',
            },
            {
                name: 'sum1a1h',
                text: 'Add lines 1a through 1h',
                line: 'Line 1z',
                type: 'number',
            },
            {
                name: 'taxExemptInterest',
                text: 'Tax-exempt interest',
                line: 'Line 2a',
                type: 'number',
            },
            {
                name: 'taxInterest',
                text: 'Taxable interest',
                line: 'Line 2b',
                type: 'number',
            },
            {
                name: 'qualifiedDividends',
                text: 'Qualified dividends',
                line: 'Line 3a',
                type: 'number',
            },
            {
                name: 'totalDividends',
                text: 'Ordinary dividends',
                line: 'Line 3b',
                type: 'number',
            },
            {
                name: 'iraDistribution',
                text: 'IRA distributions',
                line: 'Line 4a',
                type: 'number',
            },
            {
                name: 'taxableIRA',
                text: 'IRA distributions taxable amount',
                line: 'Line 4b',
                type: 'number',
            },
            {
                name: 'pension',
                text: 'Pensions and annuities',
                line: 'Line 5a',
                type: 'number',
            },
            {
                name: 'taxablePension',
                text: 'Pensions and annuities taxable amount',
                line: 'Line 5b',
                type: 'number',
            },
            {
                name: 'socialSecurityBenefits',
                text: 'Social security benefits',
                line: 'Line 6a',
                type: 'number',
            },
            {
                name: 'socialSecurity',
                text: 'Social security benefits taxable amount',
                line: 'Line 6b',
                type: 'number',
            },
            {
                name: 'capitalGainOrLoss',
                text: 'Capital gain or (loss)',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'otherIncome',
                text: 'Additional Income',
                line: 'Line 8',
                type: 'number',
            },
            {
                name: 'totalIncome',
                text: 'Total income',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'adjustedIncome',
                text: 'Adjustments to income',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'adjustedGrossIncome',
                text: 'Adjusted gross income',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'standardDeductions',
                text: 'Standard deductions or itemized deductions',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'qbIncome',
                text: 'Qualified business income deductions',
                line: 'Line 13',
                type: 'number',
            },
            {
                name: 'sumDeductions',
                text: 'Sum of deductions',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'taxableIncome',
                text: 'Taxable income',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'taxes',
                text: 'Tax',
                line: 'Line 16',
                type: 'number',
            },
            {
                name: 'line17_1040',
                text: 'Amount from schedule 2 line 3',
                line: 'Line 17',
                type: 'number',
            },
            {
                name: 'line18_1040',
                text: 'Add lines 16 and 17',
                line: 'Line 18',
                type: 'number',
            },
            {
                name: 'qualifyingDependentCreditClaimed',
                text: 'Child tax credit or credit for other dependents',
                line: 'Line 19',
                type: 'number',
            },
            {
                name: 'line20_1040',
                text: 'Amount from schedule 3 line 8',
                line: 'Line 20',
                type: 'number',
            },
            {
                name: 'line21_1040',
                text: 'Add lines 19 and 20',
                line: 'Line 21',
                type: 'number',
            },
            {
                name: 'line22_1040',
                text: 'Substract line 21 from line 18',
                line: 'Line 22',
                type: 'number',
            },
            {
                name: 'otherTaxesWithSelfEmployment',
                text: 'Other taxes',
                line: 'Line 23',
                type: 'number',
            },
            {
                name: 'totalTax',
                text: 'Total tax',
                line: 'Line 24',
                type: 'number',
            },
            {
                name: 'federalIncomeTaxWithheld',
                text: 'Federal income tax withheld from Form(s) W-2, 1099 and others',
                line: 'Line 25d',
                type: 'number',
            },
            {
                name: 'estimatedTaxPaymentsAmountForLastYear',
                text: '2024 estimated tax payments and amount applied from 2023 return',
                line: 'Line 26',
                type: 'number',
            },
            {
                name: 'earnedIncomeCredit',
                text: 'Earned income credit (EIC)',
                line: 'Line 27',
                type: 'number',
            },
            {
                name: 'additionalChildTaxCredit',
                text: 'Additional child tax credit',
                line: 'Line 28',
                type: 'number',
            },
            {
                name: 'americanOpportunityCreditTaken',
                text: 'American opportunity credit from Form 8863, line 8',
                line: 'Line 29',
                type: 'number',
            },
            {
                name: 'recoveryRebateCredit',
                text: 'Recovery rebate credit',
                line: 'Line 30',
                type: 'number',
            },
            {
                name: 'amountfromSchedule3line15',
                text: 'Amount from Schedule 3, line 15',
                line: 'Line 31',
                type: 'number',
            },
            {
                name: 'totalOtherPayments',
                text: 'Total other payments and refundable credits',
                line: 'Line 32',
                type: 'number',
            },
            {
                name: 'totalPayments',
                text: 'Total payments',
                line: 'Line 33',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule 1',
        fields: [
            {
                name: 'clientHasSchedule1',
                text: 'Has Schedule 1?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'taxableRefunds',
                text: 'Taxable refunds',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'alimonyReceived',
                text: 'Alimony received',
                line: 'Line 2a',
                type: 'number',
            },
            {
                name: 'businessIncome',
                text: 'Business income/loss',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'otherGainsOrLosses',
                text: 'Other gains or losses',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'rentalRealEstate',
                text: 'Rental real estate, royalties, partnerships, etc.',
                line: 'Line 5',
                type: 'number',
            },
            {
                name: 'farmIncome',
                text: 'Farm income or loss',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'unemployementCompensation',
                text: 'Unemployment compensation',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'otherIncome8a',
                text: 'Net operating loss',
                line: 'Line 8a',
                type: 'number',
            },
            {
                name: 'otherIncome8b',
                text: 'Gambling',
                line: 'Line 8b',
                type: 'number',
            },
            {
                name: 'otherIncome8c',
                text: 'Cancellation of debt',
                line: 'Line 8c',
                type: 'number',
            },
            {
                name: 'otherIncome8d',
                text: 'Foreign earned income exclusion from Form 2555',
                line: 'Line 8d',
                type: 'number',
            },
            {
                name: 'otherIncome8e',
                text: 'Income from Form 8853',
                line: 'Line 8e',
                type: 'number',
            },
            {
                name: 'otherIncome8f',
                text: 'Income from Form 8889',
                line: 'Line 8f',
                type: 'number',
            },
            {
                name: 'otherIncome8g',
                text: 'Alaska permanent fund dividends',
                line: 'Line 8g',
                type: 'number',
            },
            {
                name: 'otherIncome8h',
                text: 'Jury duty pay',
                line: 'Line 8h',
                type: 'number',
            },
            {
                name: 'otherIncome8i',
                text: 'Prizes and awards',
                line: 'Line 8i',
                type: 'number',
            },
            {
                name: 'otherIncome8j',
                text: 'Activity not engaged in profit income',
                line: 'Line 8j',
                type: 'number',
            },
            {
                name: 'otherIncome8k',
                text: 'Stock options',
                line: 'Line 8k',
                type: 'number',
            },
            {
                name: 'otherIncome8l',
                text: 'Income from rental of personal property',
                line: 'Line 8l',
                type: 'number',
            },
            {
                name: 'otherIncome8m',
                text: 'Olympic and paralympic medals and USOC prize money',
                line: 'Line 8m',
                type: 'number',
            },
            {
                name: 'otherIncome8n',
                text: 'Section 951(a) inclusion',
                line: 'Line 8n',
                type: 'number',
            },
            {
                name: 'otherIncome8o',
                text: 'Section 951A(a) inclusion',
                line: 'Line 8o',
                type: 'number',
            },
            {
                name: 'otherIncome8p',
                text: 'Section 461(l) excess business loss adjustment',
                line: 'Line 8p',
                type: 'number',
            },
            {
                name: 'otherIncome8q',
                text: 'Taxable distributions from and ABLE account',
                line: 'Line 8q',
                type: 'number',
            },
            {
                name: 'otherIncome8r',
                text: 'Scholarship and fellowship grants not reported on W-2',
                line: 'Line 8r',
                type: 'number',
            },
            {
                name: 'otherIncome8s',
                text: 'Nontaxable amount of Medicaid waiver payments',
                line: 'Line 8s',
                type: 'number',
            },
            {
                name: 'otherIncome8t',
                text: 'Pension or annuity from a nonquialified deferred compensation plan',
                line: 'Line 8t',
                type: 'number',
            },
            {
                name: 'otherIncome8u',
                text: 'Wages earned while incarcerated',
                line: 'Line 8u',
                type: 'number',
            },
            {
                name: 'otherIncome8v',
                text: 'Digital assets received as ordinary income not reported elsewhere',
                line: 'Line 8v',
                type: 'number',
            },
            {
                name: 'otherIncome8z',
                text: 'Other income',
                line: 'Line 8z',
                type: 'number',
            },
            {
                name: 'additionalIncomeTotal',
                text: 'Total other income',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'sum1to9',
                text: 'Additional income',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'educatorExpenses',
                text: 'Educator expenses',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'certainBusinessExpenses',
                text: 'Certain business expenses',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'healthSavings',
                text: 'Health savings account deduction',
                line: 'Line 13',
                type: 'number',
            },
            {
                name: 'movingExpenses',
                text: 'Moving expenses for members of the Armed Forces',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'deductibleOfSelfEmployment',
                text: 'Deductible part of self-employment',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'selfEmployedSEP',
                text: 'Self-employment SEP, SIMPLE and qualified plans',
                line: 'Line 16',
                type: 'number',
            },
            {
                name: 'selfEmployedHealthInsurance',
                text: 'Self-employment health insurance deduction',
                line: 'Line 17',
                type: 'number',
            },
            {
                name: 'penaltyEarlyWithdrawal',
                text: 'Penalty on early withdrawl or savings',
                line: 'Line 18',
                type: 'number',
            },
            {
                name: 'alimonyPaid',
                text: 'Alimony paid',
                line: 'Line 19a',
                type: 'number',
            },
            {
                name: 'iraDeduction',
                text: 'IRA deduction',
                line: 'Line 20',
                type: 'number',
            },
            {
                name: 'studentLoanInterestDeduction',
                text: 'Student loan interest deduction',
                line: 'Line 21',
                type: 'number',
            },
            // {
            //     name: 'forFutureUseSch1',
            //     text: 'Reserved for future use',
            //     line: 'Line 22',
            //     type: 'number',
            // },
            {
                name: 'archerMSADeduction',
                text: 'Archer MSA Deduction',
                line: 'Line 23',
                type: 'number',
            },
            {
                name: 'juryDuty',
                text: 'Jury duty pay',
                line: 'Line 24a',
                type: 'number',
            },
            {
                name: 'deductibleExpenses',
                text: 'Deductible expenses related to income reported on line 8l from the rental of personal property engaged in for profit',
                line: 'Line 24b',
                type: 'number',
            },
            {
                name: 'nontaxableAmountOlympicMedals',
                text: 'Nontaxable amount of the value of Olympic and Paralympic medals',
                line: 'Line 24c',
                type: 'number',
            },
            {
                name: 'reforestationAmortization',
                text: 'Reforestation amortization and expenses ',
                line: 'Line 24d',
                type: 'number',
            },
            {
                name: 'repaymentOfUnemployedBenefits',
                text: 'Repayment of supplemental unemployment benefits under the trade act of 1974',
                line: 'Line 24e',
                type: 'number',
            },
            {
                name: 'contributionsToSection501',
                text: 'Contributions to section 501(c)(18)(D) pension plans',
                line: 'Line 24f',
                type: 'number',
            },
            {
                name: 'contributionsToSection403',
                text: 'Contributions by certain chaplains to section 403(b) plans',
                line: 'Line 24g',
                type: 'number',
            },
            {
                name: 'attorneyFeesDiscriminationClaims',
                text: 'Attorney fees and court costs for actions involving discrimination claims',
                line: 'Line 24h',
                type: 'number',
            },
            {
                name: 'attorneyFeesIRSHelp',
                text: 'Attorney fees and court costs in connection with and award from the IRS',
                line: 'Line 24i',
                type: 'number',
            },
            {
                name: 'housingDeduction',
                text: 'Housing deduction from Form 2555',
                line: 'Line 24j',
                type: 'number',
            },
            {
                name: 'excessDeductions',
                text: 'Excess deductions of section 67(e) expenses from Schedule K-1',
                line: 'Line 24k',
                type: 'number',
            },
            {
                name: 'otherAdjustmens',
                text: 'Other adjustments',
                line: 'Line 24z',
                type: 'number',
            },
            {
                name: 'totalOtherAdjustments',
                text: 'Total other adjustmens',
                line: 'Line 25',
                type: 'number',
            },
            {
                name: 'adjustmentToIncome',
                text: 'Adjustments to income',
                line: 'Line 26',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule 2',
        fields: [
            {
                name: 'clientHasSchedule2',
                text: 'Has Schedule 2?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'schedule2line1z',
                text: 'Add lines 1a through 1y',
                line: 'Line 1z',
                type: 'number',
            },
            {
                name:'alternativeMinTax',
                text: 'Alternative minimun tax',
                line: 'Line 2',
                type: 'number',
            },
            {
                name: 'sum1and2',
                text: 'Add lines 1z and 2',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'selfEmploymentTaxSch3',
                text: 'Self-employment tax',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'socialSecurityTip',
                text: 'Social security and Medicare tax on unreported tip income',
                line: 'Line 5',
                type: 'number',
            },
            {
                name: 'uncollectedSocialSecurity',
                text: 'Uncollected social security and Medicare tax on wages',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'totalAdditionalSocialSecurity',
                text: 'Total additional social security and Medicare tax',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'additionalTaxOnIRA',
                text: 'Additional tax on IRAs or other tax-favored accounts',
                line: 'Line 8',
                type: 'number',
            },
            {
                name: 'householdEmploymentTaxes',
                text: 'Household employment taxes',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'repaymentFirstTimeBuyer',
                text: 'Repayment of first-time homebuyer credit',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'additionalMedicare',
                text: 'Additional Medicare tax',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'netInvestmentIncomeTax',
                text: 'Net investment income tax',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'uncollectedSocialSecurityOnTips',
                text: 'Uncollected social security and Medicare or RRTA tax on tips',
                line: 'Line 13',
                type: 'number',
            },
            {
                name: 'interestOnTax',
                text: 'Interest on tax due on installment income from the sale of certain residential lots and timeshares',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'interestOnDeferredTax',
                text: 'Interest on the deferred tax on gain from certain installment sales with a sales price over $150,000',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'recaptureLowIncome',
                text: 'Recapture of low-income housing credit',
                line: 'Line 16',
                type: 'number',
            },
            {
                name: 'recaptureOtherCredits',
                text: 'Recapture of other credits',
                line: 'Line 17a',
                type: 'number',
            },
            {
                name: 'recaptureFederalMortage',
                text: 'Recapture of federal mortgage subsidy',
                line: 'Line 17b',
                type: 'number',
            },
            {
                name: 'additionalTaxHSA',
                text: 'Additional tax on HSA distributions',
                line: 'Line 17c',
                type: 'number',
            },
            {
                name: 'additionalTaxHSAdidntRemainElegibleIndividual',
                text: "Additional tax on an HSA because you didn't remain an eligible individual",
                line: 'Line 17d',
                type: 'number',
            },
            {
                name: 'additionalTaxArcherMSA',
                text: 'Additional tax on Archer MSA distributions',
                line: 'Line 17e',
                type: 'number',
            },
            {
                name: 'additionalTaxMedicareAdvantageMSA',
                text: 'Additional tax on Medicare Advantage MSA distributions',
                line: 'Line 17f',
                type: 'number',
            },
            {
                name: 'recaptureOfCharitableContributions',
                text: 'Recapture of a charitable contribution deduction related to a fractional interest in tangible personal property',
                line: 'Line 17g',
                type: 'number',
            },
            {
                name: 'incomeFromNonqualifiedCompesationPlan',
                text: 'Income you received from a nonqualified deferred compensation plan that fails to meet the requirements of section 409A ',
                line: 'Line 17h',
                type: 'number',
            },
            {
                name: 'incomeFromNonqualifiedCompesationPlan457A',
                text: 'Compensation you received from a nonqualified deferred compensation plan described in section 457A',
                line: 'Line 17i',
                type: 'number',
            },
            {
                name: 'section72ExcessBenefitsTax',
                text: 'Section 72(m)(5) excess benefits tax',
                line: 'Line 17j',
                type: 'number',
            },
            {
                name: 'goldenParachutePayments',
                text: 'Golden parachute payments',
                line: 'Line 17k',
                type: 'number',
            },
            {
                name: 'taxAccumulationDistributionOfTrusts',
                text: 'Tax on accumulation distribution of trusts',
                line: 'Line 17l',
                type: 'number',
            },
            {
                name: 'exciseTaxOnInsiderStock',
                text: 'Excise tax on insider stock compensation from an expatriated corporation',
                line: 'Line 17m',
                type: 'number',
            },
            {
                name: 'lookbackInterestSection167or460',
                text: 'Look-back interest under section 167(g) or 460(b) from Form 8697 or 8866',
                line: 'Line 17n',
                type: 'number',
            },
            {
                name: 'taxNonEffectivelyConnectedIncome',
                text: 'Tax on non-effectively connected income for any part of the year you were a nonresident alien',
                line: 'Line 17o',
                type: 'number',
            },
            {
                name: 'interestForm8621line16f',
                text: 'Any interest from Form 8621, line 16f',
                line: 'Line 17p',
                type: 'number',
            },
            {
                name: 'interestForm8621line24',
                text: 'Any interest from Form 8621, line 24',
                line: 'Line 17q',
                type: 'number',
            },
            {
                name: 'anyOtherTaxes',
                text: 'Any other taxes',
                line: 'Line 17z',
                type: 'number',
            },
            {
                name: 'totalAdditionalTaxes',
                text: 'Total additional taxes',
                line: 'Line 18',
                type: 'number',
            },
            {
                name: 'reservedForFutureUse',
                text: 'Recapture of net EPE from Form 4255, line 1d, column (l)',
                line: 'Line 19',
                type: 'number',
            },
            {
                name: 'section965',
                text: 'Section 965 net tax liability installment from Form 965-A',
                line: 'Line 20',
                type: 'number',
            },
            {
                name: 'totalOtherTaxes',
                text: 'Total other taxes',
                line: 'Line 21',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule 3',
        fields: [
            {
                name: 'clientHasSchedule3',
                text: 'Has Schedule 3?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'foreignTax',
                text: 'Foreign tax',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'creditForChildCareTF',
                text: 'Credit for child/dependent care?',
                line: 'Line 2',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'creditForChildCare',
                text: 'Credit for child and dependent care expenses',
                line: 'Line 2',
                type: 'number',
            },
            {
                name: 'educationCredits',
                text: 'Education credits',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'retiramentSavings',
                text: 'Retirament savings contributions credit',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'schedule3line5a',
                text: 'Residential clean energy credit',
                line: 'Line 5a',
                type: 'number',
            },
            {
                name: 'schedule3line5b',
                text: 'Energy efficient home improvement credit',
                line: 'Line 5b',
                type: 'number',
            },
            {
                name: 'generalBusinessCredit',
                text: 'General business credit',
                line: 'Line 6a',
                type: 'number',
            },
            {
                name: 'creditForPriorYearMinimunTax',
                text: 'Credit for prior year minimun tax',
                line: 'Line 6b',
                type: 'number',
            },
            {
                name: 'adoptionCredit',
                text: 'Adoption credit',
                line: 'Line 6c',
                type: 'number',
            },
            {
                name: 'creditForEldery',
                text: 'Credit for elderly or disabled',
                line: 'Line 6d',
                type: 'number',
            },
            // {
            //     name: 'alternativeMotorVehicleCredit',
            //     text: 'Alternative motor vehicle credit',
            //     line: 'Line 6e',
            //     type: 'number',
            // },
            {
                name: 'qualifiedPlugInMotorVehicleCredit',
                text: 'Clean vehicle credit',
                line: 'Line 6f',
                type: 'number',
            },
            {
                name: 'mortgageInterestCredit',
                text: 'Mortgage interest credit',
                line: 'Line 6g',
                type: 'number',
            },
            {
                name: 'columbiaFirstTimeBuyer',
                text: 'District of Columbia first-time homebuyer credit',
                line: 'Line 6h',
                type: 'number',
            },
            {
                name: 'qualifiedElectricVehicleCredit',
                text: 'Qualified electric vehicle credit',
                line: 'Line 6i',
                type: 'number',
            },
            {
                name: 'alternativeFuelVehicleCredit',
                text: 'Alternative fuel vehicle refueling property credit',
                line: 'Line 6j',
                type: 'number',
            },
            {
                name: 'creditToHoldersTaxCreditsBonds',
                text: 'Credit to holders of tax credit bonds',
                line: 'Line 6k',
                type: 'number',
            },
            {
                name: 'amountOnForm8978',
                text: 'Amount on Form 8978, line 14',
                line: 'Line 6l',
                type: 'number',
            },
            {
                name: 'schedule3line6m',
                text: 'Credit for previously owned clean vehicles',
                line: 'Line 6m',
                type: 'number',
            },
            {
                name: 'otherNonRefundableCredits',
                text: 'Other nonrefundable credits',
                line: 'Line 6z',
                type: 'number',
            },
            {
                name: 'nonRefundableCredits',
                text: 'Total other non refundable credits',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'estimatedTax',
                text: 'Add lines 1 through 4, 5a, 5b, and 7',
                line: 'Line 8',
                type: 'number',
            },
            {
                name: 'netPremiumTaxCredit',
                text: 'Net premium tax credit',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'requestForExtension',
                text: 'Amount paid with request for extension to file',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'excessSocialSecurity',
                text: 'Excess social security and tier 1 RRTA tax withheld',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'creditForFederalTaxFuels',
                text: 'Credit for federal tax on fuels',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'form2439',
                text: 'Form 2439',
                line: 'Line 13a',
                type: 'number',
            },
            {
                name: 'qualifiedSickCredits',
                text: 'Section 1341 credit for repayment of amounts included in income from earlier years',
                line: 'Line 13b',
                type: 'number',
            },
            {
                name: 'healthCoverageCredit',
                text: 'Net elective payment election amount from Form 3800',
                line: 'Line 13c',
                type: 'number',
            },
            {
                name: 'creditForRepayment',
                text: 'Deferred amount of net 965 tax liability',
                line: 'Line 13d',
                type: 'number',
            },
            {
                name: 'otherPaymentsOrRefundableCredits',
                text: 'Other payments or refundable credits',
                line: 'Line 13z',
                type: 'number',
            },
            {
                name: 'refundableCredits',
                text: 'Total other payments or refundable credits',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'sum9to14',
                text: 'Add lines 9 through 12 and 14',
                line: 'Line 15',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule A',
        fields: [
            {
                name: 'clientHasScheduleA',
                text: 'Has Schedule A?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'medicalExpenses',
                text: 'Medical and dental expenses',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'medicalExpenses2',
                text: 'Multiply line 2 by 7.5%',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'medicalExpenses3',
                text: 'Subtract line 3 from line 1',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5a',
                text: 'State and local income taxes or general sales taxes',
                line: 'Line 5a',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5b',
                text: 'State and local real estate taxes',
                line: 'Line 5b',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5c',
                text: 'State and local personal property taxes',
                line: 'Line 5c',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5d',
                text: 'Add lines 5a through 5c',
                line: 'Line 5d',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5e',
                text: 'Enter the smaller of line 5d or $10,000',
                line: 'Line 5e',
                type: 'number',
            },
            {
                name: 'taxesYouPaid',
                text: 'Add lines 5e and 6',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'mortgageInterests',
                text: 'Home mortgage interest and points reported to you on Form 1098',
                line: 'Line 8a',
                type: 'number',
            },
            {
                name: 'mortgageInterestsNotReported',
                text: 'Home mortgage interest not reported to you on Form 1098',
                line: 'Line 8b',
                type: 'number',
            },
            {
                name: 'pointsNotReported',
                text: 'Points not reported to you on Form 1098',
                line: 'Line 8c',
                type: 'number',
            },
            // {
            //     name: 'mortgageInsurancePremiums',
            //     text: 'Mortgage insurance premiums',
            //     line: 'Line 8d',
            //     type: 'number',
            // },
            {
                name: 'sumOfLines8',
                text: 'Add lines 8a through 8c',
                line: 'Line 8e',
                type: 'number',
            },
            {
                name: 'investmentInterest',
                text: 'Investment interest',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'interestYouPaid',
                text: 'Add lines 8e and 9',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'giftsByCash',
                text: 'Gifts by cash or check',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'giftsByLand',
                text: 'Other than by cash or check',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'giftsToCharity',
                text: 'Add lines 11 through 13',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'casualtyLosses',
                text: 'Casualty and theft loss(es) from a federally declared disaster',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'otherItemizedDeductions',
                text: 'Other itemized deductions',
                line: 'Line 16',
                type: 'number',
            },
            {
                name: 'summationsItemizedDeductions',
                text: 'Total itemized deductions',
                line: 'Line 17',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule B Part 1',
        fields: [
            {
                name: 'clientHasScheduleB',
                text: 'Has Schedule B?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'scheduleb_1_0_text',
                text: 'scheduleb_1_0_text',
                line: 'Line 0 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_0_amount',
                text: 'scheduleb_1_0_amount',
                line: 'Line 0 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_1_text',
                text: 'scheduleb_1_1_text',
                line: 'Line 1 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_1_amount',
                text: 'scheduleb_1_1_amount',
                line: 'Line 1 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_2_text',
                text: 'scheduleb_1_2_text',
                line: 'Line 2 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_2_amount',
                text: 'scheduleb_1_2_amount',
                line: 'Line 2 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_3_text',
                text: 'scheduleb_1_3_text',
                line: 'Line 3 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_3_amount',
                text: 'scheduleb_1_3_amount',
                line: 'Line 3 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_4_text',
                text: 'scheduleb_1_4_text',
                line: 'Line 4 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_4_amount',
                text: 'scheduleb_1_4_amount',
                line: 'Line 4 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_5_text',
                text: 'scheduleb_1_5_text',
                line: 'Line 5 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_5_amount',
                text: 'scheduleb_1_5_amount',
                line: 'Line 5 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_6_text',
                text: 'scheduleb_1_6_text',
                line: 'Line 6 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_6_amount',
                text: 'scheduleb_1_6_amount',
                line: 'Line 6 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_7_text',
                text: 'scheduleb_1_7_text',
                line: 'Line 7 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_7_amount',
                text: 'scheduleb_1_7_amount',
                line: 'Line 7 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_8_text',
                text: 'scheduleb_1_8_text',
                line: 'Line 8 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_8_amount',
                text: 'scheduleb_1_8_amount',
                line: 'Line 8 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_9_text',
                text: 'scheduleb_1_9_text',
                line: 'Line 9 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_9_amount',
                text: 'scheduleb_1_9_amount',
                line: 'Line 9 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_10_text',
                text: 'scheduleb_1_10_text',
                line: 'Line 10 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_10_amount',
                text: 'scheduleb_1_10_amount',
                line: 'Line 10 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_11_text',
                text: 'scheduleb_1_11_text',
                line: 'Line 11 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_11_amount',
                text: 'scheduleb_1_11_amount',
                line: 'Line 11 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_12_text',
                text: 'scheduleb_1_12_text',
                line: 'Line 12 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_12_amount',
                text: 'scheduleb_1_12_amount',
                line: 'Line 12 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_13_text',
                text: 'scheduleb_1_13_text',
                line: 'Line 13 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_13_amount',
                text: 'scheduleb_1_13_amount',
                line: 'Line 13 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_14_text',
                text: 'scheduleb_1_14_text',
                line: 'Line 14 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_14_amount',
                text: 'scheduleb_1_14_amount',
                line: 'Line 14 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_15_text',
                text: 'scheduleb_1_15_text',
                line: 'Line 15 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_15_amount',
                text: 'scheduleb_1_15_amount',
                line: 'Line 15 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_2',
                text: 'Add the amounts on line 1',
                line: 'Line 2',
                type: 'number',
            },
            {
                name: 'scheduleb_3',
                text: 'Attach from 8815',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'scheduleb_4',
                text: 'Substract line 3 from line 2',
                line: 'Line 4 AMOUNT',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule B Part 2',
        fields: [
            // line 5
            {
                name: 'scheduleb_5_0_text',
                text: 'scheduleb_5_0_text',
                line: 'Line 0 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_0_amount',
                text: 'scheduleb_5_0_amount',
                line: 'Line 0 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_1_text',
                text: 'scheduleb_5_1_text',
                line: 'Line 1 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_1_amount',
                text: 'scheduleb_5_1_amount',
                line: 'Line 1 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_2_text',
                text: 'scheduleb_5_2_text',
                line: 'Line 2 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_2_amount',
                text: 'scheduleb_5_2_amount',
                line: 'Line 2 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_3_text',
                text: 'scheduleb_5_3_text',
                line: 'Line 3 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_3_amount',
                text: 'scheduleb_5_3_amount',
                line: 'Line 3 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_4_text',
                text: 'scheduleb_5_4_text',
                line: 'Line 4 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_4_amount',
                text: 'scheduleb_5_4_amount',
                line: 'Line 4 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_5_text',
                text: 'scheduleb_5_5_text',
                line: 'Line 5 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_5_amount',
                text: 'scheduleb_5_5_amount',
                line: 'Line 5 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_6_text',
                text: 'scheduleb_5_6_text',
                line: 'Line 6 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_6_amount',
                text: 'scheduleb_5_6_amount',
                line: 'Line 6 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_7_text',
                text: 'scheduleb_5_7_text',
                line: 'Line 7 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_7_amount',
                text: 'scheduleb_5_7_amount',
                line: 'Line 7 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_8_text',
                text: 'scheduleb_5_8_text',
                line: 'Line 8 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_8_amount',
                text: 'scheduleb_5_8_amount',
                line: 'Line 8 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_9_text',
                text: 'scheduleb_5_9_text',
                line: 'Line 9 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_9_amount',
                text: 'scheduleb_5_9_amount',
                line: 'Line 9 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_10_text',
                text: 'scheduleb_5_10_text',
                line: 'Line 10 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_10_amount',
                text: 'scheduleb_5_10_amount',
                line: 'Line 10 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_11_text',
                text: 'scheduleb_5_11_text',
                line: 'Line 11 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_11_amount',
                text: 'scheduleb_5_11_amount',
                line: 'Line 11 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_12_text',
                text: 'scheduleb_5_12_text',
                line: 'Line 12 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_12_amount',
                text: 'scheduleb_5_12_amount',
                line: 'Line 12 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_13_text',
                text: 'scheduleb_5_13_text',
                line: 'Line 13 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_13_amount',
                text: 'scheduleb_5_13_amount',
                line: 'Line 13 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_14_text',
                text: 'scheduleb_5_14_text',
                line: 'Line 14 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_14_amount',
                text: 'scheduleb_5_14_amount',
                line: 'Line 14 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_15_text',
                text: 'scheduleb_5_15_text',
                line: 'Line 15 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_15_amount',
                text: 'scheduleb_5_15_amount',
                line: 'Line 15 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_6',
                text: 'Add the amounts on line 5',
                line: 'Line 6 AMOUNT',
                type: 'number',
            },
        ]
    },
    {
        section: 'Schedule C',
        fields: [
            {
                name: 'clientHasScheduleC',
                text: 'Has Schedule C?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'grossReceipts',
                text: 'Gross receipts or sales',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'returns',
                text: 'Returns and allowances',
                line: 'Line 2',
                type: 'number',
            },
            {
                name: 'line3Sch',
                text: 'Substract line 2 from line 1',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'costOfGoods',
                text: 'Cost of goods sold',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'grossProfit',
                text: 'Gross Profit',
                line: 'Line 5',
                type: 'number',
            },
            {
                name: 'otherIncomeSchC',
                text: 'Other Income',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'grossIncome',
                text: 'Gross Income',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'advertising',
                text: 'Advertising',
                line: 'Line 8',
                type: 'number',
            },
            {
                name: 'carExpenses',
                text: 'Car and truck expenses',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'commissions',
                text: 'Commissions and fees',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'contractLabor',
                text: 'Contract Labor',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'depletion',
                text: 'Depletion',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'depreciation',
                text: 'Depreciation',
                line: 'Line 13',
                type: 'number',
            },
            {
                name: 'employeeBenefit',
                text: 'Employee Benefit programs',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'insurance',
                text: 'Insurance (other than health)',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'interestMortgage',
                text: 'Interest Mortgage',
                line: 'Line 16a',
                type: 'number',
            },
            {
                name: 'interestOther',
                text: 'Interest Other',
                line: 'Line 16b',
                type: 'number',
            },
            {
                name: 'legal',
                text: 'Legal and professional services',
                line: 'Line 17',
                type: 'number',
            },
            {
                name: 'officeExpenses',
                text: 'Office Expenses',
                line: 'Line 18',
                type: 'number',
            },
            {
                name: 'pensionPlans',
                text: 'Pension and profit-sharing plans',
                line: 'Line 19',
                type: 'number',
            },
            {
                name: 'vehiclesMachinery',
                text: 'Rent or lease of vehicles, machinery, and equipment',
                line: 'Line 20a',
                type: 'number',
            },
            {
                name: 'otherBusinessProperty',
                text: 'Rent or lease of other business property',
                line: 'Line 20b',
                type: 'number',
            },
            {
                name: 'repairs',
                text: 'Repairs',
                line: 'Line 21',
                type: 'number',
            },
            {
                name: 'supplies',
                text: 'Supplies',
                line: 'Line 22',
                type: 'number',
            },
            {
                name: 'taxesAndLicenses',
                text: 'Taxes and Licenses',
                line: 'Line 23',
                type: 'number',
            },
            {
                name: 'travel',
                text: 'Travel',
                line: 'Line 24a',
                type: 'number',
            },
            {
                name: 'meals',
                text: 'Meals',
                line: 'Line 24b',
                type: 'number',
            },
            {
                name: 'utilities',
                text: 'Utilities',
                line: 'Line 25',
                type: 'number',
            },
            {
                name: 'wagesLessEmployeeCredits',
                text: 'Wages (less employment credits)',
                line: 'Line 26',
                type: 'number',
            },
            {
                name: 'otherExpenses_schC',
                text: 'Other Expenses',
                line: 'Line 27a',
                type: 'number',
            },
            {
                name: 'totalExpenses',
                text: 'Total Expenses',
                line: 'Line 28',
                type: 'number',
            },
            {
                name: 'tentativeProfit',
                text: 'Tentative Profit',
                line: 'Line 29',
                type: 'number',
            },
            {
                name: 'expensesForHomeBusinessTF',
                text: 'Expenses for business use of your home?',
                line: 'Line 30',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'expensesForHomeBusiness',
                text: 'Expenses for business use of your home',
                line: 'Line 30',
                type: 'number',
            },
            {
                name: 'netProfitOrLoss',
                text: 'Net profit or (loss) from business',
                line: 'Line 31',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule D',
        fields: [
            {
                name: 'clientHasScheduleD',
                text: 'Has Schedule D?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'scheduleD4',
                text: 'Short-term gain from Form 6252 and short-term gain or (loss) from Forms 4684, 6781, and 8824',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'scheduleD5',
                text: 'Net short-term gain or (loss) from partnerships, S corporations, estates, and trusts from Schedule(s) K-1',
                line: 'Line 5',
                type: 'number',
            },
            {
                name: 'shortTermLossCarryover',
                text: 'Short term capital loss carryover',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'netShortTermCapitalGains',
                text: 'Net short-term capital gain or (loss)',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'scheduleD11',
                text: 'Gain from Form 4797, Part I; long-term gain from Forms 2439 and 6252; and long-term gain or (loss) from Forms 4684, 6781, and 8824',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'scheduleD12',
                text: 'Net long-term gain or (loss) from partnerships, S corporations, estates, and trusts from Schedule(s) K-1',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'scheduleD13',
                text: 'Capital gain distributions',
                line: 'Line 13',
                type: 'number',
            },
            {
                name: 'longTermLossCarryover',
                text: 'Long term capital loss carryover',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'netLongTermCapitalGains',
                text: 'Net long-term capital gain (or loss)',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'scheduleD16',
                text: 'Combine lines 7 and 15',
                line: 'Line 16',
                type: 'number',
            },
            {
                name: 'scheduleD18',
                text: 'If you are required to complete the 28% Rate Gain Worksheet (see instructions), enter the amount, if any, from line 7 of that worksheet',
                line: 'Line 18',
                type: 'number',
            },
            {
                name: 'scheduleD19',
                text: 'If you are required to complete the Unrecaptured Section 1250 Gain Worksheet (see instructions), enter the amount, if any, from line 18 of that worksheet',
                line: 'Line 19',
                type: 'number',
            },
            {
                name: 'scheduleD21',
                text: 'If line 16 is a loss, enter the loss on line 16; or ($3,000), or if married filing separately, ($1,500)',
                line: 'Line 21',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule E',
        fields: [
            {
                name: 'clientHasScheduleE',
                text: 'Has Schedule E?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'totalAmounts23a',
                text: 'Total amounts reported on line 3 for all rental properties',
                line: 'Line 23a',
                type: 'number',
            },
            {
                name: 'totalAmounts23b',
                text: 'Total amounts reported on line 4 for all royalty properties',
                line: 'Line 23b',
                type: 'number',
            },
            {
                name: 'totalAmounts23c',
                text: 'Total amounts reported on line 12 for all properties',
                line: 'Line 23c',
                type: 'number',
            },
            {
                name: 'totalAmounts23d',
                text: 'Total amounts reported on line 18 for all properties',
                line: 'Line 23d',
                type: 'number',
            },
            {
                name: 'totalAmounts23e',
                text: 'Total amounts reported on line 20 for all properties',
                line: 'Line 23e',
                type: 'number',
            },
            {
                name: 'incomeSchE',
                text: 'Income',
                line: 'Line 24',
                type: 'number',
            },
            {
                name: 'lossesSchE',
                text: 'Losses',
                line: 'Line 25',
                type: 'number',
            },
            {
                name: 'totalRentalRealEstate',
                text: 'Total rental real estate and royalty income or loss',
                line: 'Line 26',
                type: 'number',
            },
            {
                name: 'line30schE',
                text: 'Add columns (h) and (k)',
                line: 'Line 30',
                type: 'number',
            },
            {
                name: 'line31schE',
                text: 'Add columns (g), (i) and (j)',
                line: 'Line 31',
                type: 'number',
            },
            {
                name: 'line32schE',
                text: 'Total partnership and S corporation income or loss',
                line: 'Line 32',
                type: 'number',
            },
            {
                name: 'line35schE',
                text: ' Add columns (d) and (f)',
                line: 'Line 35',
                type: 'number',
            },
            {
                name: 'line36schE',
                text: 'Add columns (c) and (e)',
                line: 'Line 36',
                type: 'number',
            },
            {
                name: 'line37schE',
                text: 'Total estate and trust income or loss',
                line: 'Line 37',
                type: 'number',
            },
            {
                name: 'line39schE',
                text: 'Combine columns (d) and (e)',
                line: 'Line 39',
                type: 'number',
            },
            {
                name: 'line40schE',
                text: 'Net farm rental income or loss',
                line: 'Line 40',
                type: 'number',
            },
            {
                name: 'line41schE',
                text: 'Total income or loss',
                line: 'Line 41',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule SE',
        fields: [
            {
                name: 'clientHasScheduleSE',
                text: 'Has Schedule SE?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'netFarmProfitOrLoss',
                text: 'Net farm profit or (loss) from Schedule F',
                line: 'Line 1a',
                type: 'number',
            },
            {
                name: 'conservationReserveProgramPayments',
                text: 'Amount of Conservation Reserve Program payments included on Schedule F',
                line: 'Line 1b',
                type: 'number',
            },
            {
                name: 'netProfitOrLossFromSchC',
                text: 'Net profit or (loss) from Schedule C, line 31',
                line: 'Line 2',
                type: 'number',
            },
            {
                name: 'combinelines1a1b2',
                text: 'Combine lines 1a, 1b, and 2',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'line4aSchSE',
                text: 'If line 3 is more than zero, multiply line 3 by 92.35% (0.9235). Otherwise, enter amount from line 3',
                line: 'Line 4a',
                type: 'number',
            },
            {
                name: 'line4bSchSE',
                text: 'If you elect one or both of the optional methods, enter the total of lines 15 and 17',
                line: 'Line 4b',
                type: 'number',
            },
            {
                name: 'line4cSchSE',
                text: 'Combine lines 4a and 4b',
                line: 'Line 4c',
                type: 'number',
            },
            {
                name: 'churchEmployeeIncome',
                text: 'Enter your church employee income from Form W-2',
                line: 'Line 5a',
                type: 'number',
            },
            {
                name: 'line5bSchSE',
                text: 'Multiply line 5a by 92.35%',
                line: 'Line 5b',
                type: 'number',
            },
            {
                name: 'addLines4c5b',
                text: 'Add lines 4c and 5b',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'maximumAmountofCombinedWages',
                text: 'Maximum amount of combined wages and self-employment earnings subject to social security tax',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'totalSocialSecurityWages',
                text: 'Total social security wages and tips',
                line: 'Line 8a',
                type: 'number',
            },
            {
                name: 'unreportedTips',
                text: 'Unreported tips subject to social security tax',
                line: 'Line 8b',
                type: 'number',
            },
            {
                name: 'wagesSubjectToSocialSecurity',
                text: 'Wages subject to social security tax from Form 8919, line 10',
                line: 'Line 8c',
                type: 'number',
            },
            {
                name: 'add8a8b8c',
                text: 'Add lines 8a, 8b, and 8c',
                line: 'Line 8d',
                type: 'number',
            },
            {
                name: 'line9SchSE',
                text: 'Subtract line 8d from line 7',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'line10SchSE',
                text: 'Multiply the smaller of line 6 or line 9 by 12.4%',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'line11SchSE',
                text: 'Multiply line 6 by 2.9%',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'selfEmploymentTax',
                text: 'Self-employment tax',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'deductionForOneHalfOfSelfemployment',
                text: 'Deduction for one-half of self-employment tax',
                line: 'Line 13',
                type: 'number',
            },
        ],
    },
    {
        section: 'Form 8995',
        fields: [
            {
                name: 'clientHasForm8995',
                text: 'Has Form 8995?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'totalQualifiedBusinessIncome',
                text: 'Total qualified business income',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'qualifiedBusinessIncomeDeduction',
                text: 'Qualified business income deduction',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'totalReit',
                text: 'Total qualified REIT dividends and PTP (loss) carryforward',
                line: 'Line 17',
                type: 'number',
            },

        ],
    },
    {
        section: 'Form 8995-A',
        fields: [
            {
                name: 'clientHasForm8995A',
                text: 'Has Form 8995-A?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'form8995A27',
                text: 'Total qualified business income',
                line: 'Line 27',
                type: 'number',
            },
            {
                name: 'form8995A28',
                text: 'Qualified REIT dividends and PTP income or loss',
                line: 'Line 28',
                type: 'number',
            },
            {
                name: 'form8995A29',
                text: 'Qualified REIT dividends and PTP (loss) carryforward from prior years',
                line: 'Line 29',
                type: 'number',
            },
            {
                name: 'form8995A30',
                text: 'Total qualified REIT dividends and PTP income',
                line: 'Line 30',
                type: 'number',
            },
            {
                name: 'form8995A31',
                text: 'REIT and PTP component',
                line: 'Line 31',
                type: 'number',
            },
            {
                name: 'form8995A32',
                text: 'Qualified business income deduction before the income limitation',
                line: 'Line 32',
                type: 'number',
            },
            {
                name: 'form8995A33',
                text: 'Taxable income before qualified business income deduction',
                line: 'Line 33',
                type: 'number',
            },
            {
                name: 'form8995A34',
                text: 'Net capital gain',
                line: 'Line 34',
                type: 'number',
            },
            {
                name: 'form8995A35',
                text: 'Substract line 34 from line 33',
                line: 'Line 35',
                type: 'number',
            },
            {
                name: 'form8995A36',
                text: 'Income limitation',
                line: 'Line 36',
                type: 'number',
            },
            {
                name: 'form8995A37',
                text: 'Qualified business income deduction before the domestic production activities deduction DPAD under section 199A(g)',
                line: 'Line 37',
                type: 'number',
            },
            {
                name: 'form8995A38',
                text: 'DPAD under section 199A(g) allocated from an agricultural or horticultural cooperative',
                line: 'Line 38',
                type: 'number',
            },
            {
                name: 'form8995A39',
                text: 'Total qualified business income deduction',
                line: 'Line 39',
                type: 'number',
            },
            {
                name: 'form8995A40',
                text: 'Total qualified REIT dividends and PTP (loss) carryforward',
                line: 'Line 40',
                type: 'number',
            },
        ],
    },
    {
        section: 'Form 8582',
        fields: [
            {
                name: 'clientHasForm8582',
                text: 'Has Form 8582?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'form8582SectionOne1a',
                text: 'Activities with net income',
                line: 'Line 1a',
                type: 'number',
            },
            {
                name: 'form8582SectionOne1b',
                text: 'Activities with net loss',
                line: 'Line 1b',
                type: 'number',
            },
            {
                name: 'form8582SectionOne1c',
                text: 'Prior years unallowed losses',
                line: 'Line 1c',
                type: 'number',
            },
            {
                name: 'form8582SectionOne1d',
                text: 'Combine lines 1a, 1b, and 1c',
                line: 'Line 1d',
                type: 'number',
            },
            {
                name: 'form8582SectionOne2a',
                text: 'Activities with net income',
                line: 'Line 2a',
                type: 'number',
            },
            {
                name: 'form8582SectionOne2b',
                text: 'Activities with net loss',
                line: 'Line 2b',
                type: 'number',
            },
            {
                name: 'form8582SectionOne2c',
                text: 'Prior years unallowed losses',
                line: 'Line 2c',
                type: 'number',
            },
            {
                name: 'form8582SectionOne2d',
                text: 'Combine lines 2a, 2b, and 2c',
                line: 'Line 2d',
                type: 'number',
            },
            {
                name: 'form8582SectionOne3',
                text: 'Combine lines 1d and 2d',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'form8582SectionTwo4',
                text: 'Enter the smaller of the loss on line 1d or the loss on line 3',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'form8582SectionTwo8',
                text: 'Home mortgage interes not reported to you on Form 1098',
                line: 'Line 8',
                type: 'number',
            },
            {
                name: 'form8582SectionTwo9',
                text: 'Enter the smaller of line 4 or line 8',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'form8582SectionThree11',
                text: 'Total losses allowed from all passive activities',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'form8582SectionSevena',
                text: '(a) Loss',
                line: 'Line 7a',
                type: 'number',
            },
            {
                name: 'form8582SectionSevenc',
                text: '(c) Unallowed loss',
                line: 'Line 7c',
                type: 'number',
            },
            {
                name: 'form8582SectionEighta',
                text: '(a) Loss',
                line: 'Line 8a',
                type: 'number',
            },
            {
                name: 'form8582SectionEightb',
                text: '(b) Unallowed loss',
                line: 'Line 8b',
                type: 'number',
            },
            {
                name: 'form8582SectionEightc',
                text: '(c) Allowed loss',
                line: 'Line 8c',
                type: 'number',
            },
        ],
    },
    {
        section: 'Form 8812',
        fields: [
            {
                name: 'clientHasSchedule8812',
                text: 'Does the client has Form 8812?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'schedule88121',
                text: 'Enter the amount from line 11 of your Form 1040, 1040-SR, or 1040-NR',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'schedule88122a',
                text: 'Enter income from Puerto Rico that you excluded',
                line: 'Line 2a',
                type: 'number',
            },
            {
                name: 'schedule88122b',
                text: 'Enter the amounts from lines 45 and 50 of your Form 2555',
                line: 'Line 2b',
                type: 'number',
            },
            {
                name: 'schedule88122c',
                text: 'Enter the amount from line 15 of your Form 4563',
                line: 'Line 2c',
                type: 'number',
            },
            {
                name: 'schedule88122d',
                text: 'Add lines 2a through 2c',
                line: 'Line 2d',
                type: 'number',
            },
            {
                name: 'schedule88123',
                text: 'Add lines 1 and 2d',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'schedule88124',
                text: 'Number of qualifying children under age 17 with the required social security number',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'schedule88125',
                text: 'Multiply line 4 by $2,000 ',
                line: 'Line 5',
                type: 'number',
            },
            {
                name: 'schedule88126',
                text: 'Number of other dependents, including any qualifying children who are not under age 17 or who do not have the required social security number',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'schedule88127',
                text: 'Multiply line 6 by $500',
                line: 'Line7',
                type: 'number',
            },
            {
                name: 'schedule88128',
                text: 'Add lines 5 and 7',
                line: 'Line 8',
                type: 'number',
            },
            {
                name: 'schedule88129',
                text: 'Enter the amount shown below for your filing status. • Married filing jointly—$400,000 • All other filing statuses—$200,000',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'schedule881210',
                text: 'Subtract line 9 from line 3',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'schedule881211',
                text: 'Multiply line 10 by 5% (0.05)',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'schedule881212',
                text: 'Is the amount on line 8 more than the amount on line 11?',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'schedule881213',
                text: 'Enter the amount from Credit Limit Worksheet A',
                line: 'Line 13',
                type: 'number',
            },
            {
                name: 'schedule881214',
                text: 'Enter the smaller of line 12 or line 13.',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'schedule881216a',
                text: 'Subtract line 14 from line 12',
                line: 'Line 16a',
                type: 'number',
            },
            {
                name: 'schedule881216b',
                text: 'Number of qualifying children under age 17 with the required social security number',
                line: 'Line 16b',
                type: 'number',
            },
            {
                name: 'schedule881217',
                text: 'Enter the smaller of line 16a or line 16b',
                line: 'Line 17',
                type: 'number',
            },
            {
                name: 'schedule881218a',
                text: 'Earned income',
                line: 'Line 18a',
                type: 'number',
            },
            {
                name: 'schedule881219',
                text: 'If the amount on line 18a more than $2,500, Subtract $2,500 from the amount on line 18a. ',
                line: 'Line 19',
                type: 'number',
            },
            {
                name: 'schedule881220',
                text: 'Multiply the amount on line 19 by 15% (0.15) and enter the result',
                line: 'Line 20',
                type: 'number',
            }, {
                name: 'schedule881221',
                text: 'Withheld social security, Medicare, and Additional Medicare taxes from Form(s) W-2, boxes 4 and 6',
                line: 'Line 21',
                type: 'number',
            },
            {
                name: 'schedule881222',
                text: 'Enter the total of the amounts from Schedule 1 (Form 1040), line 15; Schedule 2 (Form 1040), line 5; Schedule 2 (Form 1040), line 6; and Schedule 2 (Form 1040), line 13',
                line: 'Line 22',
                type: 'number',
            },
            {
                name: 'schedule881223',
                text: 'Add lines 21 and 22',
                line: 'Line 23',
                type: 'number',
            },
            {
                name: 'schedule881224',
                text: 'Enter the total of the amounts from Form 1040 or 1040-SR, line 27, and Schedule 3 (Form 1040), line 11',
                line: 'Line 24',
                type: 'number',
            },
            {
                name: 'schedule881225',
                text: 'Subtract line 24 from line 23. If zero or less, enter -0-',
                line: 'Line 25',
                type: 'number',
            },
            {
                name: 'schedule881226',
                text: 'Enter the larger of line 20 or line 25',
                line: 'Line 26',
                type: 'number',
            },
            {
                name: 'schedule881227',
                text: 'This is your additional child tax credit. Enter this amount on Form 1040, 1040-SR, or 1040-NR, line 28',
                line: 'Line 27',
                type: 'number',
            },
        ],
    },
    {
        section: 'Other Ones',
        fields: [
            {
                name: 'contributionMade457_1',
                text: 'Contribution made to plan 457',
                line: 'Form 8880 Line 2',
                type: 'number',
            },
            {
                name: 'contributionMade457_2',
                text: 'Spouse contribution made to plan 457',
                line: 'Form 8880 Line 2',
                type: 'number',
            },
            {
                name: 'line3form2441',
                text: 'line 3 form 2441',
                line: 'Line 3 form 2441',
                type: 'number',
            },
            {
                name: 'clientHasForm2441',
                text: 'Has Form 2441?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'hasChildren',
                text: 'Has children?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'hasForm8863',
                text: 'Has Form 8863?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm1120',
                text: 'Has Form 1120?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm1120S',
                text: 'Has Form 1120S?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm8839',
                text: 'Has Form 8839?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm6251',
                text: 'Has Form 6251?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm5695',
                text: 'Has Form 5695?',
                type: 'string',
                options: ['Yes', 'No'],
            },
        ],
    },
]