import { Button, Icon, Input, Segment, Select, Checkbox, TextArea } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { addEstateManualInputAsset, removeEstateManualInputAsset, setEstateManualInputField } from '../../actions/estate-manual-input.actions';
import { v4 as uuid } from 'uuid';
import { InputItem } from '../shared/EstateInputItem';
import { SelectFamilyMember } from '../shared/SelectFamilyMember';
import { useEffect, useState } from 'react';
import { FamilyMembersModal } from '../modal/FamilyMembersModal';
import { usStates } from '../../data/usStates';
// import { WhenWillItTransferToPrimaryBeneficiariesOptions } from './TrustAndWillCommon';

const TrustGuardians = ({ docId, version }) => {
    const dispatch = useDispatch();

    const estateManualInputAssets = useSelector(state => state.estateManualInputAssets);
    // const estateManualInput = useSelector(state => state.estateManualInput);

    const guardianMinorChildren = estateManualInputAssets?.[`${docId}_guardianMinorChildren`];

    const addGuardianBtn_Click = (beneficiaryType) => {
        return () => {
            const id = uuid();
            dispatch(addEstateManualInputAsset(`${docId}_guardianMinorChildren`, id));
            dispatch(setEstateManualInputField(docId, `guardianType___${id}`, beneficiaryType || 'guardian'));
        }
    };

    const removeGuardianBtn_Click = (asset) => {
        return () => {
            dispatch(removeEstateManualInputAsset(
                `${docId}_guardianMinorChildren`,
                asset.id,
                ['guardian', 'guardianType'],
                docId,
            ));
        }
    };

    return (
        <div style={{ marginBottom: '20px' }}>
            <h4>Guardian for minor childrens</h4>
            {guardianMinorChildren?.length > 0 &&
                <Segment.Group>
                    {guardianMinorChildren?.map(asset => {
                        return (
                            <Segment key={asset.id}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                    <SelectFamilyMember
                                        docType={docId}
                                        style={{ marginBottom: '0px', marginRight: '10px' }}
                                        field={`guardian___${asset.id}`}
                                        version={version}
                                    />
                                    <Button color='red' onClick={removeGuardianBtn_Click(asset)}>Remove</Button>
                                </div>
                            </Segment>
                        )
                    })}
                </Segment.Group>
            }
            <Button onClick={addGuardianBtn_Click('guardian')}>Add Guardian</Button>
        </div>
    )
}

const WillCreatesTypeOfTrust = ({ docId, version }) => {
    const estateManualInput = useSelector(state => state.estateManualInput);
    const client = useSelector(state => state.currentClient);

    const [showTrustsModal, setShowTrustsModal] = useState(false);

    if (estateManualInput?.docId?.['createsTypeOfTrust'] !== 'Yes') {
        return <></>;
    }

    const trustOptions = client?.estateContactsList?.filter(c => c.type === 'trust').map(t => ({ key: t._id, value: t._id, text: `${t.firstName} ${t.lastName}` })) || [];

    return (<div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <div style={{ flex: 1, marginRight: '10px' }}>
            <InputItem
                indent={4}
                docType={docId}
                label='Specify the trust'
                field='typeOfTrust'
                nOptions={trustOptions}
                style={{ marginBottom: '0' }}
                version={version}
            />
        </div>
        <Button onClick={() => setShowTrustsModal(true)}>Create trust</Button>
        <FamilyMembersModal open={showTrustsModal} closeBtn_Click={() => setShowTrustsModal(false)} type='trusts' />
    </div>)
}

const WillAssets = ({ docId, version }) => {
    const dispatch = useDispatch();

    const client = useSelector(state => state.currentClient);
    const estateManualInputAssets = useSelector(state => state.estateManualInputAssets);
    const estateManualInput = useSelector(state => state.estateManualInput);
    const estateManualInputRequest = useSelector(state => state.estateManualInputRequest);
    const estateManualInputChange = useSelector(state => state.estateManualInputChange);

    const willAssets = estateManualInputAssets?.[docId];
    const self = client?.estateContactsList?.find(c => c.relation === 'Self');
    const selfFullName = `${self.firstName} ${self.lastName}`;
    const spouse = client?.estateContactsList?.filter(itm => itm.relation === 'Spouse')[0] || false;
    const spouseFullName = spouse ? `${spouse.firstName} ${spouse.lastName}` : 'N/A';

    const firstDeathTriggerOptions = [
        'Upon death of first settlor',
        `Upon death of ${selfFullName} if spouse (${spouseFullName}) survives them`,
        `Upon death of ${spouseFullName} if spouse (${selfFullName}) survives them`,
        'Upon death of either settlor',
        'Upon death of either settlor, during lifetime of surviving settlor',
        'During lifetime of surviving settlor',
        'Other',
    ];
    const secondDeathTriggerOptions = [
        'Upon death of surviving settlor',
        `Upon death of surviving spouse`,
        `Upon death of ${selfFullName} if they are not survived by ${spouseFullName}`,
        `Upon death of ${spouseFullName} if they are not survived by ${selfFullName}`,
        'Upon death of second settlor/grantor',
        `Upon death of ${selfFullName} if ${selfFullName} predeceases ${spouseFullName}`,
        `Upon death of ${spouseFullName} if ${spouseFullName} predeceases ${selfFullName}`,
        'Upon death of settlor/grantors',
        'Other',
    ];
    const beforeAnyDeathTriggerOptions = [
        `During the lifetime of (${selfFullName}), and before the death of (${spouseFullName})`,
        `During the lifetime of ${selfFullName}`,
        `During the lifetime of (${spouseFullName}), and before the death of (${selfFullName})`,
        `During the lifetime of ${spouseFullName}`,
    ];

    const trustNameOptions = client?.estateContactsList
        ?.filter(c => c.relation === 'Trust')
        ?.map(c => ({ key: c._id, value: c._id, text: `${c.firstName}` }))
        || [];

    const addAssetBtn_Click = () => {
        const id = uuid();
        dispatch(addEstateManualInputAsset(docId, id));
    }

    const removeAssetBtn_Click = (asset) => {
        return () => {
            dispatch(removeEstateManualInputAsset(
                docId,
                asset.id,
                ['assetType', 'primaryBeneficiaries', 'contingentBeneficiaries', 'whenWillTransfer'],
            ));
        }
    };

    const findRelation = (relativeId, relation) => {
        if (relation === 'Self') {
            return 'Self';
        }

        let contact = client?.estateContactsList?.find(c => c._id === relativeId);
        if (!contact) {
            contact = self;
        }

        return `${contact.firstName}'s ${relation}`;
    };

    const addBeneficiaryBtn_Click = (beneficiaryType, internalAssetId) => {
        return () => {
            const datapoint = `${beneficiaryType}Beneficiaries___${internalAssetId}`;
            const beneficiaryId = estateManualInput?.[docId]?.[datapoint];

            let beneficiariesList = estateManualInput?.[docId]?.[`${beneficiaryType}BeneficiariesList___${internalAssetId}`] || '[]';
            beneficiariesList = JSON.parse(beneficiariesList);
            const contactsIds = beneficiariesList.map(b => b.contactId);

            if (!contactsIds.includes(beneficiaryId)) {
                beneficiariesList.push({ contactId: beneficiaryId, amountType: '%', amount: '0' });
                dispatch(setEstateManualInputField(docId, `${beneficiaryType}BeneficiariesList___${internalAssetId}`, JSON.stringify(beneficiariesList)));
            }

            dispatch(setEstateManualInputField(docId, `${beneficiaryType}Beneficiaries___${internalAssetId}`, ''));
        }
    }

    const removeBeneficiaryBtn_Click = (beneficiaryType, internalAssetId, contactId) => {
        return () => {
            let beneficiariesList = estateManualInput?.[docId]?.[`${beneficiaryType}BeneficiariesList___${internalAssetId}`] || '[]';
            beneficiariesList = JSON.parse(beneficiariesList);

            const index = beneficiariesList.indexOf(contactId);
            beneficiariesList.splice(index, 1);
            dispatch(setEstateManualInputField(docId, `${beneficiaryType}BeneficiariesList___${internalAssetId}`, JSON.stringify(beneficiariesList)));
        }
    }

    const beneficiaryAmount_Change = (beneficiary, beneficiaryType, internalAssetId) => {
        return (e, elem) => {
            let beneficiariesList = estateManualInput?.[docId]?.[`${beneficiaryType}BeneficiariesList___${internalAssetId}`] || '[]';
            beneficiariesList = JSON.parse(beneficiariesList);

            const beneficiaryInList = beneficiariesList.find(b => b.contactId === beneficiary.contactId);
            const amount = parseFloat(Math.abs(elem.value)).toString().replace(/^0+/, '');
            if (beneficiaryInList) {
                beneficiaryInList.amount = amount;
                dispatch(setEstateManualInputField(docId, `${beneficiaryType}BeneficiariesList___${internalAssetId}`, JSON.stringify(beneficiariesList)));
            }
        }
    }

    const beneficiaryAmountType_Change = (beneficiary, beneficiaryType, internalAssetId) => {
        return (e, elem) => {
            let beneficiariesList = estateManualInput?.[docId]?.[`${beneficiaryType}BeneficiariesList___${internalAssetId}`] || '[]';
            beneficiariesList = JSON.parse(beneficiariesList);

            const beneficiaryInList = beneficiariesList.find(b => b.contactId === beneficiary.contactId);
            if (beneficiaryInList) {
                beneficiaryInList.amountType = elem.value;
                dispatch(setEstateManualInputField(docId, `${beneficiaryType}BeneficiariesList___${internalAssetId}`, JSON.stringify(beneficiariesList)));
            }
        }
    }

    /* const beneficiaryAgeToReach_Change = (beneficiary, beneficiaryType, internalAssetId) => {
        return (e, elem) => {
            let beneficiariesList = estateManualInput?.[docId]?.[`${beneficiaryType}BeneficiariesList___${internalAssetId}`] || '[]';
            beneficiariesList = JSON.parse(beneficiariesList);
    
            const beneficiaryInList = beneficiariesList.find(b => b.contactId === beneficiary.contactId);
            if (beneficiaryInList) {
                beneficiaryInList.ageToReach = elem.value;
                dispatch(setEstateManualInputField(docId, `${beneficiaryType}BeneficiariesList___${internalAssetId}`, JSON.stringify(beneficiariesList)));
            }
        }
    } */

    const beneficiaryComment_Change = (beneficiary, beneficiaryType, internalAssetId) => {
        return (e, elem) => {
            let beneficiariesList = estateManualInput?.[docId]?.[`${beneficiaryType}BeneficiariesList___${internalAssetId}`] || '[]';
            beneficiariesList = JSON.parse(beneficiariesList);
    
            const beneficiaryInList = beneficiariesList.find(b => b.contactId === beneficiary.contactId);
            if (beneficiaryInList) {
                beneficiaryInList.comment = elem.value;
                dispatch(setEstateManualInputField(docId, `${beneficiaryType}BeneficiariesList___${internalAssetId}`, JSON.stringify(beneficiariesList)));
            }
        }
    }

    const beneficiaryPerStirpes_Change = (beneficiary, beneficiaryType, internalAssetId) => {
        return (e, elem) => {
            let beneficiariesList = estateManualInput?.[docId]?.[`${beneficiaryType}BeneficiariesList___${internalAssetId}`] || '[]';
            beneficiariesList = JSON.parse(beneficiariesList);

            const beneficiaryInList = beneficiariesList.find(b => b.contactId === beneficiary.contactId);
            if (beneficiaryInList) {
                beneficiaryInList.perStirpes = elem.checked;
                dispatch(setEstateManualInputField(docId, `${beneficiaryType}BeneficiariesList___${internalAssetId}`, JSON.stringify(beneficiariesList)));
            }
        }
    }

    const BeneficiariesList = (beneficiaryType, internalAssetId) => {
        let beneficiariesList = estateManualInput?.[docId]?.[`${beneficiaryType}BeneficiariesList___${internalAssetId}`] || '[]';
        beneficiariesList = JSON.parse(beneficiariesList);
        beneficiariesList = beneficiariesList?.map(beneficiary => ({ ...beneficiary, contact: client?.estateContactsList?.find(c => c._id === beneficiary.contactId) }));

        const blanckOption = [{ value: '', text: ' ' }];

        const fillOptions = [
            { value: '%', text: '%' },
            { value: '$', text: '$' },
        ];

        let amountTypeOptions = []
        if (
            estateManualInput[docId]?.[`assetId___${internalAssetId}`] === client?.estateAssetsList?.filter(
                itm => itm.name === 'Net Income and Principal'
            )[0]?._id
        ) {
            amountTypeOptions = [...blanckOption, ...fillOptions]
        } else {
            amountTypeOptions = [...fillOptions]
        }

        return (<div style={{ display: beneficiariesList?.length > 0 ? 'block' : 'none', margin: '10px 0 10px 100px' }}>
            {beneficiariesList.map(beneficiary => {
                return <>
                    <div style={{
                        padding: '10px', borderBottom: '1px solid #e8e8e8', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                        border: estateManualInputChange && docId in estateManualInputChange && `${beneficiaryType}BeneficiariesList___${internalAssetId}` in estateManualInputChange[docId] ? '1px solid rgba(21, 97, 198, 0.5)' : 'none',
                        borderRadius: '0.28571429rem',
                        boxShadow: estateManualInputChange && docId in estateManualInputChange && `${beneficiaryType}BeneficiariesList___${internalAssetId}` in estateManualInputChange[docId] ? 'rgba(21, 97, 198, 0.5) 0px 1px 10px' : 'none'
                    }}>
                        <div>{beneficiary.contact?.firstName} {beneficiary.contact?.lastName} [{findRelation(beneficiary.contact?.relatedTo, beneficiary.contact?.relation)}]</div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {[2,3,4].includes(estateManualInputRequest.estateSnapshotVersion) &&
                                <div style={{ width: '100px', display: 'inline-block' }}>
                                    <Checkbox
                                        checked={beneficiary.perStirpes}
                                        label='per stirpes'
                                        value={beneficiary.perStirpes}
                                        onChange={beneficiaryPerStirpes_Change(beneficiary, beneficiaryType, internalAssetId)}
                                    />
                                </div>
                            }

                            <div style={{ width: '50px', display: 'inline-block' }}>
                                <Select fluid
                                    options={amountTypeOptions}
                                    defaultValue={beneficiary.amount !== "0" ? beneficiary.amountType : '%'}
                                    onChange={beneficiaryAmountType_Change(beneficiary, beneficiaryType, internalAssetId)}
                                    type="number"
                                />
                            </div>
                            <Input
                                style={{ width: '120px', margin: '0 30px 0 5px' }}
                                value={beneficiary.amount}
                                onChange={beneficiaryAmount_Change(beneficiary, beneficiaryType, internalAssetId)}
                                type="number"
                            />
                            <Icon name='delete' inverted color='black' size='large' onClick={removeBeneficiaryBtn_Click(beneficiaryType, internalAssetId, beneficiary.contact?._id)} />
                        </div>
                    </div>
                    <div
                        style={{
                            padding: '10px 0',
                            borderBottom: '1px solid #e8e8e8',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            marginLeft: '119px',
                            columnGap: '16px',
                        }}
                    >
                        Comments:
                        <TextArea
                            value={beneficiary.comment}
                            onChange={beneficiaryComment_Change(beneficiary, beneficiaryType, internalAssetId)}
                            placeholder="Write your comments"
                            style={{
                                width: '100%',
                                marginLeft: '15px',
                                border: '1px solid rgba(34, 36, 38, 0.15)',
                                padding: '0.7em 1em',
                                borderRadius: '5px',
                                '&::focus': {
                                    border: '1px solid #85b7d9'
                                },
                            }}
                        />
                    </div>
                </>
            })}
        </div>);
    };

    const handlerReceiveAssets = (doc, field, val) => {
        const asset = field.split('___')[1];
        const actions = {
            [`remainderBeneficiariesReceiveAssetsOutright___${asset}`]: {
                Yes: () => {
                    dispatch(setEstateManualInputField(doc, field, 'Yes'));
                    dispatch(setEstateManualInputField(doc, `remainderBeneficiariesReceiveAssetsInTrust___${asset}`, 'No'));
                    estateManualInput?.[doc]?.[`whatProvisionsAreThere___${asset}`]
                        && dispatch(setEstateManualInputField(doc, `whatProvisionsAreThere___${asset}`, undefined));
                },
                No: () => {
                    dispatch(setEstateManualInputField(doc, field, 'No'));
                    dispatch(setEstateManualInputField(doc, `remainderBeneficiariesReceiveAssetsInTrust___${asset}`, 'Yes'));
                    dispatch(setEstateManualInputField(doc, `whatProvisionsAreThere___${asset}`, 'Until beneficiary reaches age'));
                },
            },
            [`remainderBeneficiariesReceiveAssetsInTrust___${asset}`]: {
                Yes: () => {
                    dispatch(setEstateManualInputField(doc, field, 'Yes'));
                    dispatch(setEstateManualInputField(doc, `remainderBeneficiariesReceiveAssetsOutright___${asset}`, 'No'));
                    dispatch(setEstateManualInputField(doc, `whatProvisionsAreThere___${asset}`, 'Until beneficiary reaches age'));
                },
                No: () => {
                    dispatch(setEstateManualInputField(doc, field, 'No'));
                    dispatch(setEstateManualInputField(doc, `remainderBeneficiariesReceiveAssetsOutright___${asset}`, 'Yes'));
                    estateManualInput?.[doc]?.[`whatProvisionsAreThere___${asset}`]
                        && dispatch(setEstateManualInputField(doc, `whatProvisionsAreThere___${asset}`, undefined));
                    
                },
            },
        };
        actions[field][val]();
    };

    return (
        <div style={{ marginBottom: '20px' }}>
            {willAssets?.length > 0 &&
                <div>
                    {willAssets?.map(asset => {
                        return (
                            <Segment key={asset.id}>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'start', marginBottom: '10px' }}>
                                    <InputItem
                                        docType={docId}
                                        style={{ marginBottom: '0px', marginRight: '10px' }}
                                        nOptions={client?.estateAssetsList?.map(asset => ({ key: asset._id, value: asset._id, text: asset.name }))}
                                        field={`assetId___${asset.id}`}
                                        ignoreBorder
                                        version={version}
                                        withoutPages
                                    />
                                    <Button color='red' onClick={removeAssetBtn_Click(asset)}>Remove</Button>
                                </div>
                                <InputItem
                                    docType={docId}
                                    style={{ marginRight: '10px' }}
                                    nOptions={[{ key: 'distributionsMandatoryIncomeYes', value: 'Yes', text: 'Yes', }, { key: 'distributionsMandatoryIncomeNo', value: 'No', text: 'No', }]}
                                    field={`areDistributionsMandatoryIncomeAndInterest___${asset.id}`}
                                    label="Are distributions mandatory - income and interest?"
                                    version={version}
                                    visible={!!(estateManualInput[docId]?.[`assetId___${asset.id}`] === client?.estateAssetsList?.filter(itm => itm.name === 'Net Income and Principal')[0]?._id)}
                                />
                                <InputItem
                                    docType={docId}
                                    style={{ marginRight: '10px' }}
                                    nOptions={[{ key: 'istributionsMandatoryPrincipalYes', value: 'Yes', text: 'Yes', }, { key: 'istributionsMandatoryPrincipalNo', value: 'No', text: 'No', }]}
                                    field={`areDistributionsMandatoryPrincipal___${asset.id}`}
                                    label="Are distributions mandatory - principal?"
                                    version={version}
                                    visible={!!(estateManualInput[docId]?.[`assetId___${asset.id}`] === client?.estateAssetsList?.filter(itm => itm.name === 'Net Income and Principal')[0]?._id)}
                                />
                                <InputItem
                                    docType={docId}
                                    style={{ marginRight: '10px' }}
                                    options={['Annuity', 'Unitrust']}
                                    field={`annuityOrUnitrust___${asset.id}`}
                                    label="Annuity or Unitrust?"
                                    version={version}
                                    visible={!!(estateManualInput[docId]?.[`assetId___${asset.id}`] === client?.estateAssetsList?.filter(itm => itm.name === 'Net Income and Principal')[0]?._id)}
                                />
                                <InputItem
                                    docType={docId}
                                    style={{ marginRight: '10px' }}
                                    field={`annuityAmount___${asset.id}`}
                                    label="Annuity Amount ($)"
                                    version={version}
                                    visible={!!(estateManualInput[docId]?.[`assetId___${asset.id}`] === client?.estateAssetsList?.filter(itm => itm.name === 'Net Income and Principal')[0]?._id) && !!(estateManualInput[docId]?.[`annuityOrUnitrust___${asset.id}`] === 'Annuity')}
                                    type="number"
                                    indent={16}
                                />
                                <InputItem
                                    docType={docId}
                                    style={{ marginRight: '10px' }}
                                    field={`unitrustPercentage___${asset.id}`}
                                    label="Unitrust Percentage (%)"
                                    version={version}
                                    visible={!!(estateManualInput[docId]?.[`assetId___${asset.id}`] === client?.estateAssetsList?.filter(itm => itm.name === 'Net Income and Principal')[0]?._id) && !!(estateManualInput[docId]?.[`annuityOrUnitrust___${asset.id}`] === 'Unitrust')}
                                    type="number"
                                    indent={16}
                                />
                                <h4>How will remainder beneficiaries receive assets?</h4>
                                <InputItem docType={docId} label='Outright?' field={`remainderBeneficiariesReceiveAssetsOutright___${asset.id}`}  version={[1,2,3,4]} options={['Yes', 'No']} customChange={handlerReceiveAssets} />
                                <InputItem docType={docId} label='In trust?' field={`remainderBeneficiariesReceiveAssetsInTrust___${asset.id}`}  version={[1,2,3,4]} options={['Yes', 'No']} customChange={handlerReceiveAssets} />
                                <InputItem
                                    docType={docId}
                                    label='What provisions are there?'
                                    field={`whatProvisionsAreThere___${asset.id}`}
                                    version={[1,2,3,4]}
                                    options={['Until beneficiary reaches age']}
                                    visible={!!(estateManualInput[docId]?.[`remainderBeneficiariesReceiveAssetsInTrust___${asset.id}`] === 'Yes')}
                                    indent={4}
                                />
                                <InputItem
                                    indent={8}
                                    label='Trust'
                                    withoutPages
                                    isRequired
                                    version={[3, 4]}
                                    docType={docId}
                                    nOptions={trustNameOptions.filter(itm => itm.value !== '')}
                                    field={`whatTrustContainsProvisions___${asset.id}`}
                                    visible={!!(estateManualInput[docId]?.[`remainderBeneficiariesReceiveAssetsInTrust___${asset.id}`] === 'Yes')}
                                />
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                    <SelectFamilyMember docType={docId} label='Primary beneficiaries' style={{ marginBottom: '0px', marginRight: '10px' }} field={`primaryBeneficiaries___${asset.id}`} version={version} message={'Different Answer'} />
                                    <Button onClick={addBeneficiaryBtn_Click('primary', asset.id)}>Add</Button>
                                </div>
                                {BeneficiariesList('primary', asset.id)}
                                <InputItem docType={docId} label='When will it transfer to primary beneficiaries?' field={`whenWillTransferPrimaryBeneficiary___${asset.id}`}  version={[1,2]} withoutPages />
                                <InputItem docType={docId} label='Is it first death or second death of primary beneficiaries?' field={`isItFirstDeathOrSecondDeathPrimaryBeneficiaries___${asset.id}`} version={[3,4]} options={['First death', 'Second death', 'Before any death']} />
                                <InputItem docType={docId} label='When will it transfer to primary beneficiaries?' field={`whenWillTransferPrimaryBeneficiary___${asset.id}`} version={[3,4]} options={firstDeathTriggerOptions} indent={4} visible={!!(estateManualInput[docId]?.[`isItFirstDeathOrSecondDeathPrimaryBeneficiaries___${asset.id}`] === 'First death')} addOptions />
                                <InputItem docType={docId} label='When will it transfer to primary beneficiaries?' field={`whenWillTransferPrimaryBeneficiary___${asset.id}`} version={[3,4]} options={secondDeathTriggerOptions} indent={4} visible={!!(estateManualInput[docId]?.[`isItFirstDeathOrSecondDeathPrimaryBeneficiaries___${asset.id}`] === 'Second death')} addOptions />
                                <InputItem docType={docId} label='When will it transfer to primary beneficiaries?' field={`whenWillTransferPrimaryBeneficiary___${asset.id}`} version={[3,4]} options={beforeAnyDeathTriggerOptions} indent={4} visible={!!(estateManualInput[docId]?.[`isItFirstDeathOrSecondDeathPrimaryBeneficiaries___${asset.id}`] === 'Before any death')} addOptions />

                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: '10px' }}>
                                    <SelectFamilyMember docType={docId} label='Contingent beneficiaries' style={{ marginBottom: '0px', marginRight: '10px' }} field={`contingentBeneficiaries___${asset.id}`} version={version} withoutPages />
                                    <Button onClick={addBeneficiaryBtn_Click('contingent', asset.id)}>Add</Button>
                                </div>
                                {BeneficiariesList('contingent', asset.id)}
                                <InputItem docType={docId} label='Is it first death or second death of contingent beneficiaries?' field={`isItFirstDeathOrSecondDeathContingentBeneficiaries___${asset.id}`} version={[3,4]} options={['First death', 'Second death']} />
                                <InputItem docType={docId} label='When will it transfer to contingent beneficiaries?' field={`whenWillTransferContingentBeneficiary___${asset.id}`} version={[3,4]} options={firstDeathTriggerOptions} indent={4} visible={!!(estateManualInput[docId]?.[`isItFirstDeathOrSecondDeathContingentBeneficiaries___${asset.id}`] === 'First death')} addOptions />
                                <InputItem docType={docId} label='When will it transfer to contingent beneficiaries?' field={`whenWillTransferContingentBeneficiary___${asset.id}`} version={[3,4]} options={secondDeathTriggerOptions} indent={4} visible={!!(estateManualInput[docId]?.[`isItFirstDeathOrSecondDeathContingentBeneficiaries___${asset.id}`] === 'Second death')} addOptions />
                                <InputItem docType={docId} label='When will it transfer to contingent beneficiaries?' field={`whenWillTransferContingentBeneficiary___${asset.id}`} version={[3,4]} options={beforeAnyDeathTriggerOptions} indent={4} visible={!!(estateManualInput[docId]?.[`isItFirstDeathOrSecondDeathContingentBeneficiaries___${asset.id}`] === 'Before any death')} addOptions />

                                {estateManualInputRequest.estateSnapshotVersion !== 2 &&
                                    <InputItem docType={docId} multiline label='Additional information' field={`additionalInfo___${asset.id}`} ignoreBorder version={version} />
                                }
                            </Segment>
                        )
                    })}
                </div>
            }
            <Button style={{ marginTop: '10px' }} onClick={addAssetBtn_Click}>Add Asset</Button>
        </div>
    )
}

const WillExectutors = ({ docId, version }) => {
    const [optionsSuccesor, setOptionsSuccesor] = useState([])
    const dispatch = useDispatch();

    const estateManualInputAssets = useSelector(state => state.estateManualInputAssets);
    const estateManualInput = useSelector(state => state.estateManualInput);
    const client = useSelector(state => state.currentClient);

    const willExecutors = estateManualInputAssets?.[`${docId}_executors`];

    const addExecutorBtn_Click = (executorType) => {
        return () => {
            const id = uuid();
            dispatch(addEstateManualInputAsset(`${docId}_executors`, id));
            dispatch(setEstateManualInputField(docId, `executorType___${id}`, executorType || 'executor'));
        }
    };

    const removeExecutorBtn_Click = (asset) => {
        return () => {
            dispatch(removeEstateManualInputAsset(
                `${docId}_executors`,
                asset.id,
                ['executor', 'executorType'],
                docId,
            ));
        }
    };

    const executorTypes = {
        'executor': 'Main',
        'successive_executor': 'Successor',
        'alternate_executor': 'Alternate',
    };

    useEffect(() => {
        setOptionsSuccesor(client?.estateContactsList?.map(c => ({ key: c._id, value: c._id, text: `${c.firstName} ${c.lastName}` })) || [])

        // eslint-disable-next-line
    },[client])
    return (
        <div style={{ marginBottom: '20px' }}>
            <h4>Executors</h4>
            {willExecutors?.length > 0 &&
                <Segment.Group>
                    {willExecutors?.map(asset => {
                        return (
                            <Segment key={asset.id}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                    {/* <h4>Executor #{asset.id.substring(0, 8)}</h4> */}
                                    <h4 style={{ margin: '10px 10px 0 0' }}>{executorTypes[estateManualInput?.[docId]?.[`executorType___${asset.id}`]]}</h4>
                                    <InputItem
                                        docType={docId}
                                        // label='Name'
                                        style={{ marginBottom: '0px', marginRight: '10px' }}
                                        nOptions={optionsSuccesor}
                                        field={`executor___${asset.id}`}
                                        ignoreBorder
                                        version={version}
                                    />
                                    <Button color='red' onClick={removeExecutorBtn_Click(asset)}>Remove</Button>
                                </div>
                            </Segment>
                        )
                    })}
                </Segment.Group>
            }
            <Button onClick={addExecutorBtn_Click('executor')}>Add Executor</Button>
            <Button onClick={addExecutorBtn_Click('successive_executor')}>Add Successor Executor</Button>
            {/* <Button onClick={addExecutorBtn_Click('alternate_executor')}>Add Alternate Executor</Button> */}
        </div>
    )
}

const WillAssetsContainer = ({ docId, version }) => {
    const estateManualInput = useSelector(state => state.estateManualInput);

    if (estateManualInput?.[docId]?.willListAssets !== 'Yes') {
        return <></>;
    }

    return <WillAssets docId={docId} version={version} />;
}

const AgentsBlock = ({ docId, parentField, version }) => {
    const estateManualInput = useSelector(state => state.estateManualInput);

    if (estateManualInput?.[docId]?.[parentField] !== 'Yes') {
        return <></>;
    }

    const statesOptions = [
        { key: '', value: '', text: '' },
        ...Object.keys(usStates).map(state => ({
            key: usStates[state],
            value: state,
            text: usStates[state],
        })),
    ];

    return (
        <div>
            {parentField === 'healthCarePOAs' &&
                <>
                    <InputItem docType={docId} indent={4} label='Date' field={`${parentField}_dateOfHealth`} version={version} withoutPages />
                    {/* <InputItem docType={docId} indent={4} label='Health care POA Grantor' field={`${parentField}_grantor`}version={version} /> */}
                    <InputItem docType={docId} indent={4} label='Health care POA Agent' field={`${parentField}_agent`} version={version} />
                    <InputItem docType={docId} indent={4} label='Health care POA Succesive Agents' field={`${parentField}_succesiveAgents`} version={version} />
                    <InputItem docType={docId} indent={4} label={`Is there a HIPAA Paragraph?`} field={`${parentField}_hipaaParagraph`} options={['Yes', 'No']} version={version} />
                    <InputItem
                        docType={docId}
                        indent={4}
                        label={`State`}
                        field={`${parentField}_state`}
                        nOptions={statesOptions}
                        version={version}
                    />
                </>}
            {parentField !== 'healthCarePOAs' &&
                <>
                    <InputItem docType={docId} indent={4} label='Date of POA' field={`${parentField}_dateOfPOA`} version={version} withoutPages />
                    <InputItem docType={docId} indent={4} label={`Agent's name`} field={`${parentField}_agentName`} version={version} />
                    <InputItem docType={docId} indent={4} label={`Successive agents`} field={`${parentField}_successiveAgents`} version={version} />
                    {parentField === 'powerOfAttorney' &&
                        <>
                            <InputItem docType={docId} indent={4} label={`Is it springing?`} field={`${parentField}_springing`} options={['Yes', 'No']} version={version} />
                            <InputItem docType={docId} indent={4} label={`Are there elder abuse provisions?`} field={`${parentField}_elderAbuseProvisions`} options={['Yes', 'No']} version={version} />
                            <InputItem docType={docId} indent={4} label={`Can the agent make gift and other tax-related decisions?`} field={`${parentField}_taxRelatedDecisions`} options={['Yes', 'No']} version={version} />
                            <InputItem
                                docType={docId}
                                indent={4}
                                label={`State`}
                                field={`${parentField}_state`}
                                nOptions={statesOptions}
                                version={version}
                            />
                        </>}
                </>}
            <InputItem docType={docId} indent={4} label={`Add material information`} field={`${parentField}_materialInfo`} multiline version={version} withoutPages />
        </div>
    )
}

const OtherInformationBlocks = ({ docId, version }) => {
    const dispatch = useDispatch();const client = useSelector(state => state.currentClient);

    const estateManualInput = useSelector(state => state.estateManualInput);
    const estateManualInputAssets = useSelector(state => state.estateManualInputAssets);
    const estateManualInputRequest = useSelector(state => state.estateManualInputRequest);

    const additionalInfoBlocks = estateManualInputAssets?.[`${docId}_additionalInfo`];

    const addBlockBtn_Click = () => {
        const id = uuid();
        dispatch(addEstateManualInputAsset(`${docId}_additionalInfo`, id));
    }

    const removeBlockBtn_Click = (blockId) => {
        return () => {
            dispatch(removeEstateManualInputAsset(`${docId}_additionalInfo`, blockId, ['content']));
        }
    }

    if (!version?.includes(estateManualInputRequest.estateSnapshotVersion)) {
        return <></>;
    }

    return (
        <div>
            <p>What other material information is contained in the document?</p>
            {additionalInfoBlocks?.map((block, i) => {
                return(<>
                    <InputItem
                        docType={docId}
                        label='Category'
                        field={`contentCategory___${block.id}`}
                        options={[
                            'At the end of the trust',
                            'Disclaimer clause',
                            'Simultaneous death clause',
                            'Generation skipping provision?',
                            'Spendthrift clause',
                            'Specific assets named in trust',
                            'Crummey Power',
                            'Other category',
                        ]}
                        addOptions
                        version={[3,4]}
                        indent={4}
                        rightComponent={() => {
                            return <Button color='red' style={{ marginLeft: '10px' }} onClick={removeBlockBtn_Click(block.id)}>
                                Remove
                            </Button>
                        }}
                    />
                    <InputItem
                        docType={docId}
                        label='When does this trust end?:'
                        field={`contentAtTheEndOfTheTrust___${block.id}`}
                        version={[3,4]}
                        type={'number'}
                        indent={8}
                        options={[
                            'Lifetime of settlor',
                            'Lifetime of someone else',
                        ]}
                        visible={!!(estateManualInput?.[docId]?.[`contentCategory___${block.id}`] === 'At the end of the trust')}
                    />
                    <SelectFamilyMember
                        docType={docId}
                        label='Who?'
                        field={`contentLifetimeOfSomeoneElsePersons___${block.id}`}
                        version={[3,4]}
                        style={{ display: estateManualInput?.[docId]?.[`contentAtTheEndOfTheTrust___${block.id}`] === 'Lifetime of someone else' ? 'flex' : 'none'}}
                        indent={8}
                    />
                    <InputItem docType={docId} label='Term:' field={`contentTermOfTrust___${block.id}`} version={[3,4]} type={'number'} indent={8} visible={!!(estateManualInput?.[docId]?.[`contentCategory___${block.id}`] === 'At the end of the trust') && !!(estateManualInput?.[docId]?.[`contentAtTheEndOfTheTrust___${block.id}`] === 'Lifetime of someone else')} />
                    <InputItem docType={docId} label='Event:' field={`contentEventOfTrust___${block.id}`} version={[3,4]} multiline indent={8} visible={!!(estateManualInput?.[docId]?.[`contentCategory___${block.id}`] === 'At the end of the trust') && !!(estateManualInput?.[docId]?.[`contentAtTheEndOfTheTrust___${block.id}`] === 'Lifetime of someone else')} />
                    <SelectFamilyMember
                        docType={docId}
                        label="Who?"
                        field={`contentSimultaneousDeathClauseSersons___${block.id}`}
                        version={[3,4]}
                        style={{ display: estateManualInput?.[docId]?.[`contentCategory___${block.id}`] === 'Simultaneous death clause' ? 'flex' : 'none'}}
                        indent={4}
                        only="self and spouse"
                    />
                    <InputItem docType={docId} label='Specific assets named in trust:' field={`contentAssetsNamedInTrust___${block.id}`} nOptions={client?.estateAssetsList?.map(asset => ({ key: asset._id, value: asset._id, text: asset.name }))} version={[3,4]} indent={4} visible={!!(estateManualInput?.[docId]?.[`contentCategory___${block.id}`] === 'Specific assets named in trust')} />
                    <InputItem docType={docId} label='Funding asset (%):' field={`contentAssetsNamedInTrustPercentage___${block.id}`} version={[3,4]} type="number" min={0} max={100} indent={8} visible={!!(estateManualInput?.[docId]?.[`contentCategory___${block.id}`] === 'Specific assets named in trust')} />
                    {/* <InputItem
                        docType={docId}
                        label='Comments'
                        field={`content___comments___${block.id}`}
                        version={[3,4]}
                        multiline
                        indent={4}
                        visible={
                            !!(estateManualInput?.[docId]?.[`content___whenDoesThisTrustEnd___${block.id}`])
                            && !!(estateManualInput?.[docId]?.[`content___whenDoesThisTrustEnd___${block.id}`] !== 'Lifetime of settlor')
                            && !!(estateManualInput?.[docId]?.[`content___whenDoesThisTrustEnd___${block.id}`] !== 'Lifetime of someone else')
                            && !!(estateManualInput?.[docId]?.[`content___whenDoesThisTrustEnd___${block.id}`] !== 'Simultaneous death clause')
                            && !!(estateManualInput?.[docId]?.[`content___whenDoesThisTrustEnd___${block.id}`] !== 'Specific assets named in trust')
                        }
                    /> */}

                    <InputItem
                        version={version}
                        key={block.id}
                        multiline={true}
                        docType={docId}
                        field={`content___${block.id}`}
                        label={`#${i + 1}`}
                        /* rightComponent={() => {
                            return <Button color='red' style={{ marginLeft: '10px' }} onClick={removeBlockBtn_Click(block.id)}>
                                Remove
                            </Button>
                        }} */
                    />
                </>)
            })}
            <Button onClick={addBlockBtn_Click}>Add paragraph</Button>
        </div>
    )
}

export const Will = ({ docId }) => {
    // const dispatch = useDispatch();
    const client = useSelector(state => state.currentClient);
    const estateManualInput = useSelector(state => state.estateManualInput);
    const estateManualInputDocuments = useSelector(state => state.estateManualInputDocuments);

    const trustNameOptions = client?.estateContactsList
        ?.filter(c => c.relation === 'Trust')
        ?.map(c => ({ key: c._id, value: c._id, text: `${c.firstName}` }))
        || [];

    const allTrusts = [];
    for (const itm in estateManualInputDocuments) {
        if (estateManualInputDocuments[itm].type === 'Trust') {
            const newItem = {
                docId: itm,
                trustType: estateManualInput[itm]?.type || undefined,
                name: estateManualInput[itm]?.name || undefined,
            };
            allTrusts.push(newItem);
        }
    }

    const trustNameRevocableOptions = allTrusts
        ?.filter(itm => itm.trustType === 'Revocable')
        ?.map(itm => {
            const contact = client?.estateContactsList?.filter(c => c._id === itm.name);
            return {
                key: contact[0]?._id,
                value: contact[0]?._id,
                text: contact[0]?.firstName,
            };
        }) || [];


    const trustNameIrrevocableOptions = allTrusts
        ?.map(itm => {
            const contact = client?.estateContactsList?.filter(c => c._id === itm.name);
            return {
                key: contact[0]?._id,
                value: contact[0]?._id,
                text: contact[0]?.firstName,
            };
        }) || [];

    const statesOptions = [
        { key: '', value: '', text: '' },
        ...Object.keys(usStates).map(state => ({
            key: usStates[state],
            value: state,
            text: usStates[state],
        })),
    ];


    /* const handlerWillOrTrusts = (doc, field, val) => {
        const actions = {
            isItAPouroverWill: {
                Yes: () => {
                    dispatch(setEstateManualInputField(doc, field, 'Yes'));
                    dispatch(setEstateManualInputField(doc, 'areThereTestamentaryTrusts', 'No'));
                    estateManualInput[doc]?.whichTestamentaryTrust && dispatch(setEstateManualInputField(doc, 'whichTestamentaryTrust', undefined));
                    estateManualInput[doc]?.whichPouroverTrust && dispatch(setEstateManualInputField(doc, 'whichPouroverTrust', undefined));
                },
                No: () => {
                    dispatch(setEstateManualInputField(doc, field, 'No'));
                    dispatch(setEstateManualInputField(doc, 'areThereTestamentaryTrusts', 'Yes'));
                    estateManualInput[doc]?.whichTestamentaryTrust && dispatch(setEstateManualInputField(doc, 'whichTestamentaryTrust', undefined));
                    estateManualInput[doc]?.whichPouroverTrust && dispatch(setEstateManualInputField(doc, 'whichPouroverTrust', undefined));
                },
            },
            areThereTestamentaryTrusts: {
                Yes: () => {
                    dispatch(setEstateManualInputField(doc, field, 'Yes'));
                    dispatch(setEstateManualInputField(doc, 'isItAPouroverWill', 'No'));
                    estateManualInput[doc]?.whichTestamentaryTrust && dispatch(setEstateManualInputField(doc, 'whichTestamentaryTrust', undefined));
                    estateManualInput[doc]?.whichPouroverTrust && dispatch(setEstateManualInputField(doc, 'whichPouroverTrust', undefined));
                },
                No: () => {
                    dispatch(setEstateManualInputField(doc, field, 'No'));
                    dispatch(setEstateManualInputField(doc, 'isItAPouroverWill', 'Yes'));
                    estateManualInput[doc]?.whichTestamentaryTrust && dispatch(setEstateManualInputField(doc, 'whichTestamentaryTrust', undefined));
                    estateManualInput[doc]?.whichPouroverTrust && dispatch(setEstateManualInputField(doc, 'whichPouroverTrust', undefined));
                },
            },
        };
        actions[field][val]();
    }; */

    return (
        <>
            <InputItem checkbox label="Incomplete documents" withoutPages docType={docId} field='isDocumentIncomplete' version={[1,2,3,4]}/>
            <SelectFamilyMember docType={docId} field='willOwner' label={`Who's will is it?`} version={[1,2,3,4]} showBorder withoutPages />
            <InputItem docType={docId} label='Date of the will' field='dateOfWill' version={[1,2,3,4]} withoutPages dateFormat />

            <InputItem
                docType={docId}
                label={`Governing Law`}
                field={`state`}
                nOptions={statesOptions}
                version={[4]}
            />
    <InputItem docType={docId} label='Is it a pourover will?' field='isItAPouroverWill' options={['Yes', 'No']} version={[1,2,3,4]} />
            <InputItem docType={docId} indent={4} label='Specify the trust' field='whichPouroverTrust' visible={estateManualInput?.[docId]?.isItAPouroverWill === 'Yes'} version={[2,3,4]} nOptions={trustNameRevocableOptions} withoutPages />
            <InputItem docType={docId} label='Are there testamentary trusts?' field='areThereTestamentaryTrusts' options={['Yes', 'No']} version={[1,2,3,4]} />
            <InputItem docType={docId} indent={4} label='Specify the trust' field='whichTestamentaryTrust' visible={!!(estateManualInput?.[docId]?.areThereTestamentaryTrusts === 'Yes')} version={[2,3,4]} nOptions={trustNameIrrevocableOptions} withoutPages />
            {/* <WillInputItem label='Name of the executor' field='executorName' /> */}
            <WillExectutors docId={docId} version={[1,2,3,4]} />
            {/* <WillInputItem label='Successive executors' field='successiveTrustees' /> */}
            <InputItem docType={docId} label='Does the will list assets?' field='willListAssets' options={['Yes', 'No']} initialValue={'No'} version={[1,2,3,4]} withoutPages />
            <WillAssetsContainer docId={docId} version={[1,2,3,4]} />
            {/* <WillInputItem label='Guardian for minor children' field='guardianMinorChildren' /> */}
            <InputItem docType={docId} label='When will it transfer to primary beneficiaries?' field={`whenWillTransferPrimaryBeneficiary`} version={[2,3,4]} />
            <SelectFamilyMember docType={docId} label='Guardian for minor children' field='guardianMinorChildren' version={[1]} showBorder />
            <TrustGuardians docId={docId} version={[2,3,4]} />

            <InputItem docType={docId} label='Does it create any type of trust?' field='createsTypeOfTrust' options={['Yes', 'No']} initialValue={'No'} version={[1,2]} />
            <InputItem docType={docId} indent={4} label='Specify the trust' field='whichTrust' visible={estateManualInput?.[docId]?.createsTypeOfTrust === 'Yes'} version={[2]} nOptions={trustNameOptions} withoutPages />

            

            <WillCreatesTypeOfTrust docId={docId} version={[1]} />

            <InputItem docType={docId} label='Power of appointment' field='powerOfAppointment' options={['Yes', 'No']} version={[1]} />
            <AgentsBlock docId={docId} parentField='powerOfAppointment' version={[1]} />

            <InputItem docType={docId} label='Medical directives / Healthcare proxy' field='medicalDirectives' options={['Yes', 'No']} version={[1]} />
            <AgentsBlock docId={docId} parentField='medicalDirectives' version={[1]} />

            {/* <InputItem docType={docId} label='Power of attorney' field='powerOfAttorney' options={['Yes', 'No']} version={[1,2]} withoutPages />
            <AgentsBlock docId={docId} parentField='powerOfAttorney' version={[1,2]} /> */}

            {/* <InputItem docType={docId} label={`Health Care Directive`} field={`healthCarePOAs`} options={['Yes', 'No']} version={[1,2]} withoutPages/>
            <AgentsBlock docId={docId} parentField='healthCarePOAs' version={[1,2]} /> */}

            <OtherInformationBlocks docId={docId} version={[2, 3, 4]} />
            <InputItem docType={docId} label='What other material information is contained in the document? (Please provide a one line summary per each paragraph)' field='otherInformation' multiline version={[1]} />
        </>
    )
}