import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Container, Input, Tab, Table } from 'semantic-ui-react';
import { ALL_AREAS } from '.';
import { responseError } from '../../helpers';
import { sysAPI } from '../../apis/SysAPI';

const AreaRecommendations = ({ items, area }) => {
  const _items = items;

  const dateFormatting = (date) => {
    return date && moment(date).isValid() ? moment(date).format('MM/DD/YYYY HH:mm:ss') : 'N/A';
  };

  return (
    <Tab.Pane>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Advisor</Table.HeaderCell>
            <Table.HeaderCell>Client</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>{area === 'prospect' ? 'Areas' : '# Recs'}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_items?.map((item) => {
            return (
              <Table.Row data-stamps={item.timestamps ? JSON.stringify(item.timestamps) : '{}'}>
                <Table.Cell>{item?.advisor?.fullName}</Table.Cell>
                <Table.Cell>{item.client ? `${item.client.firstName} ${item.client.lastName}` : 'N/A'}</Table.Cell>
                <Table.Cell>{dateFormatting(item.date)}</Table.Cell>
                <Table.Cell>{area === 'prospect' ? item.data.areas.join(', ') : item.data.recommendations}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Tab.Pane>
  );
};

const Recommendations = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [loading, setLoading] = useState(false)
  const [areasRecommendations, setAreasRecommendations] = useState([])

  const filterBtn_Click = async () => {
    setLoading(true)
    try {
        const {data} = await sysAPI.get(`/advisors/areas-recommendations?startDate=${startDate}&endDate=${endDate}`);
        setAreasRecommendations(data.advisorsList)
    } catch (error) {
        responseError(error, undefined, "Extractions.filterBtn_Click", dispatch);
    }
    setLoading(false)
  };

  useEffect(() => {
    filterBtn_Click()

    // eslint-disable-next-line
  }, []);


  const recommendations = areasRecommendations.map((item) => {
    return {
      areaId: item.area,
      items: item.data.map((i) => {
        return { ...i, advisor: i.advisorData, client: i.clientData };
      }),
    };
  });

  const panes = recommendations.map((extr) => ({
    menuItem: `${ALL_AREAS[extr.areaId]} (${extr.items.length})`,
    render: () => <AreaRecommendations items={extr.items} area={extr.areaId} />,
  }));

  return (
    <>
      <Container fluid style={{ margin: '0 0 20px 0' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div>
            <span style={{ marginRight: '10px' }}>From</span>
            <Input type="date" value={startDate} onChange={(e) => setStartDate(e.currentTarget.value)} />
            <span style={{ margin: '0 10px' }}>to</span>
            <Input type="date" value={endDate} onChange={(e) => setEndDate(e.currentTarget.value)} />
            <Button loading={loading} style={{ marginLeft: '10px' }} color="grey" onClick={filterBtn_Click}>
              Filter
            </Button>
          </div>
        </div>
      </Container>
      <Container fluid>
        <Tab panes={panes} />
      </Container>
    </>
  );
};

export default Recommendations;
