import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { sysAPI } from '../../../apis/SysAPI';
import { Container, Header, Table } from 'semantic-ui-react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useDispatch } from 'react-redux';
import { responseError } from '../../../helpers';

export const StackedGraphic = ({ labels, datasets, options, plugins, datalabels }) => {
  return (
    <>
      <Bar
        plugins={[ChartDataLabels]}
        data={{ labels: labels, datasets: datasets }}
        options={{
          ...options,
          categoryPercentage: 1,
          type: 'bar',
          layout: {
            padding: 20,
          },
          color: 'black',
          scales: {
            xAxis: {
              ticks: {
                color: 'black',
                font: {
                  size: 15,
                },
              },
              categoryPercentage: 1.0,
              barPercentage: 1.0,
            },
            yAxis: {
              ticks: {
                font: {
                  size: 15,
                },
                color: 'black',
                padding: 5,
              },
            },
          },
          plugins: {
            datalabels: {
              color: 'black',
              font: {
                family: 'sans-serif',
                size: 15,
              },
              ...datalabels,
            },
            ...plugins,
          },
        }}
      />
    </>
  );
};

const GenericTable = ({ rows, title, fields }) => {
  const getFields = () => {
    const auxfields = [];
    for (const row of fields) {
      for (const field of row) {
        if (!field.size) auxfields.push(field.value);
      }
    }
    return auxfields;
  };
  const getSize = () => {
    let size = 0;
    for (const field of fields[0]) {
      size += field.size || 1;
    }
    return size;
  };
  return (
    <>
      <Table>
        <Table.Header>
          {title && (
            <Table.Row>
              <Table.HeaderCell colSpan={getSize()} textAlign="center">
                {title}
              </Table.HeaderCell>
            </Table.Row>
          )}
          {fields.map((row) => {
            return (
              <Table.Row>
                {row.map((field) => {
                  return (
                    <Table.HeaderCell rowSpan={field.size ? 1 : fields.length} colSpan={field.size || 1}>
                      {field.name}
                    </Table.HeaderCell>
                  );
                })}
              </Table.Row>
            );
          })}
        </Table.Header>
        <Table.Body>
          {rows.map((row) => {
            return (
              <Table.Row>
                {getFields().map((field) => {
                  return <Table.Cell> {row[field]}</Table.Cell>;
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

const Tables = forwardRef(({ title, metrics, activeIndex, isPeriod, startDate, endDate, excludeCompanies, includeCompanies, areas, setLoading, loading }, ref) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const callAPI = async () => {
    setLoading(true);
    try {
      const {data} = await sysAPI.get(`/metrics`,{params: {
          metrics: metrics,
          startDate: startDate,
          endDate: endDate,
          isPeriod: isPeriod,
          companies: includeCompanies.join(','),
          excludeCompanies: excludeCompanies.join(','),
          areas: areas.join(','),
        }}
      )

      setData(data);
    } catch (error) {
      responseError(error, undefined, 'Tables.callAPI', dispatch);
    }
    setLoading(false);
  };

  useEffect(() => {
    callAPI();

    // eslint-disable-next-line
  }, [activeIndex]);

  useImperativeHandle(ref, () => ({
    callApiTable: callAPI,
  }));

  return (
    <>
      <Container>
        <Header textAlign="center" as="h2">
          {title}
        </Header>
      </Container>

      <Container style={{ marginTop: '50px' }}>
        {data.map((metric) => {
          return metric.type === 'graph' ? (
            <>
              <Header textAlign="center" as="h3">
                {metric.title}
              </Header>
              <StackedGraphic
                labels={metric.data.labels}
                datasets={metric.data.datasets}
                options={{ barPercentage: 0.3, indexAxis: 'y', elements: { bar: { borderWidth: 2, borderColor: 'white' } } }}
                plugins={{ legend: { display: true, position: 'bottom', labels: { font: { size: 15 } } } }}
              />
            </>
          ) : metric.type === 'table' ? (
            <>
              <Header textAlign="center" as="h3">
                {metric.title}
              </Header>
              <GenericTable rows={metric.data} fields={metric.headers} />
            </>
          ) : (
            <>
              <Header textAlign="center" as="h3">
                {metric.title}
              </Header>
              {metric.data.map((nestedTable) => {
                return (
                  <>
                    <GenericTable rows={nestedTable.data} title={nestedTable.area} fields={nestedTable.headers} />
                  </>
                );
              })}
            </>
          );
        })}
      </Container>
    </>
  );
});

export default Tables;
