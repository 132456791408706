import { useEffect, useState } from 'react';
import { sysAPI } from '../../apis/SysAPI';
import { Button, Container, Message, Pagination, Table } from 'semantic-ui-react';
import moment from 'moment';
import { responseError } from '../../helpers';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export const LastLogin = () => {
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);
  // eslint-disable-next-line
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    pageChange(null, {activePage: 1})

    // eslint-disable-next-line
  }, []);

  const pageChange = async (e, { activePage }) => {
    setLoading(true)
    try {
        const { data } = await sysAPI.get(`/advisors/metrics`,{
          params: {
            page: activePage,
            size: pageSize,
            sort: "lastLogin",
            sorttype: "DESC"
          }
        })
        const { advisorsList, totalPages } = data;
        setRows(advisorsList);
        setTotalPages(totalPages);
    } catch (error) {
        responseError(error, undefined, "LasLogin.pagehange", dispatch)
    }
    setActivePage(activePage);
    setLoading(false)
  };

  const dateFormatting = (date) => {
    return date && moment(date).isValid() ? moment(date).format('MM/DD/YYYY HH:mm:ss') : 'N/A';
  };

  return (
    <>
      <Container fluid>
        <div style={{display:"flex", justifyContent:"end"}}>
          <Button loading={loading} color="grey" onClick={() => pageChange(null, {activePage})}>Refresh</Button>
        </div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Full Name</Table.HeaderCell>
              <Table.HeaderCell>Company</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Last Login Date</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {rows.map((row) => {
              return (
                <Table.Row>
                  <Table.Cell>
                    <Link
                      to={`/dashboard/advisors/${row._id}`}
                      style={{ color: 'black' }}
                    >
                      {row.fullName}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    <Link
                      to={`/dashboard/companies/${row.companyInfo._id}`}
                      style={{ color: 'black' }}
                    >
                      {row.companyInfo.name}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{row.email}</Table.Cell>
                  <Table.Cell>{dateFormatting(row.activity?.lastLogin || row.lastLogin)}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        {loading && <>
            <Message color="blue">
                Loading...
            </Message>
        </>}
        <Pagination disabled={loading} totalPages={totalPages} activePage={activePage} onPageChange={pageChange} />
      </Container>
    </>
  );
};

export default LastLogin;
