import { useEffect, useState } from "react";
import { Button } from "semantic-ui-react";

const ToggleDarkMode = () => {
  const [darkMode, setDarkMode] = useState(false);
  const keyInLocalStorage = "dark-mode";

  const changeDarkMode = () => {
    let link = document.getElementById("dark-mode-style");
    if (darkMode) {
      if (!link) {
        link = document.createElement("link");
        link.id = "dark-mode-style";
        link.rel = "stylesheet";
        link.href = "/css/dark-mode.css";
        document.head.appendChild(link);
        console.log("Experimental Dark Mode, please report any issues");
        console.log("Dark mode enable");
      }
    } else {
      if (link) {
        link.remove();
        console.log("Dark mode disable");
      }
    }
  }

  const getDarkMode = () => {
    let savedDarkMode = localStorage.getItem(keyInLocalStorage);
    if (savedDarkMode === "true") {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  }

  const changeStatus = () => {
    setDarkMode(!darkMode);
    localStorage.setItem(keyInLocalStorage, !darkMode);

  }

  useEffect(() => {
    changeDarkMode();
    // eslint-disable-next-line
  }, [darkMode]);

  useEffect(() => {
    getDarkMode()
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{display:"none"}}>
      <Button circular icon={darkMode ? 'moon': 'sun' }onClick={() => changeStatus()} />
    </div>
  );
};

export default ToggleDarkMode;
