/**
 * This object is made programmatically from the file:
 * "/src/components/shared/EstateGeneralQuestionnaire.js".
 * Any modifications to that file will be reflected here.
 * Or you can use the old way if you prefer.
 */

import GeneralQuestionnaireStructure from "../../components/shared/EstateGeneralQuestionnaire";

const EstateQuestionnaire = [];
let previousTitle = '';

GeneralQuestionnaireStructure.forEach((section) => {
  const questionnaire = {
      section: '',
      fields: [],
  };

  if (section.title) {
    questionnaire.section = section.title;
    previousTitle = section.title;
  }

  if (section.subtitle) {
    questionnaire.section = `${previousTitle.length > 0 ? `${previousTitle} - ${section.subtitle}` : section.subtitle}`;
  }

  questionnaire.fields = section.questions.map((field) => ({
          name: field.field.replace('estateQuestion___', ''),
          text: field.label,
          type: 'string',
          ...(field?.options && { options: field.options }),
  }));

  EstateQuestionnaire.push(questionnaire);
});

export default EstateQuestionnaire;

// eslint-disable-next-line
/* export default [
  {
    "section": "",
    "fields": [
      {
        "name": "estateQuestion___analysisType",
        "text": "Analysis Type",
        "type": "string"
      }
    ]
  },
  {
    "section": "Will information",
    "fields": [
      {
        "name": "estateQuestion___will_pourOver",
        "text": "Is it a pour-over will?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___will_dated5YearsAgo",
        "text": "Is the will dated more than 5 years ago?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___will_diffGoverningLaw",
        "text": "Is the state listed as the \"governing law provision\" in the will document different than the state of residence in the General Questionnaire?",
        "type": "string",
        "options": ["Yes", "No"]
      }
    ]
  },
  {
    "section": "Executors",
    "fields": [
      {
        "name": "estateQuestion___will_executors",
        "text": "Who are the executors?",
        "type": "string"
      },
      {
        "name": "estateQuestion___will_successorExecutors",
        "text": "Who are the successor executors?",
        "type": "string"
      },
      {
        "name": "estateQuestion___will_coExecutors",
        "text": "Are there co-executors/multiple executors?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___will_contingentBeneficiaries",
        "text": "Are there contingent beneficiaries in the will?",
        "type": "string",
        "options": ["Yes", "No"]
      }
    ]
  },
  {
    "section": "Minor Children",
    "fields": [
      {
        "name": "estateQuestion___will_guardianForMinors",
        "text": "Did the client name a guardian for their minor children?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___will_minorClause",
        "text": "Is there a minor\"s clause in the will?",
        "type": "string",
        "options": ["Yes", "No"]
      }
    ]
  },
  {
    "section": "Fiduciary Powers",
    "fields": [
      {
        "name": "estateQuestion___will_businessOwner",
        "text": "Is the will owner a business owner?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___will_digitalAssets",
        "text": "Is there a digital assets provision allowing the executor to manage online accounts and data?",
        "type": "string",
        "options": ["Yes", "No"]
      }
    ]
  },
  {
    "section": "Distributions",
    "fields": [
      {
        "name": "estateQuestion___will_specificDistributions",
        "text": "Are there any specific distributions (specific bequests)?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___will_cashBequest",
        "text": "Is there a cash bequest?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___will_realEstate",
        "text": "Is the specific bequest a house or real estate property?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___will_personalProperty",
        "text": "Is the specific bequest personal property? (cars, jewelry, art, collectibles, furniture, dishes, ,etc)",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___will_business",
        "text": "Is the specific bequest a business?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___will_residuaryBeneficiaries",
        "text": "Who are the beneficiaries of the residuary clause?",
        "type": "string"
      },
      {
        "name": "estateQuestion___will_residuaryClause",
        "text": "What is the exact wording of residuary clause?",
        "type": "string"
      },
      {
        "name": "estateQuestion___will_contingentBeneficiaries",
        "text": "Who are the contingent beneficiaries?",
        "type": "string"
      },
      {
        "name": "estateQuestion___will_catastropheClause",
        "text": "Is there a catastrophe clause?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___will_charitableDistributions",
        "text": "Are there any charitable distributions?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___will_assetDirections",
        "text": "Is there direction on which assets should be used for the charitable distribution?",
        "type": "string",
        "options": ["Yes", "No"]
      }
    ]
  },
  {
    "section": "Testamentary Trusts",
    "fields": [
      {
        "name": "estateQuestion___will_testamentaryTrust",
        "text": "Does the will create a testamentary trust at the death of the will owner?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___will_hemStandardTrust",
        "text": "Does the will create a trust that provides for distributions under a HEMS standard?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___will_disclaimerTrust",
        "text": "Is the testamentary trust created at death a disclaimer or bypass trust?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___will_minorTrust",
        "text": "Is there a trust for minor children in the will?",
        "type": "string",
        "options": ["Yes", "No"]
      }
    ]
  },
  {
    "section": "POA Information",
    "fields": [
      {
        "name": "estateQuestion___poa_coAgents",
        "text": "Does the POA list 2 people as agents to serve at the same time (aka co-agents)?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___poa_coAgentsIndep",
        "text": "Can the co-agents act independently?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___poa_springing",
        "text": "Is the POA springing?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___poa_statutory",
        "text": "Is it a statutory power POA? (i.e. does it say the Agent gets all the powers in the state statute?)",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___poa_digitalAssets",
        "text": "Is there a power related to digital asset management?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___poa_giftingPowers",
        "text": "Does it include powers for gifting?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___poa_taxPowers",
        "text": "Does it include powers for tax related decisions?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___poa_trustManagement",
        "text": "Are there powers related to trust management?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___poa_powerChanges",
        "text": "Are there powers allowing changes to beneficiary designations?",
        "type": "string",
        "options": ["Yes", "No"]
      }
    ]
  },
  {
    "section": "Healthcare Information",
    "fields": [
      {
        "name": "estateQuestion___hc_coAgents",
        "text": "Does the Healthcare POA list 2 people as agents to serve at the same time (aka co-agents)?",
        "type": "string",
        "options": ["Yes", "No"]
      }
    ]
  },
  {
    "section": "Revocable Trust Information",
    "fields": [
      {
        "name": "estateQuestion___rev_jointTrust",
        "text": "Is it a joint trust?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___rev_amended",
        "text": "Has the trust been amended?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___rev_amendmentOrRestatement",
        "text": "Is EACH amendement a restatement or just an amendment? (Restatement, amendment)",
        "type": "string"
      },
      {
        "name": "estateQuestion___rev_trustDate",
        "text": "What is the original trust date?",
        "type": "string"
      },
      {
        "name": "estateQuestion___rev_amendmentDate",
        "text": "What is the trust amendment date(s)?",
        "type": "string"
      },
      {
        "name": "estateQuestion___rev_trust5YearsOld",
        "text": "Is the trust or most recent amendment more than 5 years old?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___rev_diffGoverningLaw",
        "text": "Is the state listed as the \"governing law provision\" in trust document different than the state of residence in the General Questionnaire?",
        "type": "string",
        "options": ["Yes", "No"]
      }
    ]
  },
  {
    "section": "Trustees",
    "fields": [
      {
        "name": "estateQuestion___rev_mainTrustees",
        "text": "Who are the trustees of the main trust?",
        "type": "string"
      },
      {
        "name": "estateQuestion___rev_successorTrustees",
        "text": "Who are the successor trustees of the main trust?",
        "type": "string"
      },
      {
        "name": "estateQuestion___rev_coTrustees",
        "text": "Are there co-trustees?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___rev_indepTrustees",
        "text": "Do they have the power to act independently?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___rev_soleTrustee",
        "text": "Can they continue as sole trustee?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___rev_noTrusteeClause",
        "text": "Is there a \"no available trustee\" clause?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___rev_matchingTrustees",
        "text": "Are the resulting trust trustees the same as the main trust trustees?",
        "type": "string",
        "options": ["Yes", "No"]
      }
    ]
  },
  {
    "section": "Distributions",
    "fields": [
      {
        "name": "estateQuestion___rev_specificDistributions",
        "text": "Are there any specific disitrbutions (specific bequests)?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___rev_cashBequest",
        "text": "Is there a cash bequest?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___rev_realEstate",
        "text": "Is the specific bequest a house or real estate property?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___rev_personalProperty",
        "text": "Is the specific bequest personal property? (cars, jewelry, art, collectibles, furniture, dishes, ,etc)",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___rev_businessInTrust",
        "text": "Is the specific bequest a business?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___rev_contingentBeneficiaries",
        "text": "Who are the contingent beneficiaries?",
        "type": "string"
      },
      {
        "name": "estateQuestion___rev_catastropheClause",
        "text": "Is there a catastrophe clause?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___rev_charitableDistributions",
        "text": "Are there any charitable distributions?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___rev_assetDirections",
        "text": "Is there direction on which assets should be used for the charitable distribution?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___rev_residuaryEstate",
        "text": "Is the residuary estate passed outright or in trust?",
        "type": "string"
      }
    ]
  },
  {
    "section": "Resulting Trusts",
    "fields": [
      {
        "name": "estateQuestion___rev_descendantShare",
        "text": "Upon the death of the first grantor, how is descendant's share passed?",
        "type": "string"
      },
      {
        "name": "estateQuestion___rev_spouseShare",
        "text": "Upon the death of the first grantor, how is surviving spouse's share passed?",
        "type": "string"
      },
      {
        "name": "estateQuestion___rev_afterSpouseDeath",
        "text": "What happens to the resulting trust assests afer the surviving spouse's death?",
        "type": "string"
      },
      {
        "name": "estateQuestion___rev_spouseAssetsPostDeath",
        "text": "What happens to the surviving spouse's assets upon their death?",
        "type": "string"
      }
    ]
  },
  {
    "section": "Provisions/Clauses",
    "fields": [
      {
        "name": "estateQuestion___rev_governingLaw",
        "text": "Is there a governing law provision in the trust? (i.e does it say what state law applies to the trust?)",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___rev_state",
        "text": "What state?",
        "type": "string"
      },
      {
        "name": "estateQuestion___rev_minorClause",
        "text": "Is there a minor’s clause in the revocable trust?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___rev_powerOfAppointment",
        "text": "Does the beneficiary receive a power of appointment over the trust assets? (could be different per each resulting trust)",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___rev_hemStandardTrust",
        "text": "Does the revocable trust create a trust that provides for distributions under a HEMS standard?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___rev_spouseRemarriageClause",
        "text": "Does the trust contain a clause about what happens if the surviving spouse re-marries?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___rev_digitalAssets",
        "text": "Is there a digital assets provision allowing the trustee to manage online accounts and data?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___rev_taxEfficientDistributions",
        "text": "Is there a trustee power to direct tax efficient distributions?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___rev_taxApportion",
        "text": "Does the main trust direct the trustee how to apportion tax payments after the grantor's death?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___rev_taxPayments",
        "text": "Do any of the resulting trusts direct the trustee how to apportion tax payments after the grantor's death?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___rev_capitalGains",
        "text": "Is the trustee permitted to distribute capital gains to the beneficiary as part of the income distribution? (could be different per each resulting trust)",
        "type": "string",
        "options": ["Yes", "No"]
      }
    ]
  },
  {
    "section": "Irrevocable Trust Information",
    "fields": [
      {
        "name": "estateQuestion___irrev_amended",
        "text": "Has the trust been amended/modified?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___irrev_diffGoverningLaw",
        "text": "Is the state listed as the \"governing law provision\" in trust document different than the state of residence in the General Questionnaire?",
        "type": "string",
        "options": ["Yes", "No"]
      }
    ]
  },
  {
    "section": "Trustees",
    "fields": [
      {
        "name": "estateQuestion___irrev_mainTrustees",
        "text": "Who are the trustees of the main trust?",
        "type": "string"
      },
      {
        "name": "estateQuestion___irrev_successorTrustees",
        "text": "Who are the successor trustees of the main trust?",
        "type": "string"
      },
      {
        "name": "estateQuestion___irrev_coTrustees",
        "text": "Are there co-trustees?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___irrev_indepTrustees",
        "text": "Do they have the power to act independently?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___irrev_soleTrustee",
        "text": "Can they continue as sole trustee?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___irrev_noAvailableClause",
        "text": "Is there a \"no available trustee\" clause?",
        "type": "string",
        "options": ["Yes", "No"]
      }
    ]
  },
  {
    "section": "Distributions",
    "fields": [
      {
        "name": "estateQuestion___irrev_assetsPostGrantor",
        "text": "Upon the grantor's death, how are assets passed?",
        "type": "string"
      },
      {
        "name": "estateQuestion___irrev_grantorBeneficiaries",
        "text": "Upon the grantor's death, who are the beneficiaries?",
        "type": "string"
      },
      {
        "name": "estateQuestion___irrev_contingentBeneficiaries",
        "text": "Who are the contingent beneficiaries?",
        "type": "string"
      },
      {
        "name": "estateQuestion___irrev_catastropheClause",
        "text": "Is there a catastrophe clause?",
        "type": "string",
        "options": ["Yes", "No"]
      }
    ]
  },
  {
    "section": "Provisions/Clauses",
    "fields": [
      {
        "name": "estateQuestion___irrev_governingLaw",
        "text": "Is there a governing law provision in the trust? (i.e does it say what state law applies to the trust?)",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___irrev_state",
        "text": "What state?",
        "type": "string"
      },
      {
        "name": "estateQuestion___irrev_powerOfAppointment",
        "text": "Does the beneficiary receive a power of appointment over the trust assets? (could be different per each resulting trust)",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___irrev_hasModificationClause",
        "text": "Does it have a modification provision?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___irrev_modificationClause",
        "text": "If yes, this is what you have in your modification provision",
        "type": "string"
      }
    ]
  },
  {
    "section": "Specific Types of Irrevocable Trusts",
    "fields": [
      {
        "name": "estateQuestion___irrev_qprt",
        "text": "Is it a Qualified Personal Residence Trust (QPRT)?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___irrev_grat",
        "text": "Is it a Grantor Retained Annuity Trust (GRAT)?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___irrev_grut",
        "text": "Is it a Grantor Retained Unitrust (GRUT)?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___irrev_crt",
        "text": "Is it a Charitale Remiander Trust (CRT)?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___irrev_clt",
        "text": "Is it a Charitable Lead Trust (CLT)?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___irrev_idgt",
        "text": "Is it an Intentionally Defective Grantor Trust (IDGT)?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___irrev_dynastyTrust",
        "text": "Is it a Dynasty Trust?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___irrev_slat",
        "text": "Is it a Spousal Lifetime Access Trust (SLAT)?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___irrev_ilit",
        "text": "Is it an Irrevocable Life Insurance Trust (ILIT)?",
        "type": "string",
        "options": ["Yes", "No"]
      },
      {
        "name": "estateQuestion___irrev_grantorCount",
        "text": "Does it have two Grantors or just one?",
        "type": "string"
      }
    ]
  }
]
; */
