import { TabContainer } from '../../components/shared/TabContainer';
import { DashboardTemplate } from '../../templates/DashboardTemplate';
import Extractions from './Extractions';
import Recommendations from './Recommendations';
import LastLogin from './LastLogin';
import UsersOnline from './UsersOnline';
import { Graphics } from './Graphics';
import { Tab } from 'semantic-ui-react';

export const ALL_AREAS = {
  all: 'All Areas',
  estate: 'Estate Planning',
  auto: 'Auto',
  crossborder: 'Cross Border',
  disability: 'Disability',
  home: 'Home',
  medical: 'Medical',
  identity: 'Identity',
  student: 'Student Loan',
  expense: 'Expense',
  fulltax: 'Tax Planning',
  ltc: 'LTC',
  mortgage: 'Mortgage',
  life: 'Life Planning',
  divorce: 'Divorce Planning',
  property: 'Property',
  creditor: 'Creditor',
  elder: 'Elder care',
};

/* const Metrics = () => {
    const [companiesList, setCompaniesList] = useState([]);

    // eslint-disable-next-line
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        sysAPI.get(`/companies`).then(response => {
            const { companiesList } = response.data;
            setCompaniesList(companiesList.map(company => ({ key: company, text: company, value: company })));
        }).catch(err => {
            console.error(err);
        });
    }, []);

    const handleChange =  (e, data) => {
        setActiveIndex(data.activeIndex);
    }

    const panes = [
        {
            menuItem: 'Usage',
            render: () => <Tab.Pane>
                <Tables
                    title={'Week\'s User Activity'}
                    dropdownOptions={companiesList}
                    areaOptions={Object.keys(ALL_AREAS).map( area => {return { key: area, text: ALL_AREAS[area], value: area }})}
                />
            </Tab.Pane>,
        }
    ]

    return (<>
        <Container fluid>
            <Tab menu={{ pointing: true, secondary: true, }} panes={panes} onTabChange={handleChange} />
        </Container>
    </>)
} */

export const DashboardMetricsView = () => {
  const panes = [
    { menuItem: 'Extractions by area', render: () => <Extractions /> },
    { menuItem: 'Recommendations by area', render: () => <Recommendations /> },
    { menuItem: 'Last login', render: () => <LastLogin /> },
    { menuItem: 'Users online', render: () => <UsersOnline /> },
    { menuItem: 'Graphics', render: () => <Graphics /> },
  ];

  return (
    <DashboardTemplate scope="metrics" permission="metrics:metrics_view">
      <TabContainer title="Platform Metrics" subtitle="Registry of advisors activity" icon="chart pie">
        <Tab panes={panes} menu={{ fluid: true, vertical: true }} grid={{ paneWidth: 13, tabWidth: 3 }} style={{ marginTop: '20px' }} />
      </TabContainer>
    </DashboardTemplate>
  );
};
