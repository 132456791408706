import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Header, Input, Label, Button, Icon, Popup } from 'semantic-ui-react';
import { fpalphaAPI } from "../../apis/FPAlphaAPI";
import { sysAPI } from "../../apis/SysAPI";
import { typeClient } from "../../helpers/advisors";
import { copyToClipboard, responseError } from "../../helpers";
import { setSnackbar } from "../../actions/snackbar.actions";
//import { ManualInputModal } from "../modal/ManualInputModal";

export const AdvisorClientsTab = ({ activeTab }) => {
    const me = useSelector((state) => state.me);
    const clients = useSelector(state => state.currentAdvisorClients);
    const advisorProfile = useSelector(state => state.currentAdvisorProfile);
    const [showTabActions, setShowTabActions] = useState(false);
    const dispatch = useDispatch();

    const [searchInput, setSearchInput] = useState('');

    let renderClients = searchInput.length === 0
        ? clients
        : clients.filter(client =>
            client.clientIDFirstName.includes(searchInput)
            || client.clientIDLastName.includes(searchInput)
            || client.email.includes(searchInput)
            || client._id.includes(searchInput)
            || client.fromIntegration?.origin?.includes(searchInput)
        );

    useEffect(() => {
        const hasPermissionsToShowActions = me?.permissions?.filter(p => ["clients:copy_email", "clients:copy_id"].includes(p)) || []
        if(hasPermissionsToShowActions.length > 0){
            setShowTabActions(true)
        }
    },[me])


    const ClientRow = ({ client, key }) => {
        const [isCopied, setIsCopied] = useState(false);
        const [loaderDowload, setLoaderDowload] = useState(false);

        const downloadBtn_Click = async () => {
            setLoaderDowload(true)
            try {
                const {data: dataCode} = await sysAPI.post('/generate-code');
                const {data} = await fpalphaAPI.post('/tools/exportClient2JSON', {
                    clientsIds: [client._id],
                    download: true,
                    code: dataCode.code,
                }, {
                    responseType: 'blob',
                })
                const href = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = href;
                link.target = '_blank';
                link.setAttribute('download', `${client.email}.fpclient`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                dispatch(setSnackbar({message: `Client downloaded ${client.clientIDFirstName} ${client.clientIDLastName} (${client.email})`}))
            } catch (error) {
                responseError(error, undefined, "AdvisorClientsTab.downloadBtn_Click", dispatch)
            }
            setLoaderDowload(false)
        };

        return <Table.Row key={key}>
            <Table.Cell>
                {client.clientIDFirstName} {client.clientIDLastName}
            </Table.Cell>
            <Table.Cell>{client.email}</Table.Cell>
            <Table.Cell>
                {typeClient(client)}
                {/* ({client.salesforceContactId || client.redtailContactId || client.fromIntegration?.origin || 'Client'}) */}
            </Table.Cell>
            <Table.Cell>{moment(client.creationDate).format('MM/DD/YYYY')}</Table.Cell>
            <Table.Cell>
                {!client.isShared
                    ? <Label color='grey'>Not Shared</Label>
                    : client.owner === advisorProfile?._id
                        ? <Label color='blue'>Shared (Owner)</Label>
                        : <Label color='green'>Shared</Label>
                }
            </Table.Cell>
            <Table.Cell>
                <Button loading={loaderDowload} onClick={() => downloadBtn_Click(client._id, client.email)}>Download</Button>
            </Table.Cell>
            {showTabActions && <>
                <Table.Cell>
                    {me?.permissions?.includes("clients:copy_email") && <>
                        <Popup
                            on={['hover']}
                            trigger={
                                <Icon name='at' style={{cursor:"pointer"}} size="large" onClick={() => {
                                    copyToClipboard(`${client.email}`, false)
                                    setIsCopied(true)
                                    setTimeout(() => {
                                        setIsCopied(false)
                                    }, 1000)
                                }} />
                            }
                            content={isCopied ? 'Copied': 'Click to copy Email'}
                            inverted
                        />
                    </>}
                    {me?.permissions?.includes("clients:copy_id") && <>
                        <Popup
                            on='hover'
                            trigger={
                                <Icon name='barcode' style={{cursor:"pointer"}} size="large" onClick={() => {
                                    copyToClipboard(`${client._id}`, false)
                                    setIsCopied(true)
                                    setTimeout(() => {
                                        setIsCopied(false)
                                    }, 1000)
                                }} />
                            }
                            content={isCopied ? 'Copied': 'Click to copy ID'}
                            inverted
                        />
                    </>}
                    </Table.Cell>
                </>}
        </Table.Row>
    }

    return (
        <div style={{ display: activeTab === 'Clients' ? 'block' : 'none' }}>
            <Header as='h3'>
                <Header.Content>All Clients ({clients.length})</Header.Content>
            </Header>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Input
                    style={{ width: '300px' }}
                    icon='search' placeholder='Search...'
                    value={searchInput} onChange={e => setSearchInput(e.currentTarget.value)}
                />
            </div>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Full Name</Table.HeaderCell>
                        <Table.HeaderCell>E-mail</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Created on</Table.HeaderCell>
                        <Table.HeaderCell>Is Shared</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        {showTabActions && <>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </>}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {renderClients.map((client, index) =>  <ClientRow client={client} key={index} /> )}
                </Table.Body>
            </Table>
        </div>
    )
}