import { useEffect, useState, useRef } from 'react';
import { sysAPI } from '../../../apis/SysAPI';
import { Button, Container, Dropdown, Input, Tab } from 'semantic-ui-react';
import Graphic from './Graphic';
import Tables from './Tables';
import { ALL_AREAS } from '../index';
import { responseError } from '../../../helpers';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import LoaderCustom from '../../../components/tabs/EstateManualInputRequestsTab/LoaderCustom';

export const Graphics = () => {
  const refGraphic = useRef();
  const refTable = useRef();
  const dispatch = useDispatch();
  const [companiesList, setCompaniesList] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [startDate, setStartDate] = useState(moment().subtract(1, 'month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [areas, setAreas] = useState([]);
  const [excludeCompanies, setExcludeCompanies] = useState([]);
  const [includeCompanies, setIncludeCompanies] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCompanies = async () => {
    try {
      const { data } = await sysAPI.get(`/companies`);
      setCompaniesList(data.companiesList.map((company) => ({ key: company, text: company, value: company })));
    } catch (error) {
      responseError(error, undefined, 'Graphics.getCompanies', dispatch);
    }
  };

  useEffect(() => {
    getCompanies();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e, data) => {
    setActiveIndex(data.activeIndex);
  };

  const ejecutarFunction = () => {
    if (refGraphic.current) {
      refGraphic.current.callApiGraphic();
    }
    if (refTable.current) {
      refTable.current.callApiTable();
    }
  };

  const areasOption = Object.keys(ALL_AREAS).map((area) => {
    return { key: area, text: ALL_AREAS[area], value: area };
  });

  const panes = [
    {
      menuItem: 'Usage',
      render: () => (
        <Tab.Pane>
          <Graphic
            activeIndex={activeIndex}
            endpoint={'weekly-processes'}
            hasTable={true}
            title={'Activity by period'}
            options={{ barPercentage: 0.3, elements: { bar: { borderWidth: 2, borderColor: 'white' } } }}
            plugins={{ legend: { display: true, position: 'bottom', labels: { font: { size: 15 } } } }}
            isPeriod={true}
            startDate={startDate}
            endDate={endDate}
            excludeCompanies={excludeCompanies}
            includeCompanies={includeCompanies}
            areas={areas}
            setLoading={setLoading}
            loading={loading}
            ref={refGraphic}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Tables',
      render: () => (
        <Tab.Pane>
          <Tables
            activeIndex={activeIndex}
            title={"Tables"}
            metrics={'validVsInvalidProcesses,typeOfAnalysis,avgProcessingTime,returnFlags,reasonOfFailure'}

            startDate={startDate}
            endDate={endDate}
            excludeCompanies={excludeCompanies}
            includeCompanies={includeCompanies}
            areas={areas}
            setLoading={setLoading}
            loading={loading}
            ref={refTable}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Prospects',
      render: () => (
        <Tab.Pane>
          <Tables
            activeIndex={activeIndex}
            title={"User Activity"}
            metrics={'historicProspectsConversion,prospectsActivity'}

            startDate={startDate}
            endDate={endDate}
            excludeCompanies={excludeCompanies}
            includeCompanies={includeCompanies}
            areas={areas}
            setLoading={setLoading}
            loading={loading}
            ref={refTable}
          />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <Container fluid>
        <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
          <div>
            <Input label="From" type="date" value={startDate} onChange={(e) => setStartDate(e.currentTarget.value)} />
          </div>
          <div>
            <Input label="To" type="date" value={endDate} onChange={(e) => setEndDate(e.currentTarget.value)} />
          </div>
          <div>
            <Dropdown
              placeholder="Inlcude Company"
              multiple
              selection
              options={companiesList}
              value={includeCompanies || []}
              onChange={(e, { value }) => setIncludeCompanies(value)}
            />
          </div>
          <div>
            <Dropdown
              placeholder="Exclude Company"
              multiple
              selection
              options={companiesList}
              value={excludeCompanies || []}
              onChange={(e, { value }) => setExcludeCompanies(value)}
            />
          </div>
          <div>
            <Dropdown placeholder="Areas" multiple selection options={areasOption} value={areas || []} onChange={(e, { value }) => setAreas(value)} />
          </div>
          <div>
            <Button loading={loading} style={{ marginLeft: '10px' }} color="grey" onClick={() => ejecutarFunction()}>
              Filter
            </Button>
          </div>
        </div>
        <Tab menu={{ pointing: true, secondary: true }} panes={panes} onTabChange={handleChange} />
        {loading && <LoaderCustom />}
      </Container>
    </>
  );
};
