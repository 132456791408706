// eslint-disable-next-line
export default [
    {
        section: 'General Information',
        fields: [
            {
                name: 'clientIDFillingStatus',
                text: 'Filing Status',
                type: 'string',
                options: [
                    "Single",
                    "Married Filing Jointly",
                    "Married Filing Separately",
                    "Head of Household",
                    "Surviving Spouse"
                ],
            },
        ]
    },
    {
        section: 'Form 1040',
        fields: [
            {
                name: 'wages',
                text: 'Wages',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'taxExemptInterest',
                text: 'Tax-exempt interest',
                line: 'Line 2a',
                type: 'number',
            },
            {
                name: 'taxInterest',
                text: 'Taxable interest',
                line: 'Line 2b',
                type: 'number',
            },
            {
                name: 'qualifiedDividends',
                text: 'Qualified dividends',
                line: 'Line 3a',
                type: 'number',
            },
            {
                name: 'totalDividends',
                text: 'Ordinary dividends',
                line: 'Line 3b',
                type: 'number',
            },
            {
                name: 'iraDistribution',
                text: 'IRA distributions',
                line: 'Line 4a',
                type: 'number',
            },
            {
                name: 'taxableIRA',
                text: 'IRA distributions taxable amount',
                line: 'Line 4b',
                type: 'number',
            },
            {
                name: 'pension',
                text: 'Pensions and annuities',
                line: 'Line 5a',
                type: 'number',
            },
            {
                name: 'taxablePension',
                text: 'Pensions and annuities taxable amount',
                line: 'Line 5b',
                type: 'number',
            },
            {
                name: 'socialSecurityBenefits',
                text: 'Social security benefits',
                line: 'Line 6a',
                type: 'number',
            },
            {
                name: 'socialSecurity',
                text: 'Social security benefits taxable amount',
                line: 'Line 6b',
                type: 'number',
            },
            {
                name: 'capitalGainOrLoss',
                text: 'Capital gain or (loss)',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'otherIncome',
                text: 'Other income',
                line: 'Line 8',
                type: 'number',
            },
            {
                name: 'totalIncome',
                text: 'Total income',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'adjustedIncome',
                text: 'Adjustments to income',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'adjustedGrossIncome',
                text: 'Adjusted gross income',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'standardDeductions',
                text: 'Standard deductions or itemized deductions',
                line: 'Line 12a',
                type: 'number',
            },
            {
                name: 'charitableContributions1040',
                text: 'Charitable contributions',
                line: 'Line 12b',
                type: 'number',
            },
            {
                name: 'sumStandardAndCharitable',
                text: 'Standard deductions and charitable contributions',
                line: 'Line 12c',
                type: 'number',
            },
            {
                name: 'qbIncome',
                text: 'Qualified business income deductions',
                line: 'Line 13',
                type: 'number',
            },
            {
                name: 'sumDeductions',
                text: 'Sum of deductions',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'taxableIncome',
                text: 'Taxable income',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'taxes',
                text: 'Taxes',
                line: 'Line 16',
                type: 'number',
            },
            {
                name: 'line17_1040',
                text: 'Amount from schedule 2 line 3',
                line: 'Line 17',
                type: 'number',
            },
            {
                name: 'line18_1040',
                text: 'Add lines 16 and 17',
                line: 'Line 18',
                type: 'number',
            },
            {
                name: 'qualifyingDependentCreditClaimed',
                text: 'Nonrefundable child tax credit',
                line: 'Line 19',
                type: 'number',
            },
            {
                name: 'line20_1040',
                text: 'Amount from schedule 3 line 8',
                line: 'Line 20',
                type: 'number',
            },
            {
                name: 'line21_1040',
                text: 'Add lines 19 and 20',
                line: 'Line 21',
                type: 'number',
            },
            {
                name: 'line22_1040',
                text: 'Substract line 21 from line 18',
                line: 'Line 22',
                type: 'number',
            },
            {
                name: 'otherTaxesWithSelfEmployment',
                text: 'Other taxes',
                line: 'Line 23',
                type: 'number',
            },
            {
                name: 'totalTax',
                text: 'Total tax',
                line: 'Line 24',
                type: 'number',
            },
            {
                name: 'federalIncomeTaxWithheld',
                text: 'Federal income tax withheld from Form(s) W-2, 1099 and others',
                line: 'Line 25d',
                type: 'number',
            },
            {
                name: 'estimatedTaxPaymentsAmountForLastYear',
                text: '2021 estimated tax payments and amount applied from 2020 return',
                line: 'Line 26',
                type: 'number',
            },
            {
                name: 'earnedIncomeCredit',
                text: 'Earned income credit (EIC)',
                line: 'Line 27',
                type: 'number',
            },
            {
                name: 'additionalChildTaxCredit',
                text: 'Additional child tax credit',
                line: 'Line 28',
                type: 'number',
            },
            {
                name: 'americanOpportunityCreditTaken',
                text: 'American opportunity credit from Form 8863, line 8',
                line: 'Line 29',
                type: 'number',
            },
            {
                name: 'recoveryRebateCredit',
                text: 'Recovery rebate credit',
                line: 'Line 30',
                type: 'number',
            },
            {
                name: 'amountfromSchedule3line15',
                text: 'Amount from Schedule 3, line 15',
                line: 'Line 31',
                type: 'number',
            },
            {
                name: 'totalOtherPayments',
                text: 'Add lines 27 through 31. These are your total other payments and refundable credits',
                line: 'Line 32',
                type: 'number',
            },
            {
                name: 'totalPayments',
                text: 'Add lines 25d, 26, and 32. These are your total payments',
                line: 'Line 33',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule 1',
        fields: [
            {
                name: 'clientHasSchedule1',
                text: 'Has Schedule 1?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name:'taxableRefunds',
                text: 'Taxable refunds',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'alimonyReceived',
                text: 'Alimony received',
                line: 'Line 2a',
                type: 'number',
            },
            {
                name: 'businessIncome',
                text: 'Business income/loss',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'otherGainsOrLosses',
                text: 'Other gains or losses',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'rentalRealEstate',
                text: 'Rental real estate',
                line: 'Line 5',
                type: 'number',
            },
            {
                name: 'farmIncome',
                text: 'Farm income',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'unemployementCompensation',
                text: 'Unemployment compensation',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'otherIncome8a',
                text: 'Net operating loss',
                line: 'Line 8a',
                type: 'number',
            },
            {
                name: 'otherIncome8b',
                text: 'Gambling income',
                line: 'Line 8b',
                type: 'number',
            },
            {
                name: 'otherIncome8c',
                text: 'Cancellation of debt',
                line: 'Line 8c',
                type: 'number',
            },
            {
                name: 'otherIncome8d',
                text: 'Foreign earned income exclusion from Form 2555',
                line: 'Line 8d',
                type: 'number',
            },
            {
                name: 'otherIncome8e',
                text: 'Income from Form 8853',
                line: 'Line 8e',
                type: 'number',
            },
            {
                name: 'otherIncome8f',
                text: 'Income from Form 8889',
                line: 'Line 8f',
                type: 'number',
            },
            {
                name: 'otherIncome8g',
                text: 'Alaska permanent fund dividends',
                line: 'Line 8g',
                type: 'number',
            },
            {
                name: 'otherIncome8h',
                text: 'Jury duty pay',
                line: 'Line 8h',
                type: 'number',
            },
            {
                name: 'otherIncome8i',
                text: 'Prizes and awards',
                line: 'Line 8i',
                type: 'number',
            },
            {
                name: 'otherIncome8j',
                text: 'Activity not engaged in profit income',
                line: 'Line 8j',
                type: 'number',
            },
            {
                name: 'otherIncome8k',
                text: 'Stock options',
                line: 'Line 8k',
                type: 'number',
            },
            {
                name: 'otherIncome8l',
                text: 'Income from rental of personal property',
                line: 'Line 8l',
                type: 'number',
            },
            {
                name: 'otherIncome8m',
                text: 'Olympic and paralympic medals and USOC prize money',
                line: 'Line 8m',
                type: 'number',
            },
            {
                name: 'otherIncome8n',
                text: 'Section 951(a) inclusion',
                line: 'Line 8n',
                type: 'number',
            },
            {
                name: 'otherIncome8o',
                text: 'Section 951A(a) inclusion',
                line: 'Line 8o',
                type: 'number',
            },
            {
                name: 'otherIncome8p',
                text: 'Section 461(l) excess business loss adjustment',
                line: 'Line 8p',
                type: 'number',
            },
            {
                name: 'otherIncome8z',
                text: 'Other income',
                line: 'Line 8z',
                type: 'number',
            },
            {
                name: 'additionalIncomeTotal',
                text: 'Total other income',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'sum1to9',
                text: 'Combine lines 1 through 7 and 9',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'educatorExpenses',
                text: 'Educator expenses',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'certainBusinessExpenses',
                text: 'Certain business expenses',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'healthSavings',
                text: 'Health savings',
                line: 'Line 13',
                type: 'number',
            },
            {
                name: 'movingExpenses',
                text: 'Moving expenses',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'deductibleOfSelfEmployment',
                text: 'Deductible part of self-employment',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'selfEmployedSEP',
                text: 'Self-employment SEP, SIMPLE and qualified plans',
                line: 'Line 16',
                type: 'number',
            },
            {
                name: 'selfEmployedHealthInsurance',
                text: 'Self-employment health insurance deduction',
                line: 'Line 17',
                type: 'number',
            },
            {
                name: 'penaltyEarlyWithdrawal',
                text: 'Penalty on early withdrawl or savings',
                line: 'Line 18',
                type: 'number',
            },
            {
                name: 'alimonyPaid',
                text: 'Alimony paid',
                line: 'Line 19a',
                type: 'number',
            },
            {
                name: 'iraDeduction',
                text: 'IRA deduction',
                line: 'Line 20',
                type: 'number',
            },
            {
                name: 'studentLoanInterestDeduction',
                text: 'Student loan interest deduction',
                line: 'Line 21',
                type: 'number',
            },
            {
                name: 'forFutureUseSch1',
                text: 'Reserved for future use',
                line: 'Line 22',
                type: 'number',
            },
            {
                name: 'archerMSADeduction',
                text: 'Archer MSA Deduction',
                line: 'Line 23',
                type: 'number',
            },
            {
                name: 'juryDuty',
                text: 'Jury duty pay',
                line: 'Line 24a',
                type: 'number',
            },
            {
                name: 'deductibleExpenses',
                text: 'Deductible expenses related to income reported on line 8k',
                line: 'Line 24b',
                type: 'number',
            },
            {
                name: 'nontaxableAmountOlympicMedals',
                text: 'Nontaxable amount of the value of Olympic and Paralympic medals',
                line: 'Line 24c',
                type: 'number',
            },
            {
                name: 'reforestationAmortization',
                text: 'Reforestation amortization and expenses ',
                line: 'Line 24d',
                type: 'number',
            },
            {
                name: 'repaymentOfUnemployedBenefits',
                text: 'Repayment of supplemental unemployment benefits under the trade act of 1974',
                line: 'Line 24e',
                type: 'number',
            },
            {
                name: 'contributionsToSection501',
                text: 'Contributions to section 501(c)(18)(D) pension plans',
                line: 'Line 24f',
                type: 'number',
            },
            {
                name: 'contributionsToSection403',
                text: 'Contributions by certain chaplains to section 403(b) plans',
                line: 'Line 24g',
                type: 'number',
            },
            {
                name: 'attorneyFeesDiscriminationClaims',
                text: 'Attorney fees and court costs for actions involving discrimination claims',
                line: 'Line 24h',
                type: 'number',
            },
            {
                name: 'attorneyFeesIRSHelp',
                text: 'Attorney fees and court costs in connection with and award from the IRS',
                line: 'Line 24i',
                type: 'number',
            },
            {
                name: 'housingDeduction',
                text: 'Housing deduction from Form 2555',
                line: 'Line 24j',
                type: 'number',
            },
            {
                name: 'excessDeductions',
                text: 'Excess deductions of section 67(e) expenses from Schedule K-1',
                line: 'Line 24k',
                type: 'number',
            },
            {
                name: 'otherAdjustmens',
                text: 'Other adjustments',
                line: 'Line 24z',
                type: 'number',
            },
            {
                name: 'totalOtherAdjustments',
                text: 'Total other adjustmens',
                line: 'Line 25',
                type: 'number',
            },
            {
                name: 'adjustmentToIncome',
                text: 'Adjustments to income',
                line: 'Line 26',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule 2',
        fields: [
            {
                name: 'clientHasSchedule2',
                text: 'Has Schedule 2?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name:'alternativeMinTax',
                text: 'Alternative minimun tax',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'excessAdvPremiumTaxCredit',
                text: 'Excess advance premium tax credit repayment',
                line: 'Line 2',
                type: 'number',
            },
            {
                name: 'sum1and2',
                text: 'Add lines 1 and 2',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'selfEmploymentTaxSch3',
                text: 'Self-employment tax',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'socialSecurityTip',
                text: 'Social security and Medicare tax on unreported tip income',
                line: 'Line 5',
                type: 'number',
            },
            {
                name: 'uncollectedSocialSecurity',
                text: 'Uncollected social security and Medicare on wages',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'totalAdditionalSocialSecurity',
                text: 'Total additional social security and Medicare',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'additionalTaxOnIRA',
                text: 'Additional tax on IRAs',
                line: 'Line 8',
                type: 'number',
            },
            {
                name: 'householdEmploymentTaxes',
                text: 'Household employment taxes',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'repaymentFirstTimeBuyer',
                text: 'Repayment on first time homebuyer credit',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'additionalMedicare',
                text: 'Additional Medicare tax',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'netInvestmentIncomeTax',
                text: 'Net investment income tax',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'uncollectedSocialSecurityOnTips',
                text: 'Uncollected social security and Medicare or RRTA tax on tips',
                line: 'Line 13',
                type: 'number',
            },
            {
                name: 'interestOnTax',
                text: 'Interest on tax due on installment income from the sale of certain residential lots',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'interestOnDeferredTax',
                text: 'Interest on the deferred tax on gain from certain installment sales with a sales price over $150,000',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'recaptureLowIncome',
                text: 'Recapture of low-income housing credit. Attach Form 8611',
                line: 'Line 16',
                type: 'number',
            },
            {
                name: 'recaptureOtherCredits',
                text: 'Recapture of other credits',
                line: 'Line 17a',
                type: 'number',
            },
            {
                name: 'recaptureFederalMortage',
                text: 'Recapture of federal mortgage subsidy',
                line: 'Line 17b',
                type: 'number',
            },
            {
                name: 'additionalTaxHSA',
                text: 'Additional tax on HSA distributions',
                line: 'Line 17c',
                type: 'number',
            },
            {
                name: 'additionalTaxHSAdidntRemainElegibleIndividual',
                text: "Additional tax on an HSA because you didn't remain an eligible individual",
                line: 'Line 17d',
                type: 'number',
            },
            {
                name: 'additionalTaxArcherMSA',
                text: 'Additional tax on Archer MSA distributions',
                line: 'Line 17e',
                type: 'number',
            },
            {
                name: 'additionalTaxMedicareAdvantageMSA',
                text: 'Additional tax on Medicare Advantage MSA distributions',
                line: 'Line 17f',
                type: 'number',
            },
            {
                name: 'recaptureOfCharitableContributions',
                text: 'Recapture of a charitable contribution deduction related to a fractional interest in tangible personal property',
                line: 'Line 17g',
                type: 'number',
            },
            {
                name: 'incomeFromNonqualifiedCompesationPlan',
                text: 'Income you received from a nonqualified deferred compensation plan that fails to meet the requirements of section 409A ',
                line: 'Line 17h',
                type: 'number',
            },
            {
                name: 'incomeFromNonqualifiedCompesationPlan457A',
                text: 'Compensation you received from a nonqualified deferred compensation plan described in section 457A',
                line: 'Line 17i',
                type: 'number',
            },
            {
                name: 'section72ExcessBenefitsTax',
                text: 'Section 72(m)(5) excess benefits tax',
                line: 'Line 17j',
                type: 'number',
            },
            {
                name: 'goldenParachutePayments',
                text: 'Golden parachute payments',
                line: 'Line 17k',
                type: 'number',
            },
            {
                name: 'taxAccumulationDistributionOfTrusts',
                text: 'Tax on accumulation distribution of trusts',
                line: 'Line 17l',
                type: 'number',
            },
            {
                name: 'exciseTaxOnInsiderStock',
                text: 'Excise tax on insider stock compensation from an expatriated corporation',
                line: 'Line 17m',
                type: 'number',
            },
            {
                name: 'lookbackInterestSection167or460',
                text: 'Excise tax on insider stock compensation from an expatriated corporation',
                line: 'Line 17n',
                type: 'number',
            },
            {
                name: 'taxNonEffectivelyConnectedIncome',
                text: 'Tax on non-effectively connected income for any part of the year you were a nonresident alien',
                line: 'Line 17o',
                type: 'number',
            },
            {
                name: 'interestForm8621line16f',
                text: 'Any interest from Form 8621, line 16f',
                line: 'Line 17p',
                type: 'number',
            },
            {
                name: 'interestForm8621line24',
                text: 'Any interest from Form 8621, line 24',
                line: 'Line 17q',
                type: 'number',
            },
            {
                name: 'anyOtherTaxes',
                text: 'Any other taxes',
                line: 'Line 17z',
                type: 'number',
            },
            {
                name: 'totalAdditionalTaxes',
                text: 'Total additional taxes',
                line: 'Line 18',
                type: 'number',
            },
            {
                name: 'reservedForFutureUse',
                text: 'Additional tax from Schedule 8812',
                line: 'Line 19',
                type: 'number',
            },
            {
                name: 'section965',
                text: 'Section 965 net tax liability installment from Form 965-A',
                line: 'Line 20',
                type: 'number',
            },
            {
                name: 'totalOtherTaxes',
                text: 'Total other taxes',
                line: 'Line 21',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule 3',
        fields: [
            {
                name: 'clientHasSchedule3',
                text: 'Has Schedule 3?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'foreignTax',
                text: 'Foreign tax',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'creditForChildCareTF',
                text: 'Credit for child/dependent care?',
                line: 'Line 2',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'creditForChildCare',
                text: 'Credit for child/dependent care',
                line: 'Line 2',
                type: 'number',
            },
            {
                name: 'educationCredits',
                text: 'Education credits',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'retiramentSavings',
                text: 'Retirament savings contributions credit',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'energyCredits',
                text: 'Residential energy credits',
                line: 'Line 5',
                type: 'number',
            },
            {
                name: 'generalBusinessCredit',
                text: 'General business credit',
                line: 'Line 6a',
                type: 'number',
            },
            {
                name: 'creditForPriorYearMinimunTax',
                text: 'Credit for prior year minimun tax',
                line: 'Line 6b',
                type: 'number',
            },
            {
                name: 'adoptionCredit',
                text: 'Adoption credit',
                line: 'Line 6c',
                type: 'number',
            },
            {
                name: 'creditForEldery',
                text: 'Credit for elderly or disabled',
                line: 'Line 6d',
                type: 'number',
            },
            {
                name: 'alternativeMotorVehicleCredit',
                text: 'Alternative motor vehicle credit',
                line: 'Line 6e',
                type: 'number',
            },
            {
                name: 'qualifiedPlugInMotorVehicleCredit',
                text: 'Qualified plug-in motor vehicle credit',
                line: 'Line 6f',
                type: 'number',
            },
            {
                name: 'mortgageInterestCredit',
                text: 'Mortgage interest credit',
                line: 'Line 6g',
                type: 'number',
            },
            {
                name: 'columbiaFirstTimeBuyer',
                text: 'District of Columbia first-time homebuyer credit',
                line: 'Line 6h',
                type: 'number',
            },
            {
                name: 'qualifiedElectricVehicleCredit',
                text: 'Qualified electric vehicle credit',
                line: 'Line 6i',
                type: 'number',
            },
            {
                name: 'alternativeFuelVehicleCredit',
                text: 'Alternative fuel vehicle refueling property credit',
                line: 'Line 6j',
                type: 'number',
            },
            {
                name: 'creditToHoldersTaxCreditsBonds',
                text: 'Credit to holders of tax credit bonds',
                line: 'Line 6k',
                type: 'number',
            },
            {
                name: 'amountOnForm8978',
                text: 'Amount on Form 8978, line 14',
                line: 'Line 6l',
                type: 'number',
            },
            {
                name: 'otherNonRefundableCredits',
                text: 'Other nonrefundable credits',
                line: 'Line 6z',
                type: 'number',
            },
            {
                name: 'nonRefundableCredits',
                text: 'Total other non refundable credits',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'estimatedTax',
                text: 'Add lines 1 through 5 and 7',
                line: 'Line 8',
                type: 'number',
            },
            {
                name: 'netPremiumTaxCredit',
                text: 'Net premium tax credit',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'requestForExtension',
                text: 'Amount paid with request for extension to file',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'excessSocialSecurity',
                text: 'Excess social security and tier 1 RRTA tax withheld',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'creditForFederalTaxFuels',
                text: 'Credit for federal tax on fuels',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'form2439',
                text: 'Form 2439',
                line: 'Line 13a',
                type: 'number',
            },
            {
                name: 'qualifiedSickCredits',
                text: 'Qualified sick and family leave credits from Schedule Ha and Form 7202 before April 1',
                line: 'Line 13b',
                type: 'number',
            },
            {
                name: 'healthCoverageCredit',
                text: 'Health coverage tax credit from Form 8885',
                line: 'Line 13c',
                type: 'number',
            },
            {
                name: 'creditForRepayment',
                text: 'Credit for repayment of amounts included in income from earlier years',
                line: 'Line 13d',
                type: 'number',
            },
            {
                name: 'futureUse13e',
                text: 'Reserved for future use',
                line: 'Line 13e',
                type: 'number',
            },
            {
                name: 'deferredAmount965',
                text: 'Deferred amount of net 965 tax liability',
                line: 'Line 13f',
                type: 'number',
            },
            {
                name: 'creditChildDependent2441',
                text: 'Credit for child and dependent care expenses from Form 2441',
                line: 'Line 13g',
                type: 'number',
            },
            {
                name: 'qualifiedSickCreditsSchH',
                text: 'Qualified sick and family leave credits from Schedule Ha and Form 7202 after March 31',
                line: 'Line 13h',
                type: 'number',
            },
            {
                name: 'otherPaymentsOrRefundableCredits',
                text: 'Other payments or refundable credits',
                line: 'Line 13z',
                type: 'number',
            },
            {
                name: 'refundableCredits',
                text: 'Total other payments or refundable credits',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'sum9to14',
                text: 'Add lines 9 through 12 and 14',
                line: 'Line 15',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule A',
        fields: [
            {
                name: 'clientHasScheduleA',
                text: 'Has Schedule A?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'medicalExpenses',
                text: 'Medical and dental expenses',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'medicalExpenses2',
                text: 'Multiply line 2 by 7.5%',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'medicalExpenses3',
                text: 'Line 3 minus line 1',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5a',
                text: 'State and local income taxes or general sales taxes',
                line: 'Line 5a',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5b',
                text: 'State and local real estate taxes',
                line: 'Line 5b',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5c',
                text: 'State and local personal property taxes',
                line: 'Line 5c',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5d',
                text: 'Taxes you paid',
                line: 'Line 5d',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5e',
                text: 'Enter the smaller of line 5d or $10,000',
                line: 'Line 5e',
                type: 'number',
            },
            {
                name: 'taxesYouPaid',
                text: 'Deductions allowed for taxes paid',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'mortgageInterests',
                text: 'Home mortgage interest and points reported to you on Form 1098',
                line: 'Line 8a',
                type: 'number',
            },
            {
                name: 'mortgageInterestsNotReported',
                text: 'Home mortgage interest not reported to you on Form 1098',
                line: 'Line 8b',
                type: 'number',
            },
            {
                name: 'pointsNotReported',
                text: 'Points not reported to you on Form 1098',
                line: 'Line 8c',
                type: 'number',
            },
            {
                name: 'mortgageInsurancePremiums',
                text: 'Mortgage insurance premiums',
                line: 'Line 8d',
                type: 'number',
            },
            {
                name: 'sumOfLines8',
                text: 'Add lines 8a through 8d',
                line: 'Line 8e',
                type: 'number',
            },
            {
                name: 'investmentInterest',
                text: 'Investment interest',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'interestYouPaid',
                text: 'Interest you paid (total)',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'giftsByCash',
                text: 'Gifts by cash or check',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'giftsByLand',
                text: 'Other than by cash or check',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'giftsToCharity',
                text: 'Gifts to charity (total)',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'casualtyLosses',
                text: 'Casualty and theft losses',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'otherItemizedDeductions',
                text: 'Other itemized deductions',
                line: 'Line 16',
                type: 'number',
            },
            {
                name: 'summationsItemizedDeductions',
                text: 'Total itemized deductions',
                line: 'Line 17',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule B Part 1',
        fields: [
            {
                name: 'clientHasScheduleB',
                text: 'Has Schedule B?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'scheduleb_1_0_text',
                text: 'scheduleb_1_0_text',
                line: 'Line 0 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_0_amount',
                text: 'scheduleb_1_0_amount',
                line: 'Line 0 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_1_text',
                text: 'scheduleb_1_1_text',
                line: 'Line 1 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_1_amount',
                text: 'scheduleb_1_1_amount',
                line: 'Line 1 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_2_text',
                text: 'scheduleb_1_2_text',
                line: 'Line 2 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_2_amount',
                text: 'scheduleb_1_2_amount',
                line: 'Line 2 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_3_text',
                text: 'scheduleb_1_3_text',
                line: 'Line 3 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_3_amount',
                text: 'scheduleb_1_3_amount',
                line: 'Line 3 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_4_text',
                text: 'scheduleb_1_4_text',
                line: 'Line 4 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_4_amount',
                text: 'scheduleb_1_4_amount',
                line: 'Line 4 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_5_text',
                text: 'scheduleb_1_5_text',
                line: 'Line 5 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_5_amount',
                text: 'scheduleb_1_5_amount',
                line: 'Line 5 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_6_text',
                text: 'scheduleb_1_6_text',
                line: 'Line 6 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_6_amount',
                text: 'scheduleb_1_6_amount',
                line: 'Line 6 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_7_text',
                text: 'scheduleb_1_7_text',
                line: 'Line 7 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_7_amount',
                text: 'scheduleb_1_7_amount',
                line: 'Line 7 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_8_text',
                text: 'scheduleb_1_8_text',
                line: 'Line 8 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_8_amount',
                text: 'scheduleb_1_8_amount',
                line: 'Line 8 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_9_text',
                text: 'scheduleb_1_9_text',
                line: 'Line 9 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_9_amount',
                text: 'scheduleb_1_9_amount',
                line: 'Line 9 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_10_text',
                text: 'scheduleb_1_10_text',
                line: 'Line 10 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_10_amount',
                text: 'scheduleb_1_10_amount',
                line: 'Line 10 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_11_text',
                text: 'scheduleb_1_11_text',
                line: 'Line 11 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_11_amount',
                text: 'scheduleb_1_11_amount',
                line: 'Line 11 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_12_text',
                text: 'scheduleb_1_12_text',
                line: 'Line 12 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_12_amount',
                text: 'scheduleb_1_12_amount',
                line: 'Line 12 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_13_text',
                text: 'scheduleb_1_13_text',
                line: 'Line 13 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_13_amount',
                text: 'scheduleb_1_13_amount',
                line: 'Line 13 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_14_text',
                text: 'scheduleb_1_14_text',
                line: 'Line 14 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_14_amount',
                text: 'scheduleb_1_14_amount',
                line: 'Line 14 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_15_text',
                text: 'scheduleb_1_15_text',
                line: 'Line 15 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_15_amount',
                text: 'scheduleb_1_15_amount',
                line: 'Line 15 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_2',
                text: 'Add the amounts on line 1',
                line: 'Line 2',
                type: 'number',
            },
            {
                name: 'scheduleb_3',
                text: 'Attach from 8815',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'scheduleb_4',
                text: 'Substract line 3 from line 2',
                line: 'Line 4 AMOUNT',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule B Part 2',
        fields: [
            // line 5
            {
                name: 'scheduleb_5_0_text',
                text: 'scheduleb_5_0_text',
                line: 'Line 0 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_0_amount',
                text: 'scheduleb_5_0_amount',
                line: 'Line 0 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_1_text',
                text: 'scheduleb_5_1_text',
                line: 'Line 1 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_1_amount',
                text: 'scheduleb_5_1_amount',
                line: 'Line 1 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_2_text',
                text: 'scheduleb_5_2_text',
                line: 'Line 2 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_2_amount',
                text: 'scheduleb_5_2_amount',
                line: 'Line 2 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_3_text',
                text: 'scheduleb_5_3_text',
                line: 'Line 3 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_3_amount',
                text: 'scheduleb_5_3_amount',
                line: 'Line 3 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_4_text',
                text: 'scheduleb_5_4_text',
                line: 'Line 4 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_4_amount',
                text: 'scheduleb_5_4_amount',
                line: 'Line 4 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_5_text',
                text: 'scheduleb_5_5_text',
                line: 'Line 5 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_5_amount',
                text: 'scheduleb_5_5_amount',
                line: 'Line 5 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_6_text',
                text: 'scheduleb_5_6_text',
                line: 'Line 6 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_6_amount',
                text: 'scheduleb_5_6_amount',
                line: 'Line 6 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_7_text',
                text: 'scheduleb_5_7_text',
                line: 'Line 7 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_7_amount',
                text: 'scheduleb_5_7_amount',
                line: 'Line 7 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_8_text',
                text: 'scheduleb_5_8_text',
                line: 'Line 8 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_8_amount',
                text: 'scheduleb_5_8_amount',
                line: 'Line 8 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_9_text',
                text: 'scheduleb_5_9_text',
                line: 'Line 9 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_9_amount',
                text: 'scheduleb_5_9_amount',
                line: 'Line 9 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_10_text',
                text: 'scheduleb_5_10_text',
                line: 'Line 10 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_10_amount',
                text: 'scheduleb_5_10_amount',
                line: 'Line 10 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_11_text',
                text: 'scheduleb_5_11_text',
                line: 'Line 11 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_11_amount',
                text: 'scheduleb_5_11_amount',
                line: 'Line 11 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_12_text',
                text: 'scheduleb_5_12_text',
                line: 'Line 12 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_12_amount',
                text: 'scheduleb_5_12_amount',
                line: 'Line 12 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_13_text',
                text: 'scheduleb_5_13_text',
                line: 'Line 13 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_13_amount',
                text: 'scheduleb_5_13_amount',
                line: 'Line 13 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_14_text',
                text: 'scheduleb_5_14_text',
                line: 'Line 14 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_14_amount',
                text: 'scheduleb_5_14_amount',
                line: 'Line 14 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_15_text',
                text: 'scheduleb_5_15_text',
                line: 'Line 15 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_15_amount',
                text: 'scheduleb_5_15_amount',
                line: 'Line 15 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_6',
                text: 'Add the amounts on line 5',
                line: 'Line 6 AMOUNT',
                type: 'number',
            },
        ]
    },
    {
        section: 'Schedule C',
        fields: [
            {
                name: 'clientHasScheduleC',
                text: 'Has Schedule C?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'grossReceipts',
                text: 'Gross Receipts',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'returns',
                text: 'Returns',
                line: 'Line 2',
                type: 'number',
            },
            {
                name: 'line3Sch',
                text: 'Substract line 2 from line 1',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'costOfGoods',
                text: 'Cost of Goods',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'grossProfit',
                text: 'Gross Profit',
                line: 'Line 5',
                type: 'number',
            },
            {
                name: 'otherIncomeSchC',
                text: 'Other Income',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'grossIncome',
                text: 'Gross Income',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'advertising',
                text: 'Advertising',
                line: 'Line 8',
                type: 'number',
            },
            {
                name: 'carExpenses',
                text: 'Car Expenses',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'commissions',
                text: 'Commissions',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'contractLabor',
                text: 'Contract Labor',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'depletion',
                text: 'Depletion',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'depreciation',
                text: 'Depreciation',
                line: 'Line 13',
                type: 'number',
            },
            {
                name: 'employeeBenefit',
                text: 'Employee Benefit',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'insurance',
                text: 'Insurance',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'interestMortgage',
                text: 'Interest Mortgage',
                line: 'Line 16a',
                type: 'number',
            },
            {
                name: 'interestOther',
                text: 'Interest Other',
                line: 'Line 16b',
                type: 'number',
            },
            {
                name: 'legal',
                text: 'Legal and professional services',
                line: 'Line 17',
                type: 'number',
            },
            {
                name: 'officeExpenses',
                text: 'Office Expenses',
                line: 'Line 18',
                type: 'number',
            },
            {
                name: 'pensionPlans',
                text: 'Pension Plans',
                line: 'Line 19',
                type: 'number',
            },
            {
                name: 'vehiclesMachinery',
                text: 'Vehicles Machinery',
                line: 'Line 20a',
                type: 'number',
            },
            {
                name: 'otherBusinessProperty',
                text: 'Other Business Property',
                line: 'Line 20b',
                type: 'number',
            },
            {
                name: 'repairs',
                text: 'Repairs',
                line: 'Line 21',
                type: 'number',
            },
            {
                name: 'supplies',
                text: 'Supplies',
                line: 'Line 22',
                type: 'number',
            },
            {
                name: 'taxesAndLicenses',
                text: 'Taxes and Licenses',
                line: 'Line 23',
                type: 'number',
            },
            {
                name: 'travel',
                text: 'Travel',
                line: 'Line 24a',
                type: 'number',
            },
            {
                name: 'meals',
                text: 'Meals',
                line: 'Line 24b',
                type: 'number',
            },
            {
                name: 'utilities',
                text: 'Utilities',
                line: 'Line 25',
                type: 'number',
            },
            {
                name: 'wagesLessEmployeeCredits',
                text: 'Wages less employee credits',
                line: 'Line 26',
                type: 'number',
            },
            {
                name: 'otherExpenses_schC',
                text: 'Other Expenses',
                line: 'Line 27a',
                type: 'number',
            },
            {
                name: 'totalExpenses',
                text: 'Total Expenses',
                line: 'Line 28',
                type: 'number',
            },
            {
                name: 'tentativeProfit',
                text: 'Tentative Profit',
                line: 'Line 29',
                type: 'number',
            },
            {
                name: 'expensesForHomeBusinessTF',
                text: 'Expenses for business use of your home?',
                line: 'Line 30',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'expensesForHomeBusiness',
                text: 'Expenses for business use of your home',
                line: 'Line 30',
                type: 'number',
            },
            {
                name: 'netProfitOrLoss',
                text: 'Net profit or (loss) from business',
                line: 'Line 31',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule D',
        fields: [
            {
                name: 'clientHasScheduleD',
                text: 'Has Schedule D?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'shortTermLossCarryover',
                text: 'Short term capital loss carryover',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'netShortTermCapitalGains',
                text: 'Net short-term capital gain or (loss)',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'longTermLossCarryover',
                text: 'Long term capital loss carryover',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'netLongTermCapitalGains',
                text: 'Net long-term capital gain (or loss)',
                line: 'Line 15',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule E',
        fields: [
            {
                name: 'clientHasScheduleE',
                text: 'Has Schedule E?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'totalAmounts23a',
                text: 'Total amounts reported on line 3 for all rental properties',
                line: 'Line 23a',
                type: 'number',
            },
            {
                name: 'totalAmounts23b',
                text: 'Total amounts reported on line 4 for all royalty properties',
                line: 'Line 23b',
                type: 'number',
            },
            {
                name: 'totalAmounts23c',
                text: 'Total amounts reported on line 12 for all properties',
                line: 'Line 23c',
                type: 'number',
            },
            {
                name: 'totalAmounts23d',
                text: 'Total amounts reported on line 18 for all properties',
                line: 'Line 23d',
                type: 'number',
            },
            {
                name: 'totalAmounts23e',
                text: 'Total amounts reported on line 20 for all properties',
                line: 'Line 23e',
                type: 'number',
            },
            {
                name: 'incomeSchE',
                text: 'Income',
                line: 'Line 24',
                type: 'number',
            },
            {
                name: 'lossesSchE',
                text: 'Losses',
                line: 'Line 25',
                type: 'number',
            },
            {
                name: 'totalRentalRealEstate',
                text: 'Total rental real estate and royalty income or loss',
                line: 'Line 26',
                type: 'number',
            },
            {
                name: 'line30schE',
                text: 'Add columns (h) and (k)',
                line: 'Line 30',
                type: 'number',
            },
            {
                name: 'line31schE',
                text: 'Add columns (g), (i) and (j)',
                line: 'Line 31',
                type: 'number',
            },
            {
                name: 'line32schE',
                text: 'Total partnership and S corporation income or loss',
                line: 'Line 32',
                type: 'number',
            },
            {
                name: 'line35schE',
                text: ' Add columns (d) and (f)',
                line: 'Line 35',
                type: 'number',
            },
            {
                name: 'line36schE',
                text: 'Add columns (c) and (e)',
                line: 'Line 36',
                type: 'number',
            },
            {
                name: 'line37schE',
                text: 'Total estate and trust income or loss',
                line: 'Line 37',
                type: 'number',
            },
            {
                name: 'line39schE',
                text: 'Combine columns (d) and (e)',
                line: 'Line 39',
                type: 'number',
            },
            {
                name: 'line40schE',
                text: 'Net farm rental income or loss',
                line: 'Line 40',
                type: 'number',
            },
            {
                name: 'line41schE',
                text: 'Total income or loss',
                line: 'Line 41',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule SE',
        fields: [
            {
                name: 'clientHasScheduleSE',
                text: 'Has Schedule SE?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'netFarmProfitOrLoss',
                text: 'Net farm profit or (loss) from Schedule F',
                line: 'Line 1a',
                type: 'number',
            },
            {
                name: 'conservationReserveProgramPayments',
                text: 'Amount of Conservation Reserve Program payments included on Schedule F',
                line: 'Line 1b',
                type: 'number',
            },
            {
                name: 'netProfitOrLossFromSchC',
                text: 'Net profit or (loss) from Schedule C, line 31',
                line: 'Line 2',
                type: 'number',
            },
            {
                name: 'combinelines1a1b2',
                text: 'Combine lines 1a, 1b, and 2',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'line4aSchSE',
                text: 'If line 3 is more than zero, multiply line 3 by 92.35% (0.9235). Otherwise, enter amount from line 3',
                line: 'Line 4a',
                type: 'number',
            },
            {
                name: 'line4bSchSE',
                text: 'If you elect one or both of the optional methods, enter the total of lines 15 and 17',
                line: 'Line 4b',
                type: 'number',
            },
            {
                name: 'line4cSchSE',
                text: 'Combine lines 4a and 4b',
                line: 'Line 4c',
                type: 'number',
            },
            {
                name: 'churchEmployeeIncome',
                text: 'Enter your church employee income from Form W-2',
                line: 'Line 5a',
                type: 'number',
            },
            {
                name: 'line5bSchSE',
                text: 'Multiply line 5a by 92.35%',
                line: 'Line 5b',
                type: 'number',
            },
            {
                name: 'addLines4c5b',
                text: 'Add lines 4c and 5b',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'maximumAmountofCombinedWages',
                text: 'Maximum amount of combined wages and self-employment earnings subject to social security tax',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'totalSocialSecurityWages',
                text: 'Total social security wages and tips',
                line: 'Line 8a',
                type: 'number',
            },
            {
                name: 'unreportedTips',
                text: 'Unreported tips subject to social security tax',
                line: 'Line 8b',
                type: 'number',
            },
            {
                name: 'wagesSubjectToSocialSecurity',
                text: 'Wages subject to social security tax from Form 8919, line 10',
                line: 'Line 8c',
                type: 'number',
            },
            {
                name: 'add8a8b8c',
                text: 'Add lines 8a, 8b, and 8c',
                line: 'Line 8d',
                type: 'number',
            },
            {
                name: 'line9SchSE',
                text: 'Subtract line 8d from line 7',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'line10SchSE',
                text: 'Multiply the smaller of line 6 or line 9 by 12.4%',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'line11SchSE',
                text: 'Multiply line 6 by 2.9%',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'selfEmploymentTax',
                text: 'Self-employment tax',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'deductionForOneHalfOfSelfemployment',
                text: 'Deduction for one-half of self-employment tax',
                line: 'Line 13',
                type: 'number',
            },
        ],
    },
    {
        section: 'Form 8995',
        fields: [
            {
                name: 'clientHasForm8995',
                text: 'Has Form 8995?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'totalQualifiedBusinessIncome',
                text: 'Total qualified business income',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'qualifiedBusinessIncomeDeduction',
                text: 'Qualified business income deduction',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'totalReit',
                text: 'Total qualified REIT dividends and PTP (loss) carryforward',
                line: 'Line 17',
                type: 'number',
            },
            
        ],
    },
    {
        section: 'Other Ones',
        fields: [
            {
                name: 'contributionMade457_1',
                text: 'Contribution made to plan 457',
                line: 'Form 8880 Line 2',
                type: 'number',
            },
            {
                name: 'contributionMade457_2',
                text: 'Spouse contribution made to plan 457',
                line: 'Form 8880 Line 2',
                type: 'number',
            },
            {
                name: 'line3form2441',
                text: 'line 3 form 2441',
                line: 'Line 3 form 2441',
                type: 'number',
            },
            {
                name: 'clientHasForm2441',
                text: 'Has Form 2441?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'hasChildren',
                text: 'Has children?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'hasForm8863',
                text: 'Has Form 8863?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm1120',
                text: 'Has Form 1120?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm1120S',
                text: 'Has Form 1120S?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm8839',
                text: 'Has Form 8839?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm6251',
                text: 'Has Form 6251?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm5695',
                text: 'Has Form 5695?',
                type: 'string',
                options: ['Yes', 'No'],
            },
        ],
    },
]