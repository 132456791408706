/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { sysAPI } from '../../apis/SysAPI';
import { Button, Container, Message, Table } from 'semantic-ui-react';
import { responseError } from '../../helpers';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { socketAPI } from '../../apis/SocketAPI';
import { dateFormatting } from '../../helpers/moment-helpers';

export const UsersOnline = () => {
  const dispatch = useDispatch();
  const [nameProp, setNameProp] = useState("NONE");
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    pageChange();

    // eslint-disable-next-line
  }, []);

  const pageChange = async () => {
    setLoading(true);
    try {
      const { data } = await socketAPI.get(`/lumos`);
      const prop = Object.keys(data)[0];
      setNameProp(prop);
      setRows(data[prop].map((e) => ({ ...e, _id: e.id })));
    } catch (error) {
      responseError(error, undefined, 'LasLogin.pagehange', dispatch);
    }
    setLoading(false);
  };

  return (
    <>
      <Container fluid>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button loading={loading} color="grey" onClick={() => pageChange()}>
            Refresh ({nameProp})
          </Button>
        </div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Id</Table.HeaderCell>
              <Table.HeaderCell>Full Name</Table.HeaderCell>
              <Table.HeaderCell>Company</Table.HeaderCell>
              <Table.HeaderCell>Rol</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Last Login Date</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {rows.map((row, index) => (
              <Advisor row={row} key={index} />
            ))}
          </Table.Body>
        </Table>
        {loading && (
          <>
            <Message color="blue">Loading...</Message>
          </>
        )}
      </Container>
    </>
  );
};

const Advisor = ({ row }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(row);

  const getUser = async () => {
    try {
      console.log({ row });
      //http://localhost:6060/sys/advisor/64d1231f3ca8a90a33fa6672
      const { data } = await sysAPI.get(`/advisor/${row._id}`);
      console.log({ data });
      setUser(data.advisorProfile || row);
    } catch (error) {
      responseError(error, undefined, 'Advisor.getUser', dispatch);
    }
  };

  useEffect(() => {
    getUser();
  }, [row._id]);

  return (
    <Table.Row>
      <Table.Cell>{user._id}</Table.Cell>
      <Table.Cell>
        {user?.fullName ? <>
          <Link to={`/dashboard/advisors/${user?._id}`} style={{ color: 'black' }}>
            {user?.fullName}
          </Link>
        </> : <>
          {user.name} - Not founded in DB
        </>}
        <div>

        </div>

      </Table.Cell>
      <Table.Cell>
        <Link to={`/dashboard/companies/${user?.companyId}`} style={{ color: 'black' }}>
          {user?.nameCompany}
        </Link>
      </Table.Cell>
      <Table.Cell>{user.enterpriseRole?.name}</Table.Cell>
      <Table.Cell>{user.email}</Table.Cell>
      <Table.Cell>{dateFormatting(user.activity?.lastLogin || user.lastLogin)}</Table.Cell>
    </Table.Row>
  );
};

export default UsersOnline;
