import { Button, Container, Input, Label, Tab, Table } from 'semantic-ui-react';
import { ALL_AREAS } from './index';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { responseError } from '../../helpers';
import { sysAPI } from '../../apis/SysAPI';

const AreaExtractions = ({ items }) => {
  const _items = items.sort((a, b) => {
    return moment(b.date).diff(a.date);
  });

  const dateFormatting = (date) => {
    return date && moment(date).isValid() ? moment(date).format('MM/DD/YYYY HH:mm:ss') : 'N/A';
  };

  const timeDelta = (timestamps) => {
    const start = timestamps?.start;
    const end = timestamps?.end;

    if (!start || !end || !moment(start).isValid() || !moment(end).isValid()) {
      return 'N/A';
    }

    const delta = moment(end).diff(start);
    const duration = moment.duration(delta);
    return `${duration.minutes()}m ${duration.seconds()}s`;
  };

  return (
    <Tab.Pane>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Advisor</Table.HeaderCell>
            <Table.HeaderCell>Client</Table.HeaderCell>
            <Table.HeaderCell>Year</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Start</Table.HeaderCell>
            <Table.HeaderCell>End</Table.HeaderCell>
            <Table.HeaderCell>Time Delta</Table.HeaderCell>
            <Table.HeaderCell>Result</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_items?.map((item) => {
            return (
              <Table.Row data-stamps={item.timestamps ? JSON.stringify(item.timestamps) : '{}'}>
                <Table.Cell>{item?.advisor?.fullName}</Table.Cell>
                <Table.Cell>{item.client ? `${item.client.firstName} ${item.client.lastName}` : 'N/A'}</Table.Cell>
                <Table.Cell>{item.data.year ? item.data.year : 'N/A'}</Table.Cell>
                <Table.Cell>{moment(item?.date).format('MM/DD/YYYY HH:mm:ss')}</Table.Cell>
                <Table.Cell>{dateFormatting(item.data.timestamps?.start)}</Table.Cell>
                <Table.Cell>{dateFormatting(item.data.timestamps?.end)}</Table.Cell>
                <Table.Cell>{timeDelta(item.data.timestamps)}</Table.Cell>
                <Table.Cell>{item.data.isValid ? <Label color="green">Valid</Label> : <Label color="red">Invalid</Label>}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Tab.Pane>
  );
};

const Extractions = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [loading, setLoading] = useState(false)
  const [areasExtractions, setAreasExtractions] = useState([])

  useEffect(() => {
    filterBtn_Click()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const filterBtn_Click = async () => {
    setLoading(true)
    try {
        const {data} = await sysAPI.get(`/advisors/areas-extractions?startDate=${startDate}&endDate=${endDate}`);
        setAreasExtractions(data.advisorsList)
    } catch (error) {
        responseError(error, undefined, "Extractions.filterBtn_Click", dispatch);
    }
    setLoading(false)
  };

  const extractions = areasExtractions.map((item) => {
    return {
      areaId: item.area,
      items: item.data.map((i) => {
        return { ...i, advisor: i.advisorData, client: i.clientData };
      }),
    };
  });

  const panes = extractions.map((extr) => ({
    menuItem: `${ALL_AREAS[extr.areaId]} (${extr.items.length})`,
    render: () => <AreaExtractions items={extr.items} />,
  }));

  return (
    <>
      <Container fluid style={{ margin: '0 0 20px 0' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div>
            <span style={{ marginRight: '10px' }}>From</span>
            <Input type="date" value={startDate} onChange={(e) => setStartDate(e.currentTarget.value)} />
            <span style={{ margin: '0 10px' }}>to</span>
            <Input type="date" value={endDate} onChange={(e) => setEndDate(e.currentTarget.value)} />
            <Button loading={loading} style={{ marginLeft: '10px' }} color="grey" onClick={() => filterBtn_Click()}>
              Filter
            </Button>
          </div>
        </div>
      </Container>
      <Container fluid>
        <Tab panes={panes} />
      </Container>
    </>
  );
};

export default Extractions;
