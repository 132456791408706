import { Dimmer, Loader, Segment } from "semantic-ui-react";

const LoaderCustom = () => (
    <div
        className="custom-loader-all-page"
    >
        <Segment style={{visibility: "hidden", backgroundColor:"#00000000"}}>
            <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
            </Dimmer>
        </Segment>
    </div>
);

export default LoaderCustom;