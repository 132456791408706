import { useEffect, useState } from "react";
import { sysAPI } from "../apis/SysAPI";
import { DashboardTemplate } from "../templates/DashboardTemplate";
import { Button, Pagination, Container, Table, /* Dropdown, */ Input, Segment, Dimmer, Loader, Popup, Icon } from "semantic-ui-react";
import { TabContainer } from "../components/shared/TabContainer";
import { fpalphaAPI } from "../apis/FPAlphaAPI";
import MessageTablesEmpty from "../components/globals/MessageTablesEmpty";
import { useDispatch, useSelector } from "react-redux";
import { dateFormatting } from "../helpers/moment-helpers";
import { copyToClipboard, responseError } from "../helpers";
import { setSnackbar } from "../actions/snackbar.actions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export const DashboardClientsView = () => {
  const dispatch = useDispatch();
  //const pageSize = 15;
  const [clientsList, setClientsList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  //const [company, setCompany] = useState("");
  //const [companiesList, setCompaniesList] = useState([]);
  const [search, setSearch] = useState({ activePage: 1, company: "", searchInput: "" });
  const [searchInput, setSearchInput] = useState("");
  const [counterSearched, setCounterSearched] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [filterButtonIsDisabled, setFilterButtonIsDisabled] = useState(true)
  const me = useSelector((state) => state.me);
  const [showTabActions, setShowTabActions] = useState(false);

  useEffect(() => {
    if (counterSearched > -1) {
      apiCall();
    }
    setCounterSearched(counterSearched + 1);
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    if(searchInput?.length === 24){
      setFilterButtonIsDisabled(false)
    }
    else if(searchInput?.length <= 5 || !searchInput?.includes("@") || searchInput?.includes(" ")){
      setFilterButtonIsDisabled(true)
    }else{
      setFilterButtonIsDisabled(false)
    }
  },[searchInput])

  useEffect(() => {
    const hasPermissionsToShowActions = me?.permissions?.filter(p => ["clients:copy_email", "clients:copy_id"].includes(p)) || []
    if(hasPermissionsToShowActions.length > 0){
      setShowTabActions(true)
    }
  },[me])

  const pageChange = (e, { activePage }) => {
    setSearch({ ...search, activePage });
  };

  const searchBtn_Click = () => {
    if(filterButtonIsDisabled){
      alert("enter a valid email");
      return
    }
    setSearch({ activePage: 1, /* company, */ searchInput });
  };

  const apiCall = async () => {
    setIsLoading(true);
    try {
      const { data } = await sysAPI.get(`/client/${searchInput}`);
      const { clientsList, metadata } = data;
      setClientsList(clientsList);
      setTotalPages(metadata[0].pages);
      setCounterSearched(counterSearched + 1);
    } catch (error) {
      console.log({ error });
    }
    setIsLoading(false);
  };
  const ClientRow = ({client, key}) => {
    const [isCopied, setIsCopied] = useState(false);
    const [loaderDowload, setLoaderDowload] = useState(false);

    const downloadBtn_Click = async () => {
        setLoaderDowload(true)
        try {
            const {data: dataCode} = await sysAPI.post('/generate-code');
            const {data} = await fpalphaAPI.post('/tools/exportClient2JSON', {
                clientsIds: [client._id],
                download: true,
                code: dataCode.code,
            }, {
                responseType: 'blob',
            })
            const href = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = href;
            link.target = '_blank';
            link.setAttribute('download', `${client.email}.fpclient`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            dispatch(setSnackbar({message: `Client downloaded ${client.clientIDFirstName} ${client.clientIDLastName} (${client.email})`}))
        } catch (error) {
            responseError(error, undefined, "AdvisorClientsTab.downloadBtn_Click", dispatch)
        }
        setLoaderDowload(false)
    };

    return <Table.Row key={key} style={{ color: client.isProspect ? "blue" : "black" }}>
      <Table.Cell>{client.clientIDFirstName}</Table.Cell>
      <Table.Cell>{client.clientIDLastName}</Table.Cell>
      <Table.Cell>{client.email}</Table.Cell>
      <Table.Cell>
        <Link
          to={`/dashboard/companies/${client.companyInfo?._id}`}
          style={{ color: 'black' }}
        >
          {client.companyName}
        </Link>
      </Table.Cell>
      <Table.Cell>
        <Link
          to={`/dashboard/advisors/${client.ownerData?._id}`}
          style={{ color: 'black' }}
        >
          {client.ownerData?.fullName}
        </Link>
      </Table.Cell>
      <Table.Cell>{client.idAdvisorList?.length > 1 ? "Yes" : "No"}</Table.Cell>
      <Table.Cell>{dateFormatting(client.creationDate)}</Table.Cell>
      <Table.Cell>
        <Button loading={loaderDowload} onClick={() => downloadBtn_Click()}>Download</Button>
      </Table.Cell>
      {showTabActions && <>
        <Table.Cell>
          {me?.permissions?.includes("clients:copy_email") && <>
            <Popup
              on={['hover']}
              trigger={
                <Icon name='at' style={{cursor:"pointer"}} size="large" onClick={() => {
                  copyToClipboard(`${client.email}`, false)
                  setIsCopied(true)
                  setTimeout(() => {
                    setIsCopied(false)
                  }, 1000)
                }} />
              }
              content={isCopied ? 'Copied': 'Click to copy Email'}
              inverted
            />
            </>}
            {me?.permissions?.includes("clients:copy_id") && <>
              <Popup
                on='hover'
                trigger={
                  <Icon name='barcode' style={{cursor:"pointer"}} size="large" onClick={() => {
                    copyToClipboard(`${client._id}`, false)
                    setIsCopied(true)
                    setTimeout(() => {
                      setIsCopied(false)
                    }, 1000)
                  }} />
                }
                content={isCopied ? 'Copied': 'Click to copy ID'}
                inverted
              />
            </>}
        </Table.Cell>
      </>}
    </Table.Row>
  }


  return (
    <DashboardTemplate scope="clients" permission="clients:client_view">
      <TabContainer title="Clients Management" subtitle="Clients" icon="address card">
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Input
            value={searchInput}
            onChange={(e) => setSearchInput(e.currentTarget.value)}
            icon="search"
            placeholder="Enter an email"
            iconPosition="left"
            style={{ marginRight: "10px", width: "300px" }}
            onKeyPress={(e) => (e.charCode === 13 || e.keyCode === 13 || e.which === 13) && searchBtn_Click()}
          />
          {/* <Dropdown
            style={{ margin: "0 6px 0 0" }}
            placeholder="Company"
            selection
            search
            options={companiesList}
            value={company}
            onChange={(e, { value }) => {
              setCompany(value);
            }}
          /> */}
          <Button disabled={filterButtonIsDisabled} onClick={searchBtn_Click} loading={isLoading}>
            Filter
          </Button>
        </div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Last Name</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Company</Table.HeaderCell>
              <Table.HeaderCell>Owner</Table.HeaderCell>
              <Table.HeaderCell>Shared</Table.HeaderCell>
              <Table.HeaderCell>Creation Date</Table.HeaderCell>
              <Table.HeaderCell>Button Download</Table.HeaderCell>
              {showTabActions && <>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </>}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {isLoading ? (
              <>
                <Table.Row>
                  <Table.Cell colSpan={11}>
                    <Segment style={{ width: "100%", height: "300px" }}>
                      <Dimmer active inverted>
                        <Loader inverted>Loading</Loader>
                      </Dimmer>
                    </Segment>
                  </Table.Cell>
                </Table.Row>
              </>
            ) : (
              <>
                {clientsList?.map((client, key) => <ClientRow client={client} key={key} />)}
              </>
            )}
          </Table.Body>
        </Table>
        {!isLoading && clientsList.length < 1 && <MessageTablesEmpty counterSearched={counterSearched} />}
        <Container fluid textAlign="right">
          <Pagination totalPages={totalPages} activePage={search.activePage} onPageChange={pageChange} />
        </Container>
      </TabContainer>
    </DashboardTemplate>
  );
};
