import { useEffect, useState } from 'react';
import { Redirect, /* useLocation */ } from 'react-router-dom';
import { Form, Input, Button, Segment, Message } from 'semantic-ui-react';
import { sysAPI } from '../apis/SysAPI';
import qs from 'query-string';
import { fpalphaAPI } from '../apis/FPAlphaAPI';

export const LoginView = () => {
    // const location = useLocation();
    const [username, setUsername] = useState('');
    const [accessCode, setAccessCode] = useState('');
    const [challenge, setChallenge] = useState('');
    const [stage, setStage] = useState('username');
    const [errorMessageType, setErrorMessageType] = useState('');
    const [showPassword, setShowPassword] = useState(false)

    const verifyToken = async () => {
        try {
            const {data} = await fpalphaAPI.get('/sys/me');
            console.log({data})
            window.location.replace("/")
        } catch (error) {
            console.log("No Login")
        }
    }   

    useEffect(() => {
        const { error } = qs.parse(window.location.search);
        if (error) setErrorMessageType(error);
        verifyToken()
    }, []);

    const loginBtn_Click = () => {
        sysAPI.post('/auth/login', { username }).then(response => {
            setStage('access_code');
            setChallenge(response.data.challenge);
        }).catch(err => {
            console.error(err);
            alert('Incorrect credentials. Please try again.');
        });
    };

    const exchangeAccessCodeBtn_Click = () => {
        sysAPI.post('/auth/login/exchange-code', { accessCode, challenge, email: username }).then(response => {
            const { accessToken, refreshToken, apikey } = response.data;
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem('apikey', apikey);
            setStage('logged_in');
        }).catch(err => {
            console.error(err);
            alert('Incorrect credentials. Please try again.');
        });
    };

    if (stage === 'logged_in') {
        return <Redirect to='/dashboard' />
    }

    return (
        <div className='card-login'>
            <Segment style={{ width: '320px' }}>
                <img width='160px' style={{ verticalAlign: 'top', display: 'block', margin: '20px auto' }} src='https://storage.googleapis.com/fpalpha-production-public/fpalpha-assets/website/fpalpha-logo.svg' alt="..." />
                {errorMessageType === 'session_expired' && <Message color='red'>Your session expired.</Message>}
                <Form.Group style={{ display: stage === 'username' ? 'block' : 'none' }}>
                    <Form.Field value={username} onChange={e => setUsername(e.currentTarget.value)} style={{ marginBottom: '10px' }} fluid control={Input} placeholder='Email' onKeyPress={e => [13].includes(e.charCode) ? loginBtn_Click() : ""} />
                    {/* <Form.Field value={password} onChange={e => setPassword(e.currentTarget.value)} style={{ marginBottom: '10px' }} fluid control={Input} placeholder='Password' type='password' /> */}
                    <Button fluid color='black' style={{ padding: '15px' }} onClick={loginBtn_Click}>Continue</Button>
                </Form.Group>

                <Form.Group style={{ display: stage === 'access_code' ? 'block' : 'none' }}>
                    <div style={{ marginBottom: '10px' }}>
                        Please check your e-mail ({username}) and enter the received access code:
                    </div>
                    <Form.Field
                        type={showPassword ? 'text' : 'password'}
                        value={accessCode}
                        onChange={e => setAccessCode(e.currentTarget.value)}
                        style={{ marginBottom: '10px' }}
                        fluid control={Input}
                        placeholder='Code or Password'
                        onKeyPress={e => [13].includes(e.charCode) ? exchangeAccessCodeBtn_Click() : ""}
                        icon={{ name: showPassword ? 'eye' : 'eye slash', circular: true, link: true, onClick: () => setShowPassword(!showPassword) }}
                    />
                    {/* <Form.Field value={password} onChange={e => setPassword(e.currentTarget.value)} style={{ marginBottom: '10px' }} fluid control={Input} placeholder='Password' type='password' /> */}
                    <Button fluid color='black' style={{ padding: '15px' }} onClick={exchangeAccessCodeBtn_Click}>Login</Button>
                    <Button fluid color='grey' style={{ padding: '15px', marginTop: '10px' }} onClick={() => { setStage('username'); setAccessCode('') }}>Cancel</Button>
                </Form.Group>
            </Segment>
        </div>
    )
}