/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 } from 'uuid';
//import { Link } from 'react-router-dom';
import { Modal, Header, Dropdown, Input, Button, Form, Icon, Select, /* Message */ } from 'semantic-ui-react';
import { sysAPI } from '../../apis/SysAPI';
import { fpalphaAPI } from '../../apis/FPAlphaAPI';
import { manualInputFields } from '../../data/manual-input-fields';
import qs from 'query-string';
import fulltax_in_depth from '../../data/manual-input-fields/fulltax_in_depth';
import home_in_depth from '../../data/manual-input-fields/home_in_depth';
import auto_in_depth from '../../data/manual-input-fields/auto_in_depth';
import { PropertiesModal } from './PopertiesModal';
import { fetchCurrentClient } from '../../actions/client.actions';
import { getFirstLetterInUpperCase } from '../../helpers/advisors';
import { UnlockQuestionarie, /* UnlockUploaders */ } from './ManualInputModal/ToggleUnlock';
import { BuildEstateQuestionnaireStructure } from '../shared/EstateGeneralQuestionnaire';
import { responseError } from '../../helpers';
import { setSnackbar } from '../../actions/snackbar.actions';

const ALL_AREAS = {
    'fulltax': 'Tax Planning',
    'estate': 'Estate Planning',
    'auto': 'Auto',
    //'crossborder': 'Cross Border',
    // 'disability': 'Disability',
    'home': 'Home',
    // 'medical': 'Medical',
    // 'identity': 'Identity',
    // 'student': 'Student Loan',
    // 'expense': 'Expense',
    // 'ltc': 'LTC',
    // 'mortgage': 'Mortgage',
    // 'life': 'Life Planning',
    // 'divorce': 'Divorce Planning',
    // 'property': 'Property',
    // 'creditor': 'Creditor',
    // 'elder': 'Elder care',
};

const SimpleInput = ({valContent, placeholder, blur, datapoint, property, beneficiary, type, inputType}) => {
    const [value, setValue] = useState(valContent);

    const changeHandler = (e) => {
        if (type === 'property') {
            blur(datapoint, property, e.target.value);
        } else {
            blur(type, datapoint, property, beneficiary, e.target.value);
        }
    }

    return (<Input
        style={{ width: '400px', margin: '0 30px 0 5px' }}
        value={value}
        type={inputType ||'text'}
        placeholder={placeholder}
        onChange={(_, elem) => setValue(elem.value)}
        onBlur={changeHandler}
    />);
};


export const ManualInputField = ({ text, section, line, options, onInputChange, value, providers, type, placeholder, name, ignoreBorder }) => {
    const [changeValue, setChangeValue] = useState("");
    useEffect(() => {
        if(value){
            //console.log({value})
            setChangeValue(value || "")
        }
    },[])


    const handleChangeValue = (newValue) => {
        //console.log({newValue})
        setChangeValue(newValue);
        onInputChange(newValue);
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', padding: '15px 0', ...(ignoreBorder && {borderBottom: '1px solid silver'}) }}>
            <div style={{ marginRight: '20px' }} name={name}>
                <div style={{ fontSize: '14px' }}>{text}</div>
                {line && <div style={{ fontSize: '14px', color: 'silver' }}>{section}, {line}</div>}
            </div>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                {(options && options === 'provider') &&
                    <Dropdown
                        placeholder='Insurance provider...'
                        value={value}
                        selection
                        search
                        options={providers.map(itm => ({key: itm.showName, value: itm.showName, text: itm.showName}))}
                        style={{ maxWidth: '200px', minWidth: 'unset', flex: '1 1 auto'}}
                        onChange={(_, val) => onInputChange(val.value)}
                    />
                }
                {(options && options.length > 2 && options !== 'provider') &&
                    <Dropdown
                        placeholder={placeholder || ''}
                        value={value}
                        selection
                        search
                        options={options.map(itm => ({key: itm, value: itm, text: itm}))}
                        style={{ maxWidth: '200px', minWidth: 'unset', flex: '1 1 auto'}}
                        onChange={(_, val) => onInputChange(val.value)}
                    />
                }
                {(options && options.length <= 2 && options !== 'provider') &&
                    <Form.Group style={{ display: 'flex' }}>
                        {options.map((opt,index) =>
                            <Form.Field key={index} >
                                <Form.Radio
                                    value={opt}
                                    label={opt}
                                    onChange={e => handleChangeValue(opt)}
                                    style={{ marginRight: '10px' }}
                                    checked={changeValue === opt}
                                />
                            </Form.Field>
                        )}
                    </Form.Group>
                }
                
                {!options &&
                    <Input
                        defaultValue={changeValue}
                        style={{ width: '200px' }}
                        placeholder={ placeholder ? placeholder : type === "number" ? '0' : '' }
                        onChange={e => handleChangeValue(e.currentTarget.value)}
                        type={type}
                        onWheel={(event) => {
                            //console.log("Scrolling")
                            event.target.blur()
                        }}
                    />
                }
            </div>
            {/* {value} */}
        </div>
    )
}

export const ManualInputModal = ({ advisor, setAdvisor }) => {
    const [clients, setClients] = useState(null);
    const [selectedClient, setSelectedClient] = useState('');
    const [selectedAreaId, setSelectedAreaId] = useState('');
    const [indexId, setIndexId] = useState(0);
    const [questionnaire, setQuestionnaire] = useState({});
    const [taxYear, setTaxYear] = useState('');
    const [taxYearOptions, setTaxYearOptions] = useState([]);
    // eslint-disable-next-line
    const [estateManualInput, setEstateManualInput] = useState(null);
    const [showPropertiesModal, setShowPropertiesModal] = useState(false);
    const [allAssets, setAllAssets] = useState([]);
    const [allProviders, setAllProviders] = useState([]);
    const [assets, setAssets] = useState([]);
    const [selectedAsset, setSelectedAsset] = useState('');
    const [fetchingProperties, setFetchingProperties] = useState(false);
    const [loader, setLoader] = useState(true);
    const [loaderSubtmit, setLoaderSubmit] = useState(false);
    const [loaderReset, setLoaderReset] = useState(false);
    const [answeredQuestions, setAnsweredQuestions] = useState({});
    // eslint-disable-next-line
    const [isDisabledQuestions, setIsDisabledQuestions] = useState(true);
    const [irrevNumber, setIrrevNumber] = useState(0);
    const [fieldsSections, setFieldsSections] = useState([]);

    // eslint-disable-next-line
    const me = useSelector(state => state.me);
    const dispatch = useDispatch();

    const sortProperties = (properties) => {
        const home = [];
        const auto = [];
        if (properties?.data?.home?.length > 0) {
            properties?.data?.home?.forEach(itm => {
                home.push({
                    key: itm._id,
                    text: itm.title,
                    value: itm.index,
                });
            });
        }
        if (properties?.data?.auto?.length > 0) {
            properties?.data?.auto?.forEach(itm => {
                auto.push({
                    key: itm._id,
                    text: itm.title,
                    value: itm.index,
                });
            });
        }
        setAssets(selectedAreaId === 'home' ? home : auto);
        setFetchingProperties(false);
    }

    const getProperties = (id) => {
        setSelectedAsset('');
        setFetchingProperties(true);
        sysAPI.get(`/insurance-snapshot/assets/${id}`)
            .then(response => {
                setAllAssets(response);
                sortProperties(response);
            })
    };

    const getEstateProperties = (id) => {
        setSelectedAsset('');
        setFetchingProperties(true);
        sysAPI.get(`/insurance-snapshot/assets/${id}?area=estate`)
            .then(response => {
                //console.log(response)
                setAllAssets(response);
                const estate = [];
                if (response?.data?.estate?.length > 0) {
                    response?.data?.estate?.forEach(itm => {
                        estate.push({
                            key: itm._id,
                            text: itm.title,
                            value: itm.index,
                        });
                    });
                    setAssets(estate)
                }
                setFetchingProperties(false);
            })
    };

    const getAllProviders = () => {
        sysAPI.get('/insurance-providers/')
            .then(response => {
                setAllProviders(response.data);
            });
    };

    useEffect(() => {
        const { clientId, area } = qs.parse(window.location.search);
        // console.log({clients});

        if (area) {
            setSelectedAreaId(area);
        }

        sysAPI.get(`/advisor/${advisor._id}/clients`).then(response => {
            setClients(response.data.clients);
            if (clientId) {
                setSelectedClient(clientId);
                if (area === 'estate') {
                    fetchEstateManualInput(clientId);
                }
            }
        }).catch(err => {
            setClients([]);
        });

        getAllProviders();

        const taxYearOpts = [];
        const currentYear = new Date().getFullYear();

        for (const year in manualInputFields.fulltax) {
            taxYearOpts.push({ key: +year, text: year.toString(), value: year.toString() });
        }
        setTaxYearOptions(taxYearOpts);
        setTaxYear((currentYear - 1).toString());

    // eslint-disable-next-line
    }, []);

    const fieldInput_Change = (name, type) => {
        return (value) => {
            //console.log("Se ejecuta")
            //console.log({type, name, value})
            const auxQuestionnaire = JSON.parse(JSON.stringify(questionnaire));
            auxQuestionnaire[name] = { answer: value, type, whoAnswered: 'fpalpha' };
            setQuestionnaire(auxQuestionnaire);
            //console.log(questionnaire)
            if (selectedAreaId === 'estate') {
                const newAnswers = {...answeredQuestions};
                newAnswers[name] = true;
                setAnsweredQuestions(newAnswers);
            }
            checkAnswered();
        }
    }

    const checkAnswered = () => {
        if (loaderSubtmit) return true;
        if (selectedAreaId !== 'estate') return true;
        const total = Object.values(answeredQuestions).filter((answer) => (answer === false));
        if (total.length === 0) setIsDisabledQuestions(false);
    };

    const fetchEstateManualInput = (clientId) => {
        setEstateManualInput(null);
        sysAPI.get(`/estate-manual-input/status?clientId=${clientId}`).then(response => {
            setEstateManualInput(response.data);
        }).catch(err => {
            console.error(err);
        });
    }

    const fillQuestionsEstate = async () => {
        setLoader(true);
        let auxQuestion = {};
        try {
            const {data} = await sysAPI.get(`/questionnaire-validations-pending/${selectedClient}/${selectedAreaId}/${indexId}`)
            if(data.data){
                const datapoints = data.data.datapoints || {};
                let allQuestions = fieldsSections.reduce((all, section) => [...all, ...section.fields], []);

                const hasIrrevs = datapoints["irrev_hasIrrev"] || "No"
                if(hasIrrevs === "Yes"){
                    const totalIrrevs = +datapoints["irrev_number"] || 0
                    setIrrevNumber(totalIrrevs);
                    const auxQuestions = fieldIrrevocableQuestions(totalIrrevs);
                    allQuestions = []
                    for(const auxQuestion of auxQuestions){
                        for(const field of auxQuestion.fields){
                            allQuestions.push(field)
                        }
                    }
                }else{
                    setIrrevNumber(0)
                }

                for(const quest of allQuestions){
                    const questionAnswered = datapoints[quest.name]
                    if(questionAnswered && !["undefined"].includes(questionAnswered)){
                        auxQuestion[quest.name] = {
                            answer: questionAnswered || "",
                            type: quest.type || "string",
                            whoAnswered: "fpalpha"
                        }
                    }
                }

                //console.log('AUX_QUESTIONS:', {auxQuestion});
                console.log("Fill questions estate success ✅")
                setQuestionnaire(auxQuestion)
            }
        } catch (error) {
            console.log({error});
            const message = error?.response?.data?.message || error?.message || "Something is wrong, try again."
            alert(message)
        }
        setLoader(false)
    }

    const fillQuestions = async () => {
        setLoader(true);
        let auxQuestion = {
            clientIDClientHasHomeInsurance: {
                answer: 'No',
                type: "string",
                whoAnswered: "fpalpha"
            }
        };
        try {
            const {data} = await fpalphaAPI.get(`/advisor/area/${selectedClient}/questionnaire/${selectedAreaId}`,{
                params: {
                    index: indexId,
                    advisorId: advisor?._id,
                    clientId: selectedClient?._id,
                }
            })
            const allQuestions = fieldsSections.reduce((all, section) => [...all, ...section.fields], []);
            for(const quest of allQuestions){
                const questionAnswered = data.answers.find(e => e.name === quest.name);
                //console.log({name:quest.name, questionAnswered})
                if(questionAnswered){
                    auxQuestion[quest.name] = {
                        answer: questionAnswered.answer || "",
                        type: questionAnswered.type || quest.type || "string",
                        whoAnswered:questionAnswered.whoAnswered || "fpalpha"
                    }
                }
                //console.log({quest})
            }

            //console.log('AUX_QUESTIONS:', {auxQuestion});
            console.log("Fill questions success ✅")
            setQuestionnaire(auxQuestion)
        } catch (error) {
            console.log({error});
            const message = error?.response?.data?.message || error?.message || "Something is wrong, try again."
            alert(message)
        }
        setLoader(false)
    }

    const submitBtn_Click = (sendNotification) => {
        return async () => {
            setLoaderSubmit(true)
            const submittingQuestionnaire = JSON.parse(JSON.stringify(questionnaire));
            const allQuestions = fieldsSections.reduce((all, section) => [...all, ...section.fields], []);
            for (const question of allQuestions) {
                if (submittingQuestionnaire[question.name] === undefined) {
                    submittingQuestionnaire[question.name] = {
                        answer: question.type === 'number' ? '0' : 'undefined',
                        type: question.type,
                        whoAnswered: 'fpalpha',
                    };

                    if(question.default){
                        //console.log(question)
                        submittingQuestionnaire[question.name].answer = question.default
                    }
                }
            }

            submittingQuestionnaire['clientIDWantsToInputTaxData'] = {
                answer: 'Yes',
                type: 'string',
                whoAnswered: 'fpalpha',
            }

            if (['fulltax'].includes(selectedAreaId)) {
                const indepth = fulltax_in_depth;
                for (const question in indepth) {
                    submittingQuestionnaire[question] = {
                        answer: indepth[question],
                        type: indepth[question] === '0' ? 'number' : 'string',
                        whoAnswered: 'fpalpha',
                    };
                }
                submittingQuestionnaire['clientIDTaxYear'] = {
                    answer: `${taxYear}`,
                    type: 'number',
                    whoAnswered: 'fpalpha',
                };
            }

            if (['auto'].includes(selectedAreaId)) {
                const indepth = auto_in_depth;
                for (const question in indepth) {
                    submittingQuestionnaire[question] = {
                        answer: indepth[question],
                        type: indepth[question] === '0' ? 'number' : 'string',
                        whoAnswered: 'fpalpha',
                    };
                }
                submittingQuestionnaire[`clientID${getFirstLetterInUpperCase(selectedAreaId)}PolicyStatus`] = {
                    answer: `COMPLETE`,
                    type: 'string',
                    whoAnswered: 'fpalpha',
                };
            }
            if (['home'].includes(selectedAreaId)) {
                const indepth = home_in_depth;
                for (const question in indepth) {
                    submittingQuestionnaire[question] = {
                        answer: indepth[question],
                        type: indepth[question] === '0' ? 'number' : 'string',
                        whoAnswered: 'fpalpha',
                    };
                }
                submittingQuestionnaire[`clientID${getFirstLetterInUpperCase(selectedAreaId)}PolicyStatus`] = {
                    answer: `COMPLETE`,
                    type: 'string',
                    whoAnswered: 'fpalpha',
                };
                if ( submittingQuestionnaire['clientIDHasUmbrella'].answer  === 'Yes') {
                    submittingQuestionnaire[`clientID${getFirstLetterInUpperCase(selectedAreaId)}PolicyStatus`] = {
                        answer: `COMPLETE`,
                        type: 'string',
                        whoAnswered: 'fpalpha',
                    };
                }
            }

            if (['estate'].includes(selectedAreaId)) {
                if(!submittingQuestionnaire['irrev_hasIrrev']) {
                    submittingQuestionnaire['irrev_hasIrrev'] = {
                        answer: `No`,
                        type: 'string',
                        whoAnswered: 'fpalpha',
                    }
                }
                if(!submittingQuestionnaire['irrev_number']) {
                    submittingQuestionnaire['irrev_number'] = {
                        answer: 0,
                        type: 'number',
                        whoAnswered: 'fpalpha',
                    }
                }
                submittingQuestionnaire['irrev_hasIrrev'].answer = irrevNumber >= 1 ? 'Yes' : 'No';
                submittingQuestionnaire['irrev_number'].answer = irrevNumber;
            }

            console.log({submittingQuestionnaire})
            try {
                await sysAPI.post('/manual-input', {
                    clientId: selectedClient,
                    area: selectedAreaId,
                    sendNotification,
                    index: ['auto', 'home', 'estate'].includes(selectedAreaId) ? indexId : 1,
                    questionnaire: submittingQuestionnaire,
                    advisorId: advisor._id,
                    policyFilename: ['auto', 'home'].includes(selectedAreaId) ? undefined : `clientIDFulltaxPolicy${taxYear}`,
                    year: ['fulltax'].includes(selectedAreaId) ? taxYear : undefined,
                });

                dispatch(setSnackbar({message: "Manual input saved correctly."}))
                //setAdvisor(null);
                setSelectedAreaId("");
            }
            catch (err) {
                responseError(err, "Could not submit manual input. Please try again later.'", "ManualInputModal.submitBtn_Click", dispatch)
            }
            setLoaderSubmit(false)
        }
    }

    const areasOptions = Object.keys(ALL_AREAS).map(areaId => ({ key: areaId, text: ALL_AREAS[areaId], value: areaId }));
    const clientsOptions = clients?.map(client => ({ key: client._id, text: `${client.clientIDFirstName} ${client.clientIDLastName} (${client.email})`, value: client._id })) || [];;
    // setFieldsSections(selectedAreaId === 'fulltax' ? manualInputFields[selectedAreaId]?.[taxYear] || [] : manualInputFields[selectedAreaId] || []);
    // console.log('FIELDS:', {fieldsSections})
    const clientInfo = clients?.find(client => client._id === selectedClient) || {};
    // const indexOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(ind => ({key: ind, text: ind, value: ind}));
    /* const clientIndexOptions = [
        { value: '1', text: 'Client' },
        { value: '2', text: 'Coclient' },
    ] */

    const taxYearDropdown_Change = (e) => {
        setTaxYear(e.target.textContent);
    }

    const areaDropdown_Change = (e, data) => {
        //console.log({data})
        setSelectedAreaId(data.value);
        setSelectedAsset("")

        if (data.value === 'estate') {
            getEstateProperties(clientInfo?._id);
            // fetchEstateManualInput(clientInfo?._id);
        }
    }

    const clientDropdown_Change = (e, data) => {
        setSelectedClient(data.value);
        fetchEstateManualInput(data.value);
        dispatch(fetchCurrentClient(data.value));
    }

    const indexDropdown_Change = (e, data) => {
        setSelectedAsset(data.value);
        setIndexId(data.value || 0);
    }

    const fieldIrrevocableQuestions = (irrevNumber) => {
        let auxQuestions = []
        if (irrevNumber >= 1) {
            const data = BuildEstateQuestionnaireStructure(irrevNumber);
            const EstateQuestionnaire = [];
            let previousTitle = '';
            data.forEach((section) => {
                const questionnaire = {
                    section: '',
                    fields: [],
                };

                if (section.title) {
                questionnaire.section = section.title;
                previousTitle = section.title;
                }

                if (section.subtitle) {
                questionnaire.section = `${previousTitle.length > 0 ? `${previousTitle} - ${section.subtitle}` : section.subtitle}`;
                }

                questionnaire.fields = section.questions.map((field) => ({
                        name: field.field.replace('estateQuestion___', ''),
                        text: field.label,
                        type: 'string',
                        ...(field?.options && { options: field.options }),
                }));

                EstateQuestionnaire.push(questionnaire);
            });
            auxQuestions = EstateQuestionnaire
        } else {
            auxQuestions = manualInputFields['estate']
        }

        setFieldsSections(auxQuestions);
        return auxQuestions
    }

    const addProperty = (datapoint) => {
        const questions = JSON.parse(JSON.stringify(questionnaire || {}));
        const answer = questions?.[datapoint] || {};
        if (!answer?.whoAnswered) answer.whoAnswered = 'fpalpha';
        if (!answer?.type) answer.type = 'string';
        if (!answer?.answer) answer.answer = '[]';

        const properties = JSON.parse(answer.answer);
        const property = {
            asset: '',
            beneficiaries: [
                {
                    name: '',
                    percentage: 0,
                    type: '%'
                },
            ],
        };

        properties.push(property);
        answer.answer = JSON.stringify(properties);
        questions[datapoint] = answer;
        setQuestionnaire(questions);
    };

    const addBeneficiary = (datapoint, property) => {
        const questions = JSON.parse(JSON.stringify(questionnaire || {}));
        const answer = questions?.[datapoint] || {};
        let list = JSON.parse(answer.answer);
        list[property].beneficiaries.push({
            'name': '',
            'percentage': 0,
            'type': "%"
        });
        answer.answer = JSON.stringify(list);
        setQuestionnaire(questions);
    };

    const removeBeneficiary = (datapoint, property, ind) => {
        const questions = JSON.parse(JSON.stringify(questionnaire || {}));
        const answer = questions?.[datapoint] || {};
        let list = JSON.parse(answer.answer);
        const newList = [];

        if (list[property].beneficiaries.length === 1) {
            list.forEach((item, index) => {
                if (property !== index) newList.push(item);
            });
            list = newList;
        } else {
            list[property].beneficiaries.forEach((item, index) => {
                if (ind !== index) newList.push(item);
            });
            list[property].beneficiaries = newList;
        }

        answer.answer = JSON.stringify(list);
        setQuestionnaire(questions);
    };

    const setPropertyValue = (datapoint, property, value) => {
        const questions = JSON.parse(JSON.stringify(questionnaire || {}));
        const answer = questions?.[datapoint];
        let properties = JSON.parse(answer?.answer || '{}');
        if (!properties[property]) {
            properties[property] = {};
        }
        properties[property].asset = value;
        questions[datapoint].answer = JSON.stringify(properties);
        setQuestionnaire(questions);
    };

    const changeBeneficiary = (dataType, datapoint, property, ind, value) => {
        const questions = JSON.parse(JSON.stringify(questionnaire || {}));
        const answer = questions?.[datapoint];
        let list = JSON.parse(answer.answer);
        list[property].beneficiaries[ind][dataType] = value;
        answer.answer = JSON.stringify(list);
        setQuestionnaire(questions);
    };

    const changeBeneficiaryData = (dataType, datapoint, property, ind) => {
        return (e, elem) => {
            const questions = JSON.parse(JSON.stringify(questionnaire || {}));
            const answer = questions?.[datapoint];
            let list = JSON.parse(answer.answer);
            list[property].beneficiaries[ind][dataType] = elem.value;
            answer.answer = JSON.stringify(list);
            setQuestionnaire(questions);
        }
    };

    const beneficiariesList = (beneficiariesType, assetId) => {
        const tyeOptions = [
            { value: '%', text: '%' },
            { value: '$', text: '$' },
        ];
        const definersValues = {
            'will_realEstateDetail_JSON': 'will_realEstate',
            'will_personalPropertyDetail_JSON': 'will_personalProperty',
            'rev_realEstateDetail_JSON': 'rev_realEstate',
            'rev_personalPropertyDetail_JSON': 'rev_personalProperty',
        };
        let properties = questionnaire?.[beneficiariesType.name]?.answer || '[]';
        try {
            properties = JSON.parse(properties);
        } catch (error) {
            console.log(`IS NOT A JSON, PLEASE REVIEW => ${beneficiariesType.name} = ${properties}`);
            properties = []
        }
        return (<>
            {properties.length > 0 && <div style={{
                display: properties?.length > 0 ? 'block' : 'none',
                margin: '10px 0 10px 200px',
                paddingBottom: '10px',
            }}>

                {properties.map((property, index) => (<div style={{ borderBottom: '3px solid #eee', paddingBottom: '16px', marginBottom: '16px' }} key={v4()}>
                    <div style={{marginBottom: "10px"}}>
                        <SimpleInput
                            valContent={property?.asset}
                            placeholder='Property Name'
                            type='property'
                            datapoint={beneficiariesType.name}
                            property={index}
                            blur={setPropertyValue}
                        />
                    </div>
                    {property.beneficiaries.map((beneficiary, ind) => (<div style={{
                        marginLeft: '200px',
                        marginBottom: '8px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                    }} key={`${property.asset}_${beneficiary.name}_${ind}`}>
                        <SimpleInput
                            valContent={beneficiary.name}
                            placeholder='Property Name'
                            type='name'
                            datapoint={beneficiariesType.name}
                            property={index}
                            beneficiary={ind}
                            blur={changeBeneficiary}
                        />
                        <SimpleInput
                            valContent={beneficiary.percentage}
                            type='percentage'
                            datapoint={beneficiariesType.name}
                            property={index}
                            beneficiary={ind}
                            blur={changeBeneficiary}
                            inputType="number"
                        />
                        <div style={{ width: '50px', display: 'inline-block' }}>
                            <Select
                                fluid
                                options={tyeOptions}
                                defaultValue={beneficiary?.percentage !== "0" ? beneficiary.type : '%'}
                                type="number"
                                onChange={changeBeneficiaryData('type', beneficiariesType.name, index, ind)}
                            />
                        </div>
                        <Icon name='delete' inverted color='black' size='large' style={{ marginLeft: '30px' }} onClick={() => removeBeneficiary(beneficiariesType.name, index, ind)} />
                    </div>))}
                    <div style={{ width: '100%', textAlign: 'right'}}>
                        <Button color='olive' onClick={() => addBeneficiary(beneficiariesType.name, index)}>Add Beneficiary</Button>
                    </div>
                </div>))}
            </div>}
            {questionnaire[definersValues[beneficiariesType.name]]?.answer === 'Yes' && <div style={{ width: '100%', textAlign: 'right', margin: '16px 0' }}>
                <Button color='teal' onClick={() => addProperty(beneficiariesType.name)}>Add Property</Button>
            </div>}
        </>);
    };

    const detailQuestions = [
        'will_realEstateDetail_JSON',
        'will_personalPropertyDetail_JSON',
        'rev_realEstateDetail_JSON',
        'rev_personalPropertyDetail_JSON',
    ];

    useEffect(() => {
        if (selectedClient) {
            getProperties(selectedClient);
        }
    }, [selectedClient]);

    useEffect(() => {
        if (selectedAreaId === 'estate') {
            const allQuestions = {};
            fieldsSections?.forEach((section) => {
                section.fields.forEach((field) => {
                    allQuestions[field.name] = false;
                });
            });
            setAnsweredQuestions(allQuestions);
        }
    }, [fieldsSections]);

    useEffect(() => {
        //* Method to refresh the questionary
        setLoader(true);
        setTimeout(() => {
            setQuestionnaire({})
            setLoader(false);
            if(["estate"].includes(selectedAreaId)){
                fillQuestionsEstate()
            }
        }, 1)

        if (['home', 'auto'].includes(selectedAreaId)){
            sortProperties(allAssets);
        }
    }, [selectedAreaId, taxYear, indexId]);

    useEffect(() => {
        const data = selectedAreaId === 'fulltax'
            ? manualInputFields[selectedAreaId]?.[taxYear] || []
            : manualInputFields[selectedAreaId] || [];
        setFieldsSections(data);
    }, [selectedAreaId]);

    useEffect(() => {
        fieldIrrevocableQuestions(irrevNumber)
    }, [irrevNumber]);

    const resetUploader = async (year) => {
        setLoaderReset(true);
        try {
            // eslint-disable-next-line no-restricted-globals
            if(!confirm(`Are you sure reset uploader year ${year}`)){
                setLoaderReset(false)
                return;
            }
            const {data} = await sysAPI.delete(`/remove-fulltax-policy/${selectedClient}`, {
                params: {
                    year
                }
            })
            alert(data?.message || "Reset uploader was success")
        } catch (error) {
            console.log({error})
            alert(error.response?.data?.message || error.message || "Something was wrong, try again please...")
        }
        setLoaderReset(false)
    }


    return (<>
        <Modal open={advisor !== null} size='small'>
            <Modal.Header>Manual Input for {advisor?.fullName}</Modal.Header>
            <Modal.Content>
                <div style={{ display: 'flex' }}>
                    <Dropdown placeholder='Find client...' value={selectedClient} search selection options={clientsOptions} style={{ flex: '1 0 auto', marginRight: '10px' }} onChange={clientDropdown_Change} />
                    <Dropdown placeholder='Area...' value={selectedAreaId} selection options={areasOptions} style={{ maxWidth: '180px', minWidth: 'unset', flex: '1 1 auto', marginRight: '10px' }} onChange={areaDropdown_Change} />
                    {['auto', 'home', 'estate'].includes(selectedAreaId) &&
                        // <Dropdown placeholder='Index...' value={indexId} selection options={indexOptions} style={{ maxWidth: '100px', minWidth: 'unset', flex: '1 1 auto'}} onChange={indexDropdown_Change} />}
                        <Dropdown placeholder={(selectedAreaId === 'auto' || selectedAreaId === 'home') ? 'Properties' : 'Clients'} loading={fetchingProperties} value={selectedAsset} selection options={assets} style={{ maxWidth: '200px', minWidth: 'unset', flex: '1 1 auto'}} onChange={indexDropdown_Change} />}
                    {/* {selectedAreaId === 'estate' && <Dropdown placeholder='Index...' value={indexId} selection options={clientIndexOptions} style={{ maxWidth: '180px', minWidth: 'unset', flex: '1 1 auto', marginRight: '10px' }} onChange={indexOnlyDropdown_Change} />} */}
                </div>
                {clients === null &&
                    <p style={{ margin: '0px', padding: '10px 0 0' }}>Loading clients, please wait...</p>
                }
                {['auto', 'home'].includes(selectedAreaId) &&
                    <div style={{display: "flex", justifyContent: "end", gap: "10px", margin: "10px 0px"}}>
                        {selectedAsset &&<>
                            <div>
                                <Button onClick={() => fillQuestions()} loading={loader} disabled={loader}>Fill Questions</Button>
                            </div>
                            {/* <div>
                                <UnlockUploaders area={selectedAreaId} assetSelected={selectedAsset} advisor={advisor} client={clientInfo} />
                            </div> */}
                            <div>
                                <UnlockQuestionarie area={selectedAreaId} assetSelected={selectedAsset} advisor={advisor} client={clientInfo} />
                            </div>
                        </>
                        }
                        <div>
                                <Button
                                    color="yellow"
                                    style={{ marginRight: 0 }}
                                    onClick={() => setShowPropertiesModal(true)}
                                >
                                    Properties
                                </Button>
                        </div>
                    </div>
                }
                <div style={{ marginTop: '1em', display: selectedClient?.length > 0 && selectedAreaId?.length > 0 ? 'block' : 'none' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', alignItems: 'center' }}>
                        <Header as='h3' style={{ margin: 0 }}>{ALL_AREAS[selectedAreaId]} for {clientInfo?.clientIDFirstName} {clientInfo?.clientIDLastName}</Header>
                        {['fulltax'].includes(selectedAreaId) &&
                            <div>
                                <span style={{ fontSize: '16px' }}>Tax Year</span>
                                <Dropdown selection options={taxYearOptions} value={taxYear} onChange={taxYearDropdown_Change} style={{ marginLeft: '10px' }} />
                                <div style={{marginTop:"10px"}}>
                                    <Button loading={loaderReset} color="orange" size="mini" fluid onClick={() => resetUploader(taxYear)}>Reset Uploader</Button>
                                </div>

                            </div>
                        }
                    </div>

                    {!loader ? <>
                        {fieldsSections?.map((section, index) =>
                            <div key={index}>
                                <Header as='h3' style={{ margin: '15px 0 0' }}>
                                    <Header.Content>{section.section}</Header.Content>
                                </Header>
                                {!loader && section.fields?.map((field, index) => {
                                    if (detailQuestions.includes(field.name)) {
                                        return beneficiariesList(field);
                                    }

                                    return (<ManualInputField {...field}
                                        key={field.name}
                                        section={section.section}
                                        value={questionnaire[field.name]?.answer || field.default || ''}
                                        onInputChange={fieldInput_Change(field.name, field.type)}
                                        placeholder={field?.options?.length > 0 ? 'Select an option' : ''}
                                        providers={allProviders}
                                        ignoreBorder={true}
                                    />)
                                })}
                            </div>
                        )}
                        {selectedAreaId === 'estate' && <div style={{ width: '100%', textAlign: 'right', marginTop: '12px' }}>
                            <Button color='teal' onClick={() => {setIrrevNumber(irrevNumber + 1)}}>Add Irrevocable Trust</Button>
                            {irrevNumber >= 1 && <Button color='red' onClick={() => {setIrrevNumber(irrevNumber - 1)}}>Remove LAST Irrevocable Trust</Button>}
                        </div>}
                    </> : <>
                        <Icon loading name='asterisk' /> Loading ...
                    </>}
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setAdvisor(null)} disabled={loaderSubtmit}>Cancel</Button>
                <Button onClick={submitBtn_Click(false)} loading={loaderSubtmit} disabled={loaderSubtmit}>Submit</Button>
                <Button onClick={submitBtn_Click(true)} loading={loaderSubtmit} disabled={loaderSubtmit}>Submit and generate recommendations</Button>
                {/* {selectedAreaId === 'estate' && <Button onClick={submitBtn_Click(true)} loading={loaderSubtmit} disabled={isDisabledQuestions}>Submit and generate recommendations</Button>} */}
            </Modal.Actions>
        </Modal>

        <PropertiesModal
            open={showPropertiesModal}
            closeBtn_Click={() => setShowPropertiesModal(false)}
            area={selectedAreaId}
            editingEnabled
            getAssets={() => getProperties(selectedClient)}
            sortAssets={sortProperties}
        />
    </>)
}